import './ActionsBar.css';

const ActionsBar = ({ children, align = 'left', className = '' }) => {
	return (
		<div className={`actions-bar-container text-${align} ${className}`}>
			{children}
		</div>
	);
};

export default ActionsBar;
