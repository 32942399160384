import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import MakeItLiveDomainSuggestion from './MakeItLiveDomainSuggestion';

const MakeItLiveDomainSearchResults = () => {
	const [ { user, makeItLiveSettings }, dispatch ] = useStateValue();
	const [ domains, setDomains ] = useState([]);
	const [ selectedDomain, setSelectedDomain ] = useState(null);
	const { guid } = useParams();
	const history = useHistory();

	useEffect(
		() => {
			if (makeItLiveSettings) {
				if (makeItLiveSettings.domainSearch) {
					setDomains(makeItLiveSettings.domainSearch);

					if (makeItLiveSettings.domainSearch.length === 1) {
						setSelectedDomain(makeItLiveSettings.domainSearch[0]);
					} else {
						setSelectedDomain(makeItLiveSettings.domainSearch[0]);
					}
				}
			} else {
				history.push(`/make-it-live/${guid}`);
			}
		},
		[ makeItLiveSettings ]
	);

	const handleGoBackToSuggestionList = () => {
		history.push(`/make-it-live/${guid}/find-domain`);
	};

	const handleSelectedDomain = (domain) => {
		setSelectedDomain(domain);
	};

	const handleContinue = () => {
		let tempSettings = {
			domain                : selectedDomain,
			IsPrivateRegistration : false,
			IsAutoRenew           : true,
			card                  : null
		};

		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : {
				...makeItLiveSettings,
				...tempSettings
			}
		});

		if (user.FreeDomains && user.FreeDomains > 0) {
			history.push(`/make-it-live/${guid}/summary`);
		} else {
			// history.push(`/make-it-live/${guid}/privacy`);
			history.push(`/make-it-live/${guid}/card-select`);
		}
	};

	return (
		<div className="wizard-step wizard-domain-search-results">
			{domains && domains.length > 0 ? (
				<Fragment>
					{domains.length === 1 ? (
						<Fragment>
							{selectedDomain && (
								<Fragment>
									<h3 className="msg-success">
										Awesome! <u>{selectedDomain.DomainName}</u> is available!
									</h3>
								</Fragment>
							)}

							<div className="buttons-container">
								<button type="button" className="btn btn-primary" onClick={handleContinue}>
									<i className="fas fa-chevron-right" />
									<span>Continue</span>
								</button>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<h3>We found some domains that you might like...</h3>
							<p className="text-muted">
								Select one of the suggested domains below and click continue, or {' '}
								<Link to={`/make-it-live/${guid}/domain-search`} className="app-link">
									click here
								</Link>{' '}
								to search for more domain names
							</p>

							<div className="domain-suggestions-container">
								{domains.map((domain) => {
									if (domain.IsAvailable) {
										return (
											<MakeItLiveDomainSuggestion
												key={domain.DomainName}
												domain={domain}
												selectedDomain={selectedDomain}
												onSelect={handleSelectedDomain}
											/>
										);
									}
								})}
							</div>

							<div className="buttons-container">
								<button
									type="button"
									className="btn btn-primary"
									disabled={!selectedDomain}
									onClick={handleContinue}
								>
									<i className="fas fa-chevron-right" />
									<span>Continue</span>
								</button>
							</div>

							<div className="buttons-container mt-2">
								<button type="button" className="btn btn-link" onClick={handleGoBackToSuggestionList}>
									<span>
										<u>Click here</u> to go back to our suggested domain list
									</span>
								</button>
							</div>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Spinner />
			)}
		</div>
	);
};

export default MakeItLiveDomainSearchResults;
