import './BrandSendMessage.css';
import { useState, Fragment, useEffect, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BrandSendMessageTabs from './brandSendMessageTabs/BrandSendMessageTabs';
import Spinner from '../../../spinner/Spinner';
import TinymceEditor from '../../tinymceEditor/TinymceEditor';
import { GetMessageHistoryDetails, GetMessageTemplates, SendMessage } from '../../../Services';
import ActionsBar from '../../actionsBar/ActionsBar';
import BrandTemplateModal from './brandTemplateModal/BrandTemplateModal';

const BrandSendMessage = ({ type }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ editorContent, setEditorContent ] = useState('');
	const [ templates, setTemplates ] = useState(null);
	const [ historyDetails, setHistoryDetails ] = useState(null);
	const [ showTemplateDialog, setShowTemplateDialog ] = useState(false);
	const [ selectedTemplateGuid, setSelectedTemplateGuid ] = useState(null);
	const [ selectedTemplateId, setSelectedTemplateId ] = useState(null);
	const [ isTemplateEdit, setIsTemplateEdit ] = useState(false);
	const [ isSystemTemplate, setIsSystemTemplate ] = useState(false);
	const { guid } = useParams();
	const history = useHistory();
	const location = useLocation();

	useEffect(
		() => {
			getMessageTemplates();

			if (guid) {
				getMessageHistoryDetails();
			}
		},
		[ guid, type ]
	);

	useEffect(
		() => {
			let tempFormData = {
				...formData,
				Recipients : ''
			};
			setFormData(tempFormData);
		},
		[ type ]
	);

	useEffect(
		() => {
			if (templates && (!guid || (guid && historyDetails))) {
				setIsLoading(false);
			}
		},
		[ guid, templates, historyDetails ]
	);

	const getMessageTemplates = async () => {
		let initFormData = {
			Template : '',
			Subject  : '',
			Content  : ''
			//Recipients : ''
		};

		let data = { Type: type };
		const response = await GetMessageTemplates(data);

		if (response.Success) {
			setTemplates(response.Data);

			if (!guid && response.Data.length > 0) {
				let tempFormData = {
					...initFormData,
					Template : response.Data[0].Guid,
					Subject  : response.Data[0].Subject,
					Content  : maybeFormatContent(response.Data[0].Content)
				};

				setFormData(tempFormData);
				setEditorContent(maybeFormatContent(response.Data[0].Content));
				setSelectedTemplateGuid(response.Data[0].Guid);
				setSelectedTemplateId(response.Data[0].Id);
			} else {
				setFormData(initFormData);
				setEditorContent('');
				setSelectedTemplateGuid(null);
				setSelectedTemplateId(null);
			}
		} else {
			toast.error(response.Message);
		}
	};

	const getMessageHistoryDetails = async () => {
		let data = { Guid: guid };
		const response = await GetMessageHistoryDetails(data);

		if (response.Success) {
			let content = maybeFormatContent(response.Data.Content);

			let tempFormData = {
				...response.Data,
				Template   : response.Data.TemplateGuid,
				Content    : content,
				Recipients : response.Data.Recipients ? response.Data.Recipients.replace(/,/g, '\n') : ''
			};

			setFormData(tempFormData);
			setEditorContent(maybeFormatContent(tempFormData.Content));
			setSelectedTemplateGuid(response.Data.TemplateGuid);
			setSelectedTemplateId(response.Data.MessageTemplateId);
			setHistoryDetails(response.Data);

			if (response.Data.MessageTemplateId == '1') {
				setIsSystemTemplate(true);
				setSelectedTemplateId(null);
			}
		} else {
			toast.error(response.Message);
		}
	};

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		setFormData((prev) => {
			return {
				...prev,
				[name] : value
			};
		});

		if (name === 'Template') {
			//set subject and content
			let templateId = e.target.selectedOptions
				? parseInt(e.target.selectedOptions[0].getAttribute('data-id'))
				: null;
			let templateGuid = value;

			if (templates && templateId) {
				let selectedTemplateItem = templates.find((x) => x.Guid === templateGuid);

				if (selectedTemplateItem) {
					let tempFormData = {
						...formData,
						Subject  : selectedTemplateItem.Subject,
						Content  : selectedTemplateItem.Content,
						Template : templateGuid
					};

					setFormData(tempFormData);
					setSelectedTemplateGuid(templateGuid);
					setSelectedTemplateId(templateId);
					setEditorContent(selectedTemplateItem.Content);
				}
			}
		} else if (name === 'Content') {
			setEditorContent(maybeFormatContent(value));
		}
	};

	const handleEditorChange = (newValue) => {
		setEditorContent(maybeFormatContent(newValue));
	};

	const maybeFormatContent = (content) => {
		return type === 'sms' ? content.replace(/(<([^>]+)>)/gi, '') : content;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!selectedTemplateId) {
			toast.error('Template is required');
			return;
		}

		if (type === 'email') {
			if (!formData.Subject || formData.Subject.trim() === '') {
				toast.error('Subject is required');
				return;
			}
		}

		if (!formData.Content || formData.Content.trim() === '') {
			toast.error('Content is required');
			return;
		}

		if (!formData.Recipients || formData.Recipients.trim() === '') {
			toast.error('Recipient(s) is required');
			return;
		}

		setIsSaving(true);

		let tempRecipients = ''; //formData.Recipients.replace(/\n/g, ',');

		var recipientsArr = formData.Recipients.split('\n');

		if (recipientsArr && recipientsArr.length > 0) {
			for (var i = 0; i < recipientsArr.length; i++) {
				let recipient = recipientsArr[i];

				//check if recipient inputs are valid
				if (type === 'email') {
					var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

					if (!recipient.match(validEmailRegex)) {
						toast.error('Invalid email address - ' + recipient);
						setIsSaving(false);
						return;
					}
				} else if (type === 'sms') {
					recipient = recipient
						.replaceAll('(', '')
						.replaceAll(')', '')
						.replaceAll('-', '')
						.replaceAll(' ', '')
						.trim();

					const USPhoneRegex = /^\d{10}$/;
					const phoneRegex = /^\d+/g;

					if (!phoneRegex.test(recipient) || !USPhoneRegex.test(recipient)) {
						toast.error('Invalid phone number - ' + recipient);
						setIsSaving(false);
						return;
					}
				}

				tempRecipients += recipient + ',';
			}
		}

		if (tempRecipients.length > 0) {
			tempRecipients = tempRecipients.substring(0, tempRecipients.length - 1);
		}

		let data = {
			TemplateId : selectedTemplateId,
			Type       : type,
			Subject    : formData.Subject,
			Content    : editorContent,
			Recipients : tempRecipients
		};

		let response = await SendMessage(data);

		if (response.Success) {
			toast.success(response.Message);
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleClearForm = () => {
		let tempFormData = {
			...formData,
			Template   : '',
			Subject    : '',
			Content    : '',
			Recipients : ''
		};

		setFormData(tempFormData);
		setEditorContent('');
		setSelectedTemplateGuid(null);
		setSelectedTemplateId(null);
	};

	const toggleTemplateDialog = () => {
		setShowTemplateDialog((prev) => !prev);
	};

	const handleAddTemplate = () => {
		setIsTemplateEdit(false);
		setShowTemplateDialog((prev) => !prev);
	};

	const handleEditTemplate = () => {
		toggleTemplateDialog();
		setIsTemplateEdit(true);
	};

	const handleTeplateUpdate = () => {
		if (isTemplateEdit && selectedTemplateGuid) {
			//update subject and content
			if (templates) {
				let selectedTemplateItem = templates.find((x) => x.Guid === selectedTemplateGuid);

				if (selectedTemplateItem) {
					let tempFormData = {
						...formData,
						Subject : selectedTemplateItem.Subject,
						Content : selectedTemplateItem.Content
					};

					setFormData(tempFormData);
				}
			}

			getMessageTemplates();
		} else {
			getMessageTemplates();
		}

		toggleTemplateDialog();
	};

	const handleTeplateDelete = () => {
		toggleTemplateDialog();
		getMessageTemplates();
	};

	const handleGoBack = () => {
		history.goBack();
	};

	return (
		<Fragment>
			{!guid && (
				<div className="container">
					<BrandSendMessageTabs type={type} />
				</div>
			)}
			<div className="container mb-3">
				{guid && (
					<ActionsBar className="pl-0">
						<button type="button" className="btn btn-outline-primary" onClick={handleGoBack}>
							<i className="fas fa-chevron-left" />
							<span>Go Back</span>
						</button>
					</ActionsBar>
				)}
				<div className="rw-container shadow-large p-3">
					{!isLoading ? (
						<form onSubmit={handleSubmit}>
							<div className="form-inputs">
								<div className="form-group">
									<label htmlFor="Template">Template</label>
									<div className="input-group">
										<select
											className="form-control form-control-lg"
											name="Template"
											disabled={isSaving}
											value={formData.Template}
											onChange={handleChange}
										>
											<option value="">- Select Template -</option>
											{templates &&
												templates.map((template) => (
													<option
														key={template.Guid}
														value={template.Guid}
														data-id={template.Id}
													>
														{template.Name}
													</option>
												))}
										</select>
										{selectedTemplateGuid &&
										!isSystemTemplate && (
											<button
												type="button"
												className="btn btn-primary btn-template-action"
												onClick={handleEditTemplate}
												title="Edit Selected Template"
											>
												<i className="fas fa-pencil-alt" />
											</button>
										)}
										<button
											type="button"
											className="btn btn-primary btn-template-action"
											onClick={handleAddTemplate}
											title="Add New Template"
										>
											<i className="fas fa-plus" />
										</button>
									</div>
								</div>
								{type === 'email' && (
									<div className="form-group">
										<label htmlFor="Subject">Subject</label>
										<input
											type="text"
											className="form-control form-control-lg"
											name="Subject"
											disabled={isSaving}
											value={formData.Subject}
											onChange={handleChange}
										/>
									</div>
								)}
								<div className="form-group">
									<label htmlFor="Content">Message</label>
									{type === 'email' ? (
										<TinymceEditor
											name="Content"
											onValueChange={handleEditorChange}
											initialValue={formData.Content}
											height={300}
											showMenubar={true}
										/>
									) : (
										<textarea
											className="form-control form-control-lg"
											name="Content"
											disabled={isSaving}
											value={formData.Content}
											onChange={handleChange}
											rows={4}
										/>
									)}
								</div>
								<div className="form-group">
									<label htmlFor="Recipients">Recipients (one per line)</label>
									<textarea
										className="form-control form-control-lg"
										name="Recipients"
										disabled={isSaving}
										value={formData.Recipients}
										onChange={handleChange}
										rows={4}
									/>
								</div>
							</div>
							<div className="rw-container-buttons">
								<div className="row">
									<div className="col-6">
										<button type="submit" className="btn btn-primary" disabled={isSaving}>
											{!isSaving ? (
												<Fragment>
													<i className="fas fa-cloud-upload-alt" />
													<span>Send</span>
												</Fragment>
											) : (
												<Fragment>
													<Spinner />
													<span>Sending...</span>
												</Fragment>
											)}
										</button>
									</div>
									<div className="col-6 text-right">
										<button
											type="button"
											className="btn btn-outline-primary"
											onClick={handleClearForm}
											disabled={isSaving}
										>
											<i className="fas fa-eraser" />
											<span>Clear</span>
										</button>
									</div>
								</div>
							</div>
						</form>
					) : (
						<div className="text-center p-3">
							<Spinner />
						</div>
					)}
				</div>
			</div>

			{showTemplateDialog && (
				<BrandTemplateModal
					guid={isTemplateEdit ? selectedTemplateGuid : null}
					onUpdate={handleTeplateUpdate}
					onDelete={handleTeplateDelete}
					onCancel={toggleTemplateDialog}
				/>
			)}
		</Fragment>
	);
};

export default BrandSendMessage;
