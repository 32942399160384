import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../reducer';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import { PurchaseCartItems, GetCartCount } from '../../../../Services';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import CartSummaryItem from '../cartSummary/cartSummaryItem/CartSummaryItem';
import './CartReview.css';
import AppTermsAndConditions from '../../../appTermsAndConditions/AppTermsAndConditions';

const CartReview = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isPurchasing, setIsPurchasing ] = useState(false);
	const [ showDomainPolicyDialog, setShowDomainPolicyDialog ] = useState(
		false
	);
	const [ showTermsDialog, setShowTermsDialog ] = useState(false);
	const [ total, setTotal ] = useState(0);
	const [ balanceDue, setBalanceDue ] = useState(0);
	const [ nonPrivateDomainsFound, setNonPrivateDomainsFound ] = useState(
		false
	);
	const [
		{
			user,
			selectedCartItems,
			freeDomain,
			cartSelectedCard,
			cartItemsResponse
		},
		dispatch
	] = useStateValue();
	const [
		requireSubscriptionActivation,
		setRequireSubscriptionActivation
	] = useState(false);
	const [
		showSubscriptionActivationDialog,
		setShowSubscriptionActivationDialog
	] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (!selectedCartItems || !cartSelectedCard) {
			history.push('/cart');
		}
	}, []);

	useEffect(
		() => {
			if (user) {
				if (
					user.IsPaidSubscriber !== null &&
					!user.IsPaidSubscriber &&
					user.PriceId &&
					user.PriceId !== ''
				) {
					setRequireSubscriptionActivation(true);
				} else {
					setRequireSubscriptionActivation(false);
				}
			}
		},
		[ user ]
	);

	useEffect(
		() => {
			if (selectedCartItems) {
				let tempTotal = 0;

				selectedCartItems.map((item) => {
					let itemPrice = item.TermPrice;

					if (freeDomain && freeDomain.Id === item.Id) {
						itemPrice -= item.YearlyPrice;
					}

					if (!item.IsPrivateRegistration) {
						setNonPrivateDomainsFound(true);
					}

					tempTotal += itemPrice;
				});

				setTotal(tempTotal);

				if (user.CreditsBalance > 0) {
					if (user.CreditsBalance > tempTotal) {
						setBalanceDue(0);
					} else {
						setBalanceDue(tempTotal - user.CreditsBalance);
					}
				} else {
					setBalanceDue(tempTotal);
				}
			}
		},
		[ selectedCartItems ]
	);

	const handleChangeCard = () => {
		history.push('/cart/select-card');
	};

	const handleChangePrivacy = () => {
		history.push('/cart');
	};

	const toggleDomainPolicyDialog = () => {
		setShowDomainPolicyDialog((prev) => !prev);
	};

	const toggleTermsDialog = () => {
		setShowTermsDialog((prev) => !prev);
	};

	const handlePayNow = async () => {
		if (requireSubscriptionActivation) {
			toggleSubscriptionActivationDialog();
			return;
		}

		setIsPurchasing(true);

		handlePurchase();
	};

	const handlePurchase = async () => {
		let data = {
			CartItemIds    : new Array(),
			CardId         : cartSelectedCard.Guid,
			FreeDomainName : freeDomain ? freeDomain.DomainName : ''
		};

		selectedCartItems.map((item) => {
			data.CartItemIds.push(item.Id);
		});

		const response = await PurchaseCartItems(data);

		if (response.IsSuccessful) {
			let tempPurchasedItems = JSON.parse(response.Data);
			console.log('purchased items: ', tempPurchasedItems);

			await checkCartItemsCount();

			dispatch({
				type              : actionTypes.SET_CART_ITEMS_RESPONSE,
				cartItemsResponse : tempPurchasedItems
			});
		} else {
			toast.error(response.Message);
			setIsPurchasing(false);
		}
	};

	const toggleSubscriptionActivationDialog = () => {
		setShowSubscriptionActivationDialog((prev) => !prev);
	};

	const handleConfirmSubscriptionActivation = async () => {
		setIsPurchasing(true);
		await handlePurchase();
	};

	const checkCartItemsCount = async () => {
		const response = await GetCartCount();

		if (response.IsSuccessful) {
			dispatch({
				type           : actionTypes.SET_CART_ITEMS_COUNT,
				cartItemsCount : parseInt(response.Message)
			});
		} else {
			dispatch({
				type           : actionTypes.SET_CART_ITEMS_COUNT,
				cartItemsCount : 0
			});
		}
	};

	useEffect(
		() => {
			if (cartItemsResponse) {
				history.push('/cart/purchase-review');
			}
		},
		[ cartItemsResponse ]
	);

	return (
		<div className="app-cart-review panel">
			{selectedCartItems &&
			cartSelectedCard && (
				<Fragment>
					<div className="container">
						<h5 className="rw-container-title">
							<i className="fas fa-shopping-bag" />
							<span>Cart Review</span>
						</h5>

						<div className="rw-container">
							<div className="app-cart-review-container">
								<h5 className="rw-container-title mt-0">
									<span>Order Items</span>
								</h5>

								<div className="rw-table">
									<div className="rw-table-header">
										<div className="rw-th description">
											Description
										</div>
										<div className="rw-th price">Price</div>
									</div>

									{selectedCartItems.map((item) => (
										<CartSummaryItem
											key={item.Id}
											domain={item}
											showDescription={false}
										/>
									))}

									<div className="rw-row total-row">
										<div className="rw-td description text-right">
											<span>Total:</span>
										</div>
										<div className="rw-td price">
											<span>${total.toFixed(2)}</span>
										</div>
									</div>

									{user.CreditsBalance > 0 && (
										<div className="rw-row total-row">
											<div className="rw-td description text-right">
												<span>Credits Available:</span>
											</div>
											<div className="rw-td price">
												<span>
													${user.CreditsBalance.toFixed(2)}
												</span>
											</div>
										</div>
									)}

									<div className="rw-row total-row">
										<div className="rw-td description text-right">
											<span>Balance Due:</span>
										</div>
										<div className="rw-td price">
											<span>
												${balanceDue.toFixed(2)}
											</span>
										</div>
									</div>
								</div>

								<hr />

								<h5 className="rw-container-title mt-0">
									<span>Payment Method Information</span>
								</h5>

								<div className="app-cart-review-selected-card">
									<i className="far fa-credit-card" />
									<span className="card-details">
										{cartSelectedCard.CardType} ending in{' '}
										{cartSelectedCard.CardDigits}
									</span>

									<button
										type="button"
										className="btn btn-outline-primary"
										disabled={isPurchasing}
										onClick={handleChangeCard}
									>
										<span>Change Card</span>
									</button>
								</div>
								<hr />

								{/* total due today */}
								<div className="alert alert-success">
									<h3 className="m-0">
										Total due today is ${balanceDue.toFixed(2)}
									</h3>
								</div>
							</div>

							<div className="rw-container-buttons">
								<button
									className="btn btn-primary"
									disabled={isPurchasing}
									onClick={handlePayNow}
								>
									{!isPurchasing ? (
										<Fragment>
											<i className="far fa-credit-card" />
											<span>Pay Now</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Purchasing...</span>
										</Fragment>
									)}
								</button>

								<button
									className="btn btn-outline-primary"
									disabled={isPurchasing}
									onClick={() => history.push('/cart')}
								>
									<i className="fas fa-chevron-left" />
									<span>Cancel</span>
								</button>

								<div className="terms-container mt-2">
									<small>
										By clicking on "Pay Now" you agree to
										our{' '}
										<a
											onClick={toggleTermsDialog}
											className="app-link"
										>
											Terms and Conditions
										</a>{' '}
										and{' '}
										<a
											onClick={toggleDomainPolicyDialog}
											className="app-link"
										>
											Domain Registration Policy
										</a>
									</small>
								</div>
							</div>
						</div>

						{/* {nonPrivateDomainsFound && (
							<Fragment>
								<div className="privacy-warning">
									<div className="alert alert-warning">
										<span>
											IMPORTANT: Some of the domains you're about to purchase do not have privacy
											enabled. This will expose your contact information to public. If you wish to
											enable privacy click on the button below.
										</span>
									</div>

									<button
										type="button"
										className="btn btn-outline-primary"
										onClick={handleChangePrivacy}
									>
										<i className="fas fa-chevron-left" />
										<span>Change Privacy Settings</span>
									</button>
								</div>
							</Fragment>
						)} */}
					</div>

					{showTermsDialog && (
						<AppTermsAndConditions onClose={toggleTermsDialog} />
					)}

					{showDomainPolicyDialog && (
						<Fragment>
							<ModalDialog>
								<ModalDialogContent>
									<h5 className="rw-container-title mt-0">
										<span>Domain Registration Policy</span>
									</h5>

									<div className="alert alert-secondary m-0">
										<p className="m-0">
											<strong>
												NOTE: This order is a
												non-refundable purchase.
											</strong>
											<br />You are purchasing products or
											services that are provided by one of
											our partner companies. Immediately
											after you complete the payment these
											orders are routed and fulfilled by
											those partner companies. We will not
											be able to process refunds or
											cancellations on these purchases
											once they have been routed and
											completed.
										</p>
									</div>
								</ModalDialogContent>
								<ModalDialogButtons shaded>
									<button
										type="button"
										className="btn btn-primary"
										onClick={toggleDomainPolicyDialog}
									>
										<i className="far fa-thumbs-up" />
										<span>OK</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						</Fragment>
					)}

					{showSubscriptionActivationDialog && (
						<Fragment>
							<ModalDialog>
								<ModalDialogContent>
									<h5 className="rw-container-title mt-0">
										<span>
											Great! You found a domain for your
											website!
										</span>
									</h5>

									<p className="m-0">
										You are currently signed up for our
										trial offer, however we require you to
										have an active paid subscription to
										register domains.
									</p>

									<p className="m-0">
										Just click the YES button below and we
										will end your trial and activate your
										monthly subscription and then
										immediately register your domain.
									</p>
								</ModalDialogContent>
								<ModalDialogButtons shaded>
									<button
										type="button"
										className="btn btn-primary"
										onClick={
											handleConfirmSubscriptionActivation
										}
										disabled={isPurchasing}
									>
										{!isPurchasing ? (
											<Fragment>
												{/* <i className="far fa-credit-card" /> */}
												<span>YES</span>
											</Fragment>
										) : (
											<Fragment>
												<Spinner />
												<span>Activating...</span>
											</Fragment>
										)}
									</button>
									<button
										type="button"
										className="btn btn-outline-primary"
										onClick={
											toggleSubscriptionActivationDialog
										}
										disabled={isPurchasing}
									>
										<span>No</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default CartReview;
