import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
	GetDomainForwardingDetails,
	UpdateDomainForwardingDetails
} from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import NoDataMessage from '../../../../noDataMessage/NoDataMessage';
import './DomainForwarding.css';

const DomainForwarding = ({ domain }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ forwardingDetails, setForwardingDetails ] = useState(null);

	useEffect(
		() => {
			if (domain) {
				getForwardingDetails();
			} else {
				setForwardingDetails({
					IsFordwardEnabled : false
				});
			}
		},
		[ domain ]
	);

	const getForwardingDetails = async () => {
		let data = {
			Guid : domain.Guid
		};

		const response = await GetDomainForwardingDetails(data);

		if (response.IsSuccessful) {
			let tempForwardingDetails = JSON.parse(response.Data);
			console.log(tempForwardingDetails);

			setForwardingDetails(tempForwardingDetails);
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const handleChange = (e) => {
		const name = e.target.name;

		if (name !== 'IsForwardEnabled') {
			setForwardingDetails({
				...forwardingDetails,
				[e.target.name]: e.target.value
			});
		} else {
			setForwardingDetails({
				...forwardingDetails,
				[e.target.name]: e.target.checked
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let data = {
			Guid : domain.Guid,
			...forwardingDetails
		};

		const response = await UpdateDomainForwardingDetails(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<div className="rw-container">
				{!isLoading ? (
					<Fragment>
						{forwardingDetails ? (
							<Fragment>
								<div className="forwarding-details">
									<form
										onSubmit={handleSubmit}
										autoComplete="off"
									>
										<div className="form-inputs">
											<div className="form-group forwarding-toggle">
												<label>
													Enable Domain Forwarding
												</label>
												<input
													id="chkForwardingEnabled"
													name="IsForwardEnabled"
													type="checkbox"
													className="rw-checkbox-switch"
													disabled={isSaving}
													defaultChecked={
														forwardingDetails.IsFordwardEnabled
													}
													onChange={handleChange}
												/>
												<label htmlFor="chkForwardingEnabled" />
											</div>
											<div className="form-group">
												<label htmlFor="forwardUrl">
													Url to Redirect to
												</label>
												<input
													type="text"
													id="forwardUrl"
													name="ForwardUrl"
													className="form-control form-control-lg"
													placeholder="Example: https://mybusiness.com"
													disabled={isSaving}
													value={
														forwardingDetails.ForwardUrl
													}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="rw-container-buttons">
											<button
												type="submit"
												className="btn btn-primary"
												disabled={isSaving}
											>
												{!isSaving ? (
													<Fragment>
														<i className="fas fa-save" />
														<span>Save</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>
															Saving Changes...
														</span>
													</Fragment>
												)}
											</button>
										</div>
									</form>
								</div>
							</Fragment>
						) : (
							<NoDataMessage />
						)}
					</Fragment>
				) : (
					<div className="text-center p-3">
						<Spinner />
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default DomainForwarding;
