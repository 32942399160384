import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetUserInvoice, PayInvoice } from '../../Services';
import Spinner from '../../spinner/Spinner';
import { useStateValue } from '../../StateProvider';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import LottiePlayer from '../lottiePlayer/LottiePlayer';
import CardInput from '../selectCreditCard/cardInput/CardInput';
import './ViewInvoice.css';
import ViewInvoiceCardInput from './viewInvoiceCardInput/ViewInvoiceCardInput';
import checkAnimation from '../lottiePlayer/animations/check.json';

const ViewInvoice = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ { brand }, dispatch ] = useStateValue();
	const { invoiceGuid } = useParams();
	const [ invoiceDetails, setInvoiceDetails ] = useState(null);
	const [ isInvoicePaid, setIsInvoicePaid ] = useState(false);
	const [ paymentSuccessful, setPaymentSuccessful ] = useState(false);
	const [ invoiceItems, setInvoiceItems ] = useState([]);
	const [ invoiceName, setInvoiceName ] = useState('');
	const [ discount, setDiscount ] = useState(0);
	const [ discountAmount, setDiscountAmount ] = useState(0);
	const [ subtotal, setSubtotal ] = useState(0);
	const [ total, setTotal ] = useState(0);
	const [ showSubscriptionDetails, setShowSubscriptionDetails ] = useState(
		false
	);
	const [ subscriptionDetails, setSubscriptionDetails ] = useState(null);
	const [ showCardForm, setShowCardForm ] = useState(false);
	const history = useHistory();

	useEffect(
		() => {
			getInvoiceDetails();
		},
		[ invoiceGuid ]
	);

	const getInvoiceDetails = async () => {
		let data = {
			Id : invoiceGuid
		};

		const response = await GetUserInvoice(data);

		if (response.IsSuccessful) {
			const tempInvoiceDetails = JSON.parse(response.Data);

			setInvoiceName(tempInvoiceDetails.InvoiceTitle);
			setDiscount(tempInvoiceDetails.Discount);

			const invoiceItemsAndSubscriptionDetails = JSON.parse(
				tempInvoiceDetails.InvoiceItemsJson
			);

			if (Array.isArray(invoiceItemsAndSubscriptionDetails)) {
				// this is a legacy invoice that did not have support for subscriptions

				for (
					let i = 0;
					i < invoiceItemsAndSubscriptionDetails.length;
					i++
				) {
					invoiceItemsAndSubscriptionDetails[i] = {
						name  : invoiceItemsAndSubscriptionDetails[i].Name,
						price : invoiceItemsAndSubscriptionDetails[i].Price
					};
				}

				setInvoiceItems(invoiceItemsAndSubscriptionDetails);
			} else {
				if (invoiceItemsAndSubscriptionDetails.InvoiceItems) {
					setInvoiceItems(
						invoiceItemsAndSubscriptionDetails.InvoiceItems
					);
				}

				if (invoiceItemsAndSubscriptionDetails.HasSubscription) {
					setShowSubscriptionDetails(
						invoiceItemsAndSubscriptionDetails.HasSubscription
					);
				}

				if (invoiceItemsAndSubscriptionDetails.SubscriptionDetails) {
					setSubscriptionDetails(
						invoiceItemsAndSubscriptionDetails.SubscriptionDetails
					);
				}
			}

			setInvoiceDetails(tempInvoiceDetails);
			setIsInvoicePaid(tempInvoiceDetails.IsPaid);
		} else {
			toast.error(response.Message);
			history.goBack();
		}
	};

	useEffect(
		() => {
			if (invoiceDetails) {
				setIsLoading(false);
			}
		},
		[ invoiceDetails ]
	);

	useEffect(
		() => {
			if (invoiceItems && invoiceItems.length > 0) {
				let tempSubtotal = 0;
				let tempTotal = 0;
				let tempDiscount = discount;

				for (let i = 0; i < invoiceItems.length; i++) {
					let invoiceItem = invoiceItems[i];

					if (invoiceItem.price && invoiceItem.price > 0) {
						tempSubtotal += invoiceItem.price;
					}
				}

				if (tempSubtotal > 0) {
					if (tempDiscount > 0) {
						tempTotal =
							tempSubtotal - tempSubtotal / 100 * tempDiscount;
					} else {
						tempTotal = tempSubtotal;
					}
				}

				setSubtotal(tempSubtotal);
				setTotal(tempTotal);
				setDiscountAmount(tempSubtotal - tempTotal);
			} else {
				setSubtotal(0);
				setTotal(0);
				setDiscountAmount(0);
			}
		},
		[ invoiceItems, discount ]
	);

	const toggleCardForm = () => {
		setShowCardForm((prev) => !prev);
	};

	const handlePayment = async (card) => {
		if (invoiceDetails.IsPaid) {
			toast.error('This invoice has already been paid');
			return;
		}

		setIsSaving(true);

		let data = {
			...card,
			InvoiceId : invoiceGuid
		};

		const response = await PayInvoice(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			setPaymentSuccessful(true);
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			{!isLoading ? (
				<Fragment>
					<div className="app-invoice-panel">
						<div className="app-invoice-container">
							{!paymentSuccessful ? (
								<Fragment>
									<div className="app-invoice">
										{!showCardForm && (
											<Fragment>
												<div className="app-invoice-header">
													<div className="app-invoice-logo">
														<img
															src={
																brand.BrandLogo
															}
															alt="Logo"
														/>
													</div>
													<div className="app-invoice-brand">
														<h5
														>{`Invoice by ${brand.BrandName}`}</h5>
														<small className="text-muted mt-2">{`Invoice #${invoiceGuid}`}</small>
													</div>
												</div>
											</Fragment>
										)}

										{!isInvoicePaid ? (
											<Fragment>
												<div className="app-invoice-amount">
													<h4
													>{`Amount due $${invoiceDetails.InvoiceAmount.toFixed(
														2
													)} USD`}</h4>

													{!showCardForm ? (
														<Fragment>
															<button
																type="button"
																className="btn btn-primary btn-lg btn-block"
																onClick={
																	toggleCardForm
																}
															>
																<span>
																	Pay Invoice
																</span>
															</button>
														</Fragment>
													) : (
														<Fragment>
															<ViewInvoiceCardInput
																onSave={
																	handlePayment
																}
																onCancel={
																	toggleCardForm
																}
																isSaving={
																	isSaving
																}
																requireTermsAgreement={
																	showSubscriptionDetails
																}
															/>
														</Fragment>
													)}
												</div>
											</Fragment>
										) : (
											<Fragment>
												<div className="alert alert-success text-center rounded-0">
													<span>
														This invoice has been
														paid
													</span>
												</div>
											</Fragment>
										)}

										{!showCardForm && (
											<Fragment>
												<div className="app-invoice-items">
													<div className="app-invoice-description">
														<i className="fas fa-sticky-note text-muted" />
														<div className="app-invoice-description-text">
															{
																invoiceDetails.InvoiceTitle
															}
														</div>
													</div>

													<div className="app-invoice-items-table">
														<table className="table">
															<thead>
																<tr>
																	<th className="description">
																		Description
																	</th>
																	<th className="price">
																		Price
																	</th>
																</tr>
															</thead>
															<tbody>
																{invoiceItems.map(
																	(item) => (
																		<tr>
																			<td className="description">
																				{
																					item.name
																				}
																			</td>
																			<td className="price">
																				${item.price.toFixed(2)}
																			</td>
																		</tr>
																	)
																)}

																<tr>
																	<td className="description text-right">
																		<strong>
																			Subtotal
																		</strong>
																	</td>
																	<td className="price">
																		${subtotal.toFixed(2)}
																	</td>
																</tr>

																{discountAmount >
																	0 && (
																	<Fragment>
																		<tr>
																			<td className="description text-right">
																				<strong
																				>{`Discount (${invoiceDetails.Discount.toFixed(
																					2
																				)}%)`}</strong>
																			</td>
																			<td className="price">
																				${discountAmount.toFixed(2)}
																			</td>
																		</tr>
																	</Fragment>
																)}

																<tr>
																	<td className="description text-right">
																		<strong>
																			Amount
																			Due
																		</strong>
																	</td>
																	<td className="price">
																		${total.toFixed(2)}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</Fragment>
										)}

										{!showCardForm && (
											<Fragment>
												<div className="app-invoice-footer">
													<span>
														If you have any
														questions, contact{' '}
														{brand.BrandName} at{' '}
														<span className="email">{brand.BrandSupportEmail}</span>
													</span>
												</div>
											</Fragment>
										)}
									</div>

									<div className="text-center mt-4">
										<small className="text-muted">
											<i className="fas fa-lock mr-2" />
											{`${brand.BrandName} partners with Stripe to provide secure invoicing and payments processing`}
										</small>
									</div>
								</Fragment>
							) : (
								<Fragment>
									<CenteredContainer>
										<LottiePlayer
											width={200}
											height={200}
											animation={checkAnimation}
										/>
										<div className="text-center">
											<h5>Invoice Paid Successfully!</h5>

											<Link
												to="/"
												className="btn btn-primary mt-3"
											>
												<span>Go to Your Account</span>
											</Link>
										</div>
									</CenteredContainer>
								</Fragment>
							)}
						</div>
					</div>
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</Fragment>
	);
};

export default ViewInvoice;
