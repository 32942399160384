import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteUserWebsite } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';

const DeleteWebsite = ({ website, closeDialog, callback }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isValid, setIsValid ] = useState(false);
	const [ deleteText, setDeleteText ] = useState('');

	useEffect(
		() => {
			if (deleteText && deleteText.trim() !== '' && deleteText.trim() === 'DELETE') {
				setIsValid(true);
			} else {
				setIsValid(false);
			}
		},
		[ deleteText ]
	);

	const handleChange = (e) => {
		setDeleteText(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isValid) {
			toast.error('Enter a word DELETE into the provided input to delete this website');
			return;
		}

		setIsSaving(true);

		let websiteData = {};
		websiteData.WebsiteId = website.Guid;

		const response = await DeleteUserWebsite(websiteData);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (callback) {
				callback();
			}

			closeDialog();
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<ModalDialog>
				<form onSubmit={handleSubmit} autoComplete="off">
					<ModalDialogContent align="left">
						<h5>Confirm Website Deletion</h5>
						<p>
							Type the word DELETE in capital letters into the box below and click on the Delete button.
							<br />
							<strong>NOTE:</strong> This action is irreversible!
						</p>
						<input
							type="text"
							name="deleteText"
							placeholder="Type DELETE to confirm deletion"
							className="form-control form-control-lg"
							value={deleteText}
							onChange={handleChange}
							disabled={isSaving}
						/>
					</ModalDialogContent>
					<ModalDialogButtons align="left" shaded={true}>
						<button type="submit" className="btn btn-danger" disabled={isSaving}>
							{!isSaving ? (
								<Fragment>
									<i class="far fa-trash-alt" />
									<span>Delete</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Deleting...</span>
								</Fragment>
							)}
						</button>
						<button
							type="button"
							className="btn btn-outline-primary"
							disabled={isSaving}
							onClick={closeDialog}
						>
							<i class="fas fa-window-close" />
							<span>Cancel</span>
						</button>
					</ModalDialogButtons>
				</form>
			</ModalDialog>
		</Fragment>
	);
};

export default DeleteWebsite;
