import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../../../StateProvider';
import { UpdateDomainAutoRenew } from '../../../../../Services';

const Domain = ({ domain, onSave }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ { brand }, dispatch ] = useStateValue();

	useEffect(() => {
		// console.log(domain);
	}, []);

	const handleAutoRenewChange = async (e) => {
		const isRenewAuto = e.target.checked;

		setIsSaving(true);

		let data = {
			Guid        : domain.Guid,
			IsAutoRenew : isRenewAuto
		};

		const response = await UpdateDomainAutoRenew(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onSave) {
				onSave();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<div className="rw-row collapsable-parent">
			<div className="rw-td domain-name">{domain.DomainName}</div>

			{domain.Status === 'Added-On' || domain.Status === 'In Cart' ? (
				<Fragment>
					<div className="rw-td auto-renew">--</div>
					<div className="rw-td expires">--</div>
				</Fragment>
			) : (
				<Fragment>
					<div className="rw-td auto-renew">
						<input
							id={`chk${domain.Guid}`}
							type="checkbox"
							className="rw-checkbox-switch"
							defaultChecked={domain.IsAutoRenew}
							onChange={handleAutoRenewChange}
						/>
						<label htmlFor={`chk${domain.Guid}`} />
					</div>
					<div className="rw-td expires">{domain.DateExpires}</div>
				</Fragment>
			)}

			{domain.PointedStatus === 'Not-Pointed' ? (
				<div
					className="rw-td notice"
					title={`This domain doesn't seem to be pointed to our servers. The nameservers of the domain have to be set to ${brand.NS1} and ${brand.NS2}`}
				>
					<i className="fas fa-exclamation-triangle" />
				</div>
			) : (
				<div className="rw-td notice" />
			)}

			<div className="rw-td status">
				<div className={`domain ${domain.Status.toLowerCase()}`}>
					{domain.Status}
				</div>
			</div>
			<div className="rw-td action">
				{domain.Status !== 'In Cart' && domain.Status !== 'Renew' ? (
					<Link
						to={`/domains/${domain.Guid}/${domain.DomainName.toLowerCase()}`}
						className="btn btn-primary"
					>
						<i className="fas fa-pencil-alt" />
						<span>Edit</span>
					</Link>
				) : (
					<Link to="/cart" className="btn btn-primary">
						<i class="fas fa-shopping-bag" />
						<span>Checkout</span>
					</Link>
				)}
			</div>
		</div>
	);
};

export default Domain;
