import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AddNewBrandWebhook, UpdateBrandWebhook } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import './EditBrandWebhook.css';

const EditBrandWebhook = ({ webhook, webhookType, onUpdate, onCancel }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});

	useEffect(
		() => {
			if (webhook) {
				setFormData(webhook);
			} else {
				setFormData({});
			}
		},
		[ webhook ]
	);

	const handleChange = (e) => {
		const name = e.target.name;
		const value =
			e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		setFormData((prev) => {
			return {
				...prev,
				[name] : value
			};
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.WebhookUrl || formData.WebhookUrl.trim() === '') {
			toast.error(
				'Invalid webhook url! Make sure to type in a fully qualified url'
			);
			return;
		}

		setIsSaving(true);

		let data = {
			WebhookType : webhookType,
			...webhook,
			WebhookUrl  : formData.WebhookUrl.trim()
		};

		let response = {};

		if (data.Guid) {
			response = await UpdateBrandWebhook(data);
		} else {
			response = await AddNewBrandWebhook(data);
		}

		if (response.Success) {
			toast.success(response.Message);

			if (onUpdate) {
				onUpdate();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<ModalDialog>
			<form onSubmit={handleSubmit} autoComplete="off">
				<ModalDialogContent>
					<label htmlFor="webhookUrl">Webhook Url:</label>
					<input
						type="text"
						name="WebhookUrl"
						className="form-control mt-3"
						placeholder="Type in the webhook url"
						disabled={isSaving}
						value={formData.WebhookUrl}
						onChange={handleChange}
					/>
				</ModalDialogContent>
				<ModalDialogButtons shaded>
					<button
						type="submit"
						className="btn btn-primary"
						disabled={isSaving}
					>
						{!isSaving ? (
							<Fragment>
								<i class="fas fa-cloud-upload-alt" />
								<span>Save</span>
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
								<span>Saving...</span>
							</Fragment>
						)}
					</button>
					<button
						type="button"
						className="btn btn-outline-primary"
						disabled={isSaving}
						onClick={handleCancelClick}
					>
						<span>Cancel</span>
					</button>
				</ModalDialogButtons>
			</form>
		</ModalDialog>
	);
};

export default EditBrandWebhook;
