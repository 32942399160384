import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { actionTypes } from '../../../reducer';
import { GetCartCount } from '../../../Services';
import { useStateValue } from '../../../StateProvider';
import './CartIcon.css';

const CartIcon = () => {
	const [ { cartItemsCount }, dispatch ] = useStateValue();
	const [ itemsLabel, setItemsLabel ] = useState('item');

	useEffect(() => {
		checkCartItemsCount();
		const cartCheckerInterval = setInterval(checkCartItemsCount, 15000);

		return () => {
			clearInterval(cartCheckerInterval);
		};
	}, []);

	useEffect(
		() => {
			if (cartItemsCount > 1) {
				setItemsLabel('items');
			} else {
				setItemsLabel('item');
			}
		},
		[ cartItemsCount ]
	);

	const checkCartItemsCount = async () => {
		const response = await GetCartCount();

		if (response.IsSuccessful) {
			dispatch({
				type           : actionTypes.SET_CART_ITEMS_COUNT,
				cartItemsCount : parseInt(response.Message)
			});
		} else {
			dispatch({
				type           : actionTypes.SET_CART_ITEMS_COUNT,
				cartItemsCount : 0
			});
		}
	};

	return (
		<Fragment>
			{cartItemsCount > 0 && (
				<div className="app-cart-icon">
					<Link to="/cart" className="btn btn-danger btn-cart">
						<i class="fas fa-shopping-bag" />
					</Link>
					<span className="text-muted">{`${cartItemsCount} ${itemsLabel} in cart`}</span>
				</div>
			)}
		</Fragment>
	);
};

export default CartIcon;
