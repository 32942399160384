import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import SelectCreditCard from '../../../selectCreditCard/SelectCreditCard';
import './CartSelectCard.css';

const CartSelectCard = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ { selectedCartItems, cartSelectedCard }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(() => {
		dispatch({
			type             : actionTypes.SET_CART_SELECTED_CARD,
			cartSelectedCard : null
		});

		if (!selectedCartItems) {
			history.push('/cart');
		}

		setIsLoading(false);
	}, []);

	useEffect(
		() => {
			if (cartSelectedCard && !isLoading) {
				history.push('/cart/review');
			}
		},
		[ cartSelectedCard ]
	);

	const handleCardSelected = (card) => {
		dispatch({
			type             : actionTypes.SET_CART_SELECTED_CARD,
			cartSelectedCard : card
		});
	};

	return (
		<div className="app-cart-select-card panel">
			<div className="container">
				<h5 className="rw-container-title">
					<i className="fas fa-shopping-bag" />
					<span>Select Credit Card</span>
				</h5>

				<SelectCreditCard onSelect={handleCardSelected} onEmptyAutoRedirect={true} />
			</div>
		</div>
	);
};

export default CartSelectCard;
