import CrmList from './crmList/CrmList';
import './Crm.css';
import { Fragment, useEffect, useState } from 'react';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import Spinner from '../../../spinner/Spinner';
import { EmptyContactsTrash, GetLeads } from '../../../Services';
import LottiePlayer from '../../lottiePlayer/LottiePlayer';
import leadsAnimation from '../../lottiePlayer/animations/leads.json';
import ActionsBar from '../../actionsBar/ActionsBar';
import { Link } from 'react-router-dom';
import CrmNavigation from './crmNavigation/CrmNavigation';
import { toast } from 'react-toastify';

const CrmTrash = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ dashboardData, setDashboardData ] = useState(null);
	const [ leads, setLeads ] = useState([]);
	const [ fullLeadsList, setFullLeadsList ] = useState([]);
	const [ isEmptying, setIsEmptying ] = useState(false);

	useEffect(async () => {
		getLeads();
	}, []);

	const getLeads = async () => {
		setIsLoading(true);
		const response = await GetLeads();

		if (response.IsSuccessful) {
			const tempDashboardData = JSON.parse(response.Data);

			setDashboardData(tempDashboardData);
			setFullLeadsList(
				tempDashboardData.Contacts.filter((x) => x.IsDeleted)
			);

			setLeads(tempDashboardData.Contacts.filter((x) => x.IsDeleted));
		}

		setIsLoading(false);
	};

	const handleEmptyTrash = async () => {
		setIsEmptying(true);

		const response = await EmptyContactsTrash();

		if (response.IsSuccessful) {
			toast.success(response.Message);

			getLeads();
		} else {
			toast.error(response.Message);
		}

		setIsEmptying(false);
	};

	return (
		<div className="app-crm panel">
			{!isLoading ? (
				<Fragment>
					<CrmNavigation />

					{leads && leads.length > 0 ? (
						<Fragment>
							<div className="container text-left">
								<h5 className="rw-container-title">
									<i className="far fa-trash-alt" />
									<span>Trash</span>
								</h5>

								<ActionsBar align="left">
									<button
										type="button"
										className="btn btn-outline-primary"
										disabled={isLoading || isEmptying}
										onClick={handleEmptyTrash}
									>
										{!isEmptying ? (
											<Fragment>
												<i className="far fa-trash-alt" />
												<span>Empty Trash</span>
											</Fragment>
										) : (
											<Fragment>
												<Spinner />
												<span>Emptying</span>
											</Fragment>
										)}
									</button>
								</ActionsBar>

								<CrmList
									leads={leads}
									onUpdate={getLeads}
									listType="trash"
								/>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<CenteredContainer>
								<div className="container text-center">
									<LottiePlayer
										animation={leadsAnimation}
										width={300}
										height={300}
										loop={true}
									/>
									<h5 className="mt-1">
										Hoorayyy! No trash can be found here!
									</h5>
									<Link
										to="/crm"
										className="btn btn-outline-primary mt-3"
									>
										<span>Back to Leads</span>
									</Link>
								</div>
							</CenteredContainer>
						</Fragment>
					)}
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default CrmTrash;
