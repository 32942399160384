import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../spinner/Spinner';
import { useStateValue } from '../../StateProvider';
import { ExitCustomerAccount } from '../../Services';
import './LoggedInAsAdmin.css';
import { useHistory } from 'react-router-dom';
import { actionTypes } from '../../reducer';

const LoggedInAsAdmin = () => {
	const [ { user, adminUser }, dispatch ] = useStateValue();
	const [ isNoticeVisible, setIsNoticeVisible ] = useState(false);
	const [ isExiting, setIsExiting ] = useState(false);
	const history = useHistory();

	useEffect(
		() => {
			if (user && adminUser) {
				if (user.Guid !== adminUser.Guid) {
					setIsNoticeVisible(true);
				} else {
					setIsNoticeVisible(false);
				}
			} else {
				setIsNoticeVisible(false);
			}
		},
		[ user, adminUser ]
	);

	const handleExitClick = async () => {
		const response = await ExitCustomerAccount();

		if (response.IsSuccessful) {
			toast.success(response.Message);

			dispatch({
				type : actionTypes.SET_USER,
				user : adminUser
			});

			history.push('/administration');
		} else {
			toast.error(response.Message);
		}
	};

	return (
		<Fragment>
			{isNoticeVisible && (
				<Fragment>
					<div className="admin-session-notice-container">
						<span>
							{`You are logged in into a customer account ${user.Email.toLowerCase()}.`}
						</span>
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleExitClick}
							disabled={isExiting}
						>
							{!isExiting ? (
								<Fragment>
									<span>Exit Customer Account</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Exiting...</span>
								</Fragment>
							)}
						</button>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default LoggedInAsAdmin;
