import { Fragment, useEffect } from 'react';
import noLeadsImg from '../../img/no-leads.png';
import noWebsiteScreenshotImg from '../../img/no-image-avail.png';
import mailchimpLogoImg from '../../img/mailchimp-logo.jpg';
import ccCirrus from '../../img/cirrus.png';
import ccDiscover from '../../img/discover.png';
import ccMaestro from '../../img/maestro.png';
import ccMastercard from '../../img/mastercard.png';
import ccVisa from '../../img/visa.png';
import gmailLogo from '../../img/gmaillogo.png';
import officeLogo from '../../img/office365logo.png';
import godaddyLogo from '../../img/godaddylogo.png';

const AssetsLoader = ({ setPreloadedAssets, setAssetsPreloaded }) => {
	useEffect(() => {
		const assets = [
			noLeadsImg,
			noWebsiteScreenshotImg,
			mailchimpLogoImg,
			ccCirrus,
			ccDiscover,
			ccMaestro,
			ccMastercard,
			ccVisa,
			gmailLogo,
			officeLogo,
			godaddyLogo
		];

		assets.map((image) => {
			new Image().src = image;
		});

		setPreloadedAssets(assets);
		setAssetsPreloaded(true);
	}, []);

	return <Fragment />;
};

export default AssetsLoader;
