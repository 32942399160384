import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetAccountInvoices } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import NoDataMessage from '../../../noDataMessage/NoDataMessage';
import './AccountInvoices.css';

const AccountInvoices = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ invoices, setInvoices ] = useState([]);

	useEffect(() => {
		getAccInvoices();
	}, []);

	const getAccInvoices = async () => {
		const response = await GetAccountInvoices();

		if (response.IsSuccessful) {
			setInvoices(JSON.parse(response.Data));
		}

		setIsLoading(false);
	};

	return (
		<div className="rw-container shadow-large acc-invoices">
			{!isLoading ? (
				<Fragment>
					{invoices && invoices.length > 0 ? (
						<Fragment>
							<div className="acc-invoices-list">
								<div className="rw-table">
									<div className="rw-table-header">
										<div className="rw-th invoice-description">
											Invoice Description
										</div>
										<div className="rw-th invoice-status">
											Status
										</div>
										<div className="rw-th invoice-amount">
											Amount
										</div>
										<div className="rw-th action" />
									</div>

									{/* invoices list */}
									{invoices.map((invoice) => (
										<div className="rw-row">
											<div className="rw-td invoice-description">
												<span>
													{invoice.InvoiceTitle}
												</span>
											</div>
											<div
												className={`rw-td invoice-status ${invoice.Status.toLowerCase()}`}
											>
												<span>{invoice.Status}</span>
											</div>
											<div className="rw-td invoice-amount">
												<span>
													${invoice.InvoiceAmount.toFixed(2)}
												</span>
											</div>
											<div className="rw-td action">
												<a
													href={`../../c/invoice/${invoice.Guid}`}
													className="btn btn-primary"
												>
													<i className="fas fa-receipt" />
													<span>View Invoice</span>
												</a>
											</div>
										</div>
									))}
								</div>
							</div>
						</Fragment>
					) : (
						<NoDataMessage message="No invoices found" />
					)}
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default AccountInvoices;
