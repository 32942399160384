import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
	AddMessageTemplate,
	DeleteMessageTemplate,
	GetMessageTemplateDetails,
	UpdateMessageTemplate
} from '../../../../Services';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import TinymceEditor from '../../../tinymceEditor/TinymceEditor';
import Spinner from '../../../../spinner/Spinner';
import './BrandTemplateModal.css';

const BrandTemplateModal = ({ guid, onUpdate, onCancel, onDelete }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isDeleting, setIsDeleting ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ editorContent, setEditorContent ] = useState('');

	useEffect(
		() => {
			setFormData({ Name: '', Type: 'email', Subject: '', Content: '' });
			setEditorContent('');

			if (guid) {
				getMessageTemplateDetails();
			} else {
				setIsLoading(false);
			}
		},
		[ guid ]
	);

	const getMessageTemplateDetails = async () => {
		let data = { Guid: guid };
		const response = await GetMessageTemplateDetails(data);

		if (response.Success) {
			let tempFormData = response.Data;
			tempFormData.Content = maybeFormatContent(tempFormData.Content, tempFormData.Type);

			setFormData(tempFormData);
			setEditorContent(maybeFormatContent(tempFormData.Content, tempFormData.Type));
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});

		if (target === 'Content') {
			setEditorContent(maybeFormatContent(value, formData.Type));
		}
	};

	const maybeFormatContent = (content, type) => {
		return type === 'sms' ? content.replace(/(<([^>]+)>)/gi, '') : content;
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.Name || formData.Name.trim() === '') {
			toast.error('Name is required');
			return;
		}

		if (formData.Type && formData.Type === 'email') {
			if (!formData.Subject || formData.Subject.trim() === '') {
				toast.error('Subject is required');
				return;
			}
		}

		if (formData.Type && formData.Type === 'email') {
			if (!editorContent || editorContent.trim() === '') {
				toast.error('Content is required');
				return;
			}
		} else {
			if (!formData.Content || formData.Content.trim() === '') {
				toast.error('Content is required');
				return;
			}
		}

		setIsSaving(true);

		let data = {
			Name    : formData.Name,
			Type    : formData.Type,
			Subject : formData.Subject,
			Content : editorContent
		};

		let response;

		if (guid) {
			data.Guid = guid;
			response = await UpdateMessageTemplate(data);
		} else {
			response = await AddMessageTemplate(data);
		}

		if (response.Success) {
			toast.success(response.Message);

			if (onUpdate) {
				onUpdate();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleDelete = async () => {
		setIsDeleting(true);
		let data = {
			Guid : guid
		};

		const response = await DeleteMessageTemplate(data);

		if (response.Success) {
			toast.success(response.Message);

			if (onDelete) {
				onDelete();
			}
		} else {
			toast.error(response.Message);
		}

		setIsDeleting(false);
	};

	const handleEditorChange = (newValue) => {
		setEditorContent(maybeFormatContent(newValue, formData.Type));
	};

	return (
		<div className="template-modal-container">
			<ModalDialog>
				{!isLoading ? (
					<form onSubmit={handleSubmit}>
						<ModalDialogContent>
							{guid ? (
								<Fragment>
									<h5>Edit Template</h5>
								</Fragment>
							) : (
								<Fragment>
									<h5>Add New Template</h5>
								</Fragment>
							)}
							<div className="form-inputs">
								<div className="form-group">
									<label htmlFor="Name">Name</label>
									<input
										type="text"
										className="form-control form-control-lg"
										name="Name"
										disabled={isSaving || isDeleting}
										value={formData.Name}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="Type">Type</label>
									<select
										className="form-control form-control-lg"
										name="Type"
										disabled={isSaving || isDeleting}
										value={formData.Type}
										onChange={handleChange}
									>
										{/* <option value="any">Any</option> */}
										<option value="email">Email</option>
										<option value="sms">SMS</option>
									</select>
								</div>
								{formData.Type === 'email' && (
									<div className="form-group">
										<label htmlFor="Subject">Subject</label>
										<input
											type="text"
											className="form-control form-control-lg"
											name="Subject"
											disabled={isSaving || isDeleting}
											value={formData.Subject}
											onChange={handleChange}
										/>
									</div>
								)}
								<div className="form-group">
									{formData.Type === 'email' ? (
										<TinymceEditor
											onValueChange={handleEditorChange}
											initialValue={formData.Content}
											height={300}
											showMenubar={true}
											name="Content"
										/>
									) : (
										<textarea
											className="form-control form-control-lg"
											name="Content"
											disabled={isSaving}
											value={formData.Content}
											onChange={handleChange}
											rows={4}
										/>
									)}
								</div>
							</div>
						</ModalDialogContent>
						<ModalDialogButtons shaded>
							<button type="submit" className="btn btn-primary" disabled={isSaving || isDeleting}>
								{!isSaving ? <span>Save</span> : <span>Saving...</span>}
							</button>
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={handleCancel}
								disabled={isSaving || isDeleting}
							>
								<span>Cancel</span>
							</button>
							{guid && (
								<button
									type="button"
									className="btn btn-danger"
									disabled={isSaving || isDeleting}
									onClick={handleDelete}
								>
									{!isDeleting ? (
										<Fragment>
											<i className="far fa-trash-alt" />
											<span>Delete</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Deleting...</span>
										</Fragment>
									)}
								</button>
							)}
						</ModalDialogButtons>
					</form>
				) : (
					<div className="text-center p-3">
						<Spinner />
					</div>
				)}
			</ModalDialog>
		</div>
	);
};

export default BrandTemplateModal;
