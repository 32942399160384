import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../../reducer';
import {
	GetUserDetails,
	GetUserDetailsAdmin,
	SaveCompanyDetails
} from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import { useStateValue } from '../../../../../StateProvider';
import ActionsBar from '../../../../actionsBar/ActionsBar';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import './WebsiteWizardCompanyDetails.css';

const WebsiteWizardCompanyDetails = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ companyDetails, setCompanyDetails ] = useState();
	const [ { user, websiteWizardPreconfig }, dispatch ] = useStateValue();
	const { preconfigId, userGuid } = useParams();
	const history = useHistory();

	useEffect(() => {
		getUserDetails();
	}, []);

	const getUserDetails = async () => {
		if (!userGuid) {
			const response = await GetUserDetails();

			if (response.IsSuccessful) {
				setCompanyDetails(JSON.parse(response.Data));
			}
		} else {
			let data = {
				UserGuid : userGuid
			};

			const response = await GetUserDetailsAdmin(data);

			if (response.IsSuccessful) {
				setCompanyDetails(JSON.parse(response.Data));
			}
		}

		setIsLoading(false);
	};

	const handleChange = (e) => {
		setCompanyDetails({
			...companyDetails,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (
			!companyDetails.CompanyName ||
			companyDetails.CompanyName.trim() === ''
		) {
			toast.error('Business Name is required!');
			return;
		}

		if (
			!companyDetails.CompanyAddress1 ||
			companyDetails.CompanyAddress1.trim() === ''
		) {
			toast.error('Business Address is required!');
			return;
		}

		if (
			!companyDetails.CompanyCity ||
			companyDetails.CompanyCity.trim() === ''
		) {
			toast.error('Business City is required!');
			return;
		}

		if (
			!companyDetails.CompanyState ||
			companyDetails.CompanyState.trim() === ''
		) {
			toast.error('Business State is required!');
			return;
		}

		if (
			!companyDetails.CompanyPostalCode ||
			companyDetails.CompanyPostalCode.trim() === ''
		) {
			toast.error('Business ZIP Code is required!');
			return;
		}

		if (
			!companyDetails.CompanyPhone ||
			companyDetails.CompanyPhone.trim() === ''
		) {
			toast.error('Business Phone is required!');
			return;
		}

		setIsSaving(true);

		let data = companyDetails;

		dispatch({
			type                   : actionTypes.SET_WEBSITE_WIZARD_PRECONFIG,
			websiteWizardPreconfig : {
				...websiteWizardPreconfig,
				...companyDetails
			}
		});

		handleContinue();

		// const response = await SaveCompanyDetails(data);

		// if (response.IsSuccessful) {
		// 	toast.success(response.Message);

		// 	handleContinue(); // continue to the next step
		// } else {
		// 	toast.error(response.Message);
		// }

		// setIsSaving(false);
	};

	const handleContinue = () => {
		if (!userGuid) {
			history.push(`/create-website/${preconfigId}/create-website`);
		} else {
			history.push(
				`/administration/websites/create/${userGuid}/${preconfigId}/create-website`
			);
		}
	};

	const handleGoBack = () => {
		history.goBack();
	};

	const handleChatOpen = () => {
		if (window.LC_API) {
			if (user && user.Email) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email.toLowerCase();

				window.LC_API.set_visitor_name(name);
				window.LC_API.set_visitor_email(email);
			}
			window.LC_API.open_chat_window();
		}
	};

	return (
		<Fragment>
			{!isLoading ? (
				<Fragment>
					<ActionsBar className="website-wizard-actions-bar">
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={handleGoBack}
							disabled={isSaving}
						>
							<i className="fas fa-chevron-left" />
							<span>Go Back</span>
						</button>

						<div className="secondary-actions">
							<button
								className="btn btn-link btn-app-chat"
								onClick={handleChatOpen}
							>
								<span>
									Need help? <u>Click here</u>
								</span>
							</button>

							<NavLink
								className="btn btn-outline-secondary btn-close-wizard"
								activeClassName=""
								to="/"
							>
								<i className="fas fa-times m-0" />
							</NavLink>
						</div>
					</ActionsBar>

					<div className="app-website-wizard-company-info">
						<div className="container">
							{/* {!isSaving && (
								<button
									type="button"
									className="btn btn-link"
									onClick={handleGoBack}
								>
									<i className="fas fa-chevron-left" />
									<span>Go Back</span>
								</button>
							)} */}

							<h5 className="rw-container-title">
								<i className="far fa-building" />
								<span>Business Information</span>
							</h5>

							<form onSubmit={handleSubmit}>
								<div className="rw-container shadow-large company-info">
									<div className="form-inputs">
										<div className="form-group">
											<label htmlFor="email">
												Business Name
											</label>
											<input
												type="text"
												id="companyName"
												name="CompanyName"
												className="form-control form-control-lg"
												disabled={isLoading || isSaving}
												value={
													companyDetails.CompanyName
												}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="email">
												Business Address
											</label>
											<input
												type="text"
												id="companyAddress1"
												name="CompanyAddress1"
												className="form-control form-control-lg"
												disabled={isLoading || isSaving}
												value={
													companyDetails.CompanyAddress1
												}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="email">
												Business City
											</label>
											<input
												type="text"
												id="companyCity"
												name="CompanyCity"
												className="form-control form-control-lg"
												disabled={isLoading || isSaving}
												value={
													companyDetails.CompanyCity
												}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="email">
												Business State
											</label>
											<input
												type="text"
												id="companyState"
												name="CompanyState"
												className="form-control form-control-lg"
												disabled={isLoading || isSaving}
												value={
													companyDetails.CompanyState
												}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="email">
												Business ZIP Code
											</label>
											<input
												type="text"
												id="companyPostalCode"
												name="CompanyPostalCode"
												className="form-control form-control-lg"
												disabled={isLoading || isSaving}
												value={
													companyDetails.CompanyPostalCode
												}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="email">
												Business Phone
											</label>
											<input
												type="text"
												id="companyPhone"
												name="CompanyPhone"
												className="form-control form-control-lg"
												disabled={isLoading || isSaving}
												value={
													companyDetails.CompanyPhone
												}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="rw-container-buttons">
										<button
											type="submit"
											className="btn btn-primary"
											disabled={isLoading || isSaving}
										>
											{!isSaving ? (
												<Fragment>
													<span>Continue</span>
												</Fragment>
											) : (
												<Fragment>
													<span>Continuing...</span>
												</Fragment>
											)}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				</Fragment>
			)}
		</Fragment>
	);
};

export default WebsiteWizardCompanyDetails;
