import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import LottiePlayer from '../../../lottiePlayer/LottiePlayer';
import bookingAnimation from '../../../lottiePlayer/animations/booking.json';
import './BookingsComingSoon.css';

const BookingsComingSoon = () => {
	return (
		<div className="app-bookings panel">
			<CenteredContainer>
				<div className="container">
					<LottiePlayer animation={bookingAnimation} width={200} height={200} />
					<div className="app-feature-title">Coming Soon!</div>
					<h3>Book appointments and calls straight from your website.</h3>
				</div>
			</CenteredContainer>
		</div>
	);
};

export default BookingsComingSoon;
