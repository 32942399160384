import CenteredContainer from '../../centeredContainer/CenteredContainer';
import './Bookings.css';
import { Route, Switch } from 'react-router-dom';
import CalendlyBookings from './calendlyBookings/CalendlyBookings';

const Bookings = () => {
	return (
		<Switch>
			<Route path="/devbookings">
				<CalendlyBookings />
			</Route>
		</Switch>
	);
};

export default Bookings;
