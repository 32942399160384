import './Account.css';
import AccountBillingHistory from './accountBillingHistory/AccountBillingHistory';
import AccountIntegrations from './accountIntegrations/AccountIntegrations';
import AccountInvoices from './accountInvoices/AccountInvoices';
import AccountPaymentSources from './accountPaymentSources/AccountPaymentSources';
import AccountSettings from './accountSettings/AccountSettings';

const Account = () => {
	return (
		<div className="account panel">
			<div className="container">
				<h5 className="rw-container-title">
					<i className="fas fa-sliders-h" />
					<span>Account Settings</span>
				</h5>
				<AccountSettings />

				<h5 className="rw-container-title">
					<i className="fas fa-random" />
					<span>Integrations</span>
				</h5>
				<AccountIntegrations />

				<h5 className="rw-container-title">
					<i className="far fa-credit-card" />
					<span>Payment Details</span>
				</h5>
				<AccountPaymentSources />

				<h5 className="rw-container-title">
					<i className="fas fa-receipt" />
					<span>Invoices</span>
				</h5>
				<AccountInvoices />

				<h5 className="rw-container-title">
					<i className="fas fa-coins" />
					<span>Billing History</span>
				</h5>
				<AccountBillingHistory />
			</div>
		</div>
	);
};

export default Account;
