import { Fragment, useContext, useEffect, useState } from 'react';
import '../../../node_modules/shepherd.js/dist/css/shepherd.css';
import './MainApp.css';
import Spinner from '../../spinner/Spinner';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation
} from 'react-router-dom';
import WebsitesList from './websites/websitesList/WebsitesList';
import MainAppHeader from './mainAppHeader/MainAppHeader';
import MainAppNav from './mainAppNav/MainAppNav';
import DomainsList from './domains/domainsList/DomainsList';
import DomainSearch from './domains/domainSearch/DomainSearch';
import Crm from './crm/Crm';
import Account from './account/Account';
import Page404 from '../404/Page404';
import WebsiteWizard from './websites/websiteWizard/WebsiteWizard';
import WebsiteWizardPreconfigSelected from './websites/websiteWizard/websiteWizardPreconfigSelected/WebsiteWizardPreconfigSelected';
import Administration from '../administration/Administration';
import CrmLeadDetails from './crm/crmList/crmLeadDetails/CrmLeadDetails';
import AddAddonDomain from './domains/addAddonDomain/AddAddonDomain';
import DomainDetails from './domains/domainDetails/DomainDetails';
import { useStateValue } from '../../StateProvider';
import Cart from './cart/Cart';
import FreeDomainSelection from './cart/freeDomainSelection/FreeDomainSelection';
import CartSummary from './cart/cartSummary/CartSummary';
import CartSelectCard from './cart/cartSelectCard/CartSelectCard';
import CartReview from './cart/cartReview/CartReview';
import ScheduleCall from '../scheduleCall/ScheduleCall';
import CartPurchaseSummary from './cart/cartPurchaseSummary/CartPurchaseSummary';
import MakeItLiveWizard from './makeItLiveWizard/MakeItLiveWizard';
import TrialDialog from '../trialDialog/TrialDialog';
import Bookings from './bookings/Bookings';
import Payments from './payments/Payments';
import Affiliates from './affiliates/Affiliates';
import FeedbackForm from './feedbackForm/FeedbackForm';
import Training from './training/Training';
import AddCreditCard from './account/addCreditCard/AddCreditCard';
import AccountCancel from './account/accountCancel/AccountCancel';
import BookingsComingSoon from './bookings/bookingsComingSoon/BookingsComingSoon';
import LoggedInAsAdmin from '../loggedInAsAdmin/LoggedInAsAdmin';
import ViewInvoice from '../viewInvoice/ViewInvoice';
import InvoiceCodePage from '../invoiceCodePage/InvoiceCodePage';
import CustomSetupPage from '../customSetupPage/CustomSetupPage';
import CustomSetupOrderPage from '../customSetupPage/CustomSetupOrderPage';
import CrmSpam from './crm/CrmSpam';
import WebsiteWizardCompanyDetails from './websites/websiteWizard/websiteWizardCompanyDetails/WebsiteWizardCompanyDetails';
import WebsiteWizardPreconfigCreate from './websites/websiteWizard/websiteWizardPreconfigCreate/WebsiteWizardPreconfigCreate';
import CrmWebhooks from './crm/crmWebhooks/CrmWebhooks';
import CrmTrash from './crm/CrmTrash';
import CrmWebhookInstructions from './crm/crmWebhookInstructions/CrmWebhookInstructions';
import WebsiteWizardSetupWizard from './websites/websiteWizardSetupWizard/WebsiteWizardSetupWizard';
import WelcomeVideo from './welcomeVideo/WelcomeVideo';
import WebsiteWizardModal from './websites/websiteWizard/WebsiteWizardModal';
import CrmSpamFilters from './crm/CrmSpamFilters';
import guidedTourStepsFile from '../../guided-tour.json';
import guidedTourOptions from '../../guided-tour-options.json';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { actionTypes } from '../../reducer';
import CrmArchive from './crm/CrmArchive';
import BrandCustomTab from './brandCustomTab/BrandCustomTab';

const MainApp = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ { user, guidedTourSteps }, dispatch ] = useStateValue();
	const [ showTrialDialog, setShowTrialDialog ] = useState(false);
	const [ isCanceledUser, setIsCanceledUser ] = useState(false);
	const [ isTrialUser, setIsTrialUser ] = useState(false);
	const [ showWelcomeVideo, setShowWelcomeVideo ] = useState(false);
	const tour = useContext(ShepherdTourContext);
	const location = useLocation();
	const history = useHistory();

	const toggleTrialDialog = () => {
		setShowTrialDialog((prev) => !prev);
	};

	useEffect(
		() => {
			if (user) {
				if (user.Status === 'Canceled') {
					setIsCanceledUser(true);
				} else if (user.Status === 'Trial') {
					setIsTrialUser(true);
				}

				if (user.Status !== 'Canceled') {
					if (
						user.IsInstalledPlanWebsite !== null &&
						user.PreinstallSiteGuid !== null
					) {
						if (!user.IsInstalledPlanWebsite) {
							if (user.PreinstallSiteGuid !== '') {
								const pageLocation = location.pathname;

								if (
									pageLocation.indexOf(
										'/website-setup-wizard'
									) === -1
								) {
									history.push(
										`/website-setup-wizard/${user.PreinstallSiteGuid}`
									);
								}
							}
						} else {
							// user has installed the website, let's check
							// if there is a Group defined and a Welcome video attached to it
							// first let's check there's no localStorage that the user has already
							// seen the welcome video

							const seenWelcomeVideo = localStorage.getItem(
								'rwwv'
							);

							if (
								!seenWelcomeVideo &&
								seenWelcomeVideo !== '1' &&
								!showWelcomeVideo
							) {
								// user hasn't seen the welcome video, let's check the group
								if (
									user.Group &&
									user.Group.WelcomeVideoHtml &&
									user.Group.WelcomeVideoHtml !== ''
								) {
									// show the welcome video
									toggleWelcomeVideo();
								} else {
									const guidedTour = localStorage.getItem(
										'rw-gtour'
									);

									if (guidedTour) {
										// this will activate the tour inside the MainAppHeader component
										dispatch({
											type            :
												actionTypes.SET_GUIDED_TOUR_STEPS,
											guidedTourSteps : guidedTourStepsFile
										});
									}
								}
							} else {
								const guidedTour = localStorage.getItem(
									'rw-gtour'
								);

								if (guidedTour) {
									// this will activate the tour inside the MainAppHeader component
									dispatch({
										type            :
											actionTypes.SET_GUIDED_TOUR_STEPS,
										guidedTourSteps : guidedTourStepsFile
									});
								}
							}
						}
					} else {
						// user has installed the website, let's check
						// if there is a Group defined and a Welcome video attached to it
						// first let's check there's no localStorage that the user has already
						// seen the welcome video

						const seenWelcomeVideo = localStorage.getItem('rwwv');

						if (
							!seenWelcomeVideo &&
							seenWelcomeVideo !== '1' &&
							!showWelcomeVideo
						) {
							// user hasn't seen the welcome video, let's check the group
							if (
								user.Group &&
								user.Group.WelcomeVideoHtml &&
								user.Group.WelcomeVideoHtml !== ''
							) {
								// show the welcome video
								toggleWelcomeVideo();
							} else {
								const guidedTour = localStorage.getItem(
									'rw-gtour'
								);

								if (guidedTour) {
									// this will activate the tour inside the MainAppHeader component
									dispatch({
										type            :
											actionTypes.SET_GUIDED_TOUR_STEPS,
										guidedTourSteps : guidedTourStepsFile
									});
								}
							}
						} else {
							const guidedTour = localStorage.getItem('rw-gtour');

							if (guidedTour) {
								// this will activate the tour inside the MainAppHeader component
								dispatch({
									type            :
										actionTypes.SET_GUIDED_TOUR_STEPS,
									guidedTourSteps : guidedTourStepsFile
								});
							}
						}
					}
				}
			}
		},
		[ user ]
	);

	const toggleWelcomeVideo = () => {
		setShowWelcomeVideo((prev) => !prev);
	};

	return (
		<Fragment>
			{isLoading ? (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			) : (
				<div className="main-app">
					<div className="main-app-container">
						{isCanceledUser && <Redirect to="/domains" />}

						{isTrialUser && <Redirect to="/" />}

						<Switch>
							<Route path="/administration">
								<Administration />
							</Route>
							<Route path="/custom-setup/order/:userGuid?">
								<CustomSetupOrderPage />
							</Route>
							<Route path="/custom-setup-offer/:userGuid?">
								<CustomSetupPage />
							</Route>
							<Route path="/invoice-code">
								<InvoiceCodePage />
							</Route>
							<Route path="/invoice/:invoiceGuid">
								<ViewInvoice />
							</Route>
							<Route path="/configure-website/:websiteId">
								<LoggedInAsAdmin />
								<WebsiteWizardSetupWizard />
							</Route>
							<Route path="/website-setup-wizard/:preconfigId">
								<LoggedInAsAdmin />
								<WebsiteWizardSetupWizard />
							</Route>
							<Route path="/">
								<ShepherdTour
									steps={guidedTourSteps}
									tourOptions={guidedTourOptions}
								>
									<LoggedInAsAdmin />
									<MainAppHeader chatButtonText="Get Help" />
									<MainAppNav
										onTrialClick={toggleTrialDialog}
									/>
									<ScheduleCall />
									{showTrialDialog && (
										<TrialDialog
											closeDialog={toggleTrialDialog}
										/>
									)}

									<Route path="/make-it-live/:guid">
										<MakeItLiveWizard />
									</Route>

									<Switch>
										<Route path="/cart/purchase-review">
											<CartPurchaseSummary />
										</Route>
										<Route path="/cart/review">
											<CartReview />
										</Route>
										<Route path="/cart/select-card">
											<CartSelectCard />
										</Route>
										<Route path="/cart/summary">
											<CartSummary />
										</Route>
										<Route path="/cart/free-domain">
											<FreeDomainSelection />
										</Route>
										<Route path="/cart">
											<Cart />
										</Route>
										<Route path="/training">
											<Training />
										</Route>
										<Route path="/content/:tabGuid">
											<BrandCustomTab />
										</Route>
										<Route path="/feedback">
											<FeedbackForm />
										</Route>
										<Route path="/affiliates">
											<Affiliates />
										</Route>
										<Route path="/account/cancel">
											<AccountCancel />
										</Route>
										<Route path="/account/add-credit-card">
											<AddCreditCard />
										</Route>
										<Route path="/account">
											<Account />
										</Route>
										<Route path="/crm/lead/:leadId">
											<CrmLeadDetails />
										</Route>
										<Route path="/crm/webhook-instructions">
											<CrmWebhookInstructions />
										</Route>
										<Route path="/crm/filters">
											<CrmSpamFilters />
										</Route>
										<Route path="/crm/webhooks">
											<CrmWebhooks />
										</Route>
										<Route path="/crm/trash">
											<CrmTrash />
										</Route>
										<Route path="/crm/spam">
											<CrmSpam />
										</Route>
										<Route path="/crm/archive">
											<CrmArchive />
										</Route>
										<Route path="/crm">
											<Crm />
										</Route>
										<Route path="/domains/search">
											<DomainSearch />
										</Route>
										<Route path="/domains/add-existing-domain">
											<AddAddonDomain />
										</Route>
										<Route path="/domains/:domainGuid/:domainName">
											<DomainDetails />
										</Route>
										<Route path="/domains">
											<DomainsList />
										</Route>
										<Route path="/payments">
											<Payments />
										</Route>
										<Route path="/devbookings">
											<Bookings />
										</Route>
										<Route path="/bookings">
											<BookingsComingSoon />
										</Route>

										<Route path="/create-website">
											<WebsiteWizardModal />
										</Route>
										<Route path="/" exact={true}>
											<WebsitesList
												toggleTrialDialog={
													toggleTrialDialog
												}
											/>
										</Route>
										<Route path="/make-it-live">
											<WebsitesList
												toggleTrialDialog={
													toggleTrialDialog
												}
											/>
										</Route>
										<Route path="*">
											<Page404 />
										</Route>
									</Switch>
								</ShepherdTour>
							</Route>
						</Switch>

						{showWelcomeVideo && (
							<WelcomeVideo onClose={toggleWelcomeVideo} />
						)}
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default MainApp;
