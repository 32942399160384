import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { SendForgotPasswordEmail } from '../../../Services';
import Spinner from '../../../spinner/Spinner';

const ForgotPasswordForm = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ isEmailTriggered, setIsEmailTriggered ] = useState(false);

	const handleChange = (e) => {
		const value = e.target.value.trim();

		setEmail(value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		if (email === '') {
			toast.error('Valid email address is required');
			return;
		}

		let data = {
			Email : email
		};

		const response = await SendForgotPasswordEmail(data);

		if (response) {
			setIsEmailTriggered(true);
		}

		setIsLoading(false);
	};

	return (
		<div className="forgot-password-form">
			{!isEmailTriggered ? (
				<Fragment>
					<form onSubmit={handleSubmit}>
						<div className="form-group mt-3">
							<label htmlFor="email" className="form-control-label">
								Email
							</label>
							<input
								id="email"
								type="email"
								name="email"
								className="form-control form-control-lg"
								value={email}
								onChange={handleChange}
								disabled={isLoading}
							/>
						</div>
						<button
							id="btnSendPasswordResetEmail"
							type="submit"
							className="btn btn-primary btn-lg btn-block mt-3"
							disabled={isLoading}
						>
							{isLoading ? <Spinner /> : <span>Continue</span>}
						</button>
					</form>
				</Fragment>
			) : (
				<Fragment>
					<div className="alert alert-secondary m-0">
						If an account is found under the email address you entered an email will be sent to your inbox
						with instructions on resetting the account password.
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default ForgotPasswordForm;
