import { Fragment, useEffect } from 'react';
import { useStateValue } from '../../../../StateProvider';
import './ChatButton.css';

const ChatButton = ({ chatButtonText }) => {
	const [ { user }, dispatch ] = useStateValue();

	useEffect(
		() => {
			setChatDetails();
		},
		[ user ]
	);

	const setChatDetails = () => {
		if (window.LC_API) {
			if (user && (user.Email || user.EmailAddress)) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email
					? user.Email.toLowerCase()
					: user.EmailAddress.toLowerCase();

				try {
					window.LC_API.set_visitor_name(name);
					window.LC_API.set_visitor_email(email);
				} catch (e) {}
			}
		}
	};

	const handleChatOpen = () => {
		if (window.LC_API) {
			setChatDetails();

			setTimeout(
				() => {
					window.LC_API.open_chat_window();
				},
				[ 100 ]
			);
		}
	};

	return (
		<Fragment>
			<button
				className="btn btn-outline-primary btn-app-chat"
				onClick={handleChatOpen}
			>
				{!chatButtonText ? (
					<Fragment>
						<i class="far fa-comment-alt" />
						<span>Chat</span>
					</Fragment>
				) : (
					<Fragment>
						<i class="far fa-comment-alt" />
						<span>{chatButtonText}</span>
					</Fragment>
				)}
			</button>
		</Fragment>
	);
};

export default ChatButton;
