import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import FreeDomain from './freeDomain/FreeDomain';
import './FreeDomainSelection.css';

const FreeDomainSelection = () => {
	const [ { selectedCartItems }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(() => {
		if (!selectedCartItems) {
			history.push('/cart');
		}
	}, []);

	const handleFreeDomainSelect = (domain) => {
		dispatch({
			type       : actionTypes.SET_FREE_DOMAIN,
			freeDomain : domain
		});

		history.push('/cart/summary');
	};

	const handleContinueButton = () => {
		dispatch({
			type       : actionTypes.SET_FREE_DOMAIN,
			freeDomain : selectedCartItems[0]
		});

		history.push('/cart/summary');
	};

	return (
		<div className="app-free-domain-selection panel">
			<div className="container">
				<h5 className="rw-container-title">
					<i className="fas fa-shopping-bag" />
					<span>Great! You have a FREE domain credit</span>
					{selectedCartItems &&
					selectedCartItems.length > 1 && (
						<div className="text-muted">
							<small>Please select the domain in the list to apply the credit</small>
						</div>
					)}
				</h5>

				{selectedCartItems && (
					<div className="rw-container">
						{selectedCartItems.length > 1 ? (
							<div className="free-domain-selection-container">
								<div className="rw-table">
									<div className="rw-table-header">
										<div className="rw-th name">Domain Name</div>
										<div className="rw-th action" />
									</div>

									{selectedCartItems.map((domain) => (
										<FreeDomain key={domain.Id} domain={domain} onSelect={handleFreeDomainSelect} />
									))}
								</div>
							</div>
						) : (
							<Fragment>
								<div className="alert alert-success m-0">
									<span>Free domain credit has been applied to this domain purchase.</span>
								</div>
								<div className="rw-container-buttons">
									<button type="button" className="btn btn-primary" onClick={handleContinueButton}>
										<i className="fas fa-chevron-right" />
										<span>Continue</span>
									</button>
								</div>
							</Fragment>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default FreeDomainSelection;
