import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../../../StateProvider';
import './CartSummary.css';
import CartSummaryItem from './cartSummaryItem/CartSummaryItem';

const CartSummary = () => {
	const [ { selectedCartItems, freeDomain, user }, dispatch ] = useStateValue();
	const [ total, setTotal ] = useState(0);
	const [ balanceDue, setBalanceDue ] = useState(0);
	const history = useHistory();

	useEffect(() => {
		if (!selectedCartItems) {
			history.push('/cart');
		}
	}, []);

	useEffect(
		() => {
			if (selectedCartItems) {
				let tempTotal = 0;

				selectedCartItems.map((item) => {
					let itemPrice = item.TermPrice;

					if (freeDomain && freeDomain.Id === item.Id) {
						itemPrice -= item.YearlyPrice;
					}

					tempTotal += itemPrice;
				});

				setTotal(tempTotal);

				if (user.CreditsBalance > 0) {
					if (user.CreditsBalance > tempTotal) {
						setBalanceDue(0);
					} else {
						setBalanceDue(tempTotal - user.CreditsBalance);
					}
				} else {
					setBalanceDue(tempTotal);
				}
			}
		},
		[ selectedCartItems ]
	);

	const handleContinue = () => {
		history.push('/cart/select-card');
	};

	return (
		<div className="app-cart-summary panel">
			<div className="container">
				<h5 className="rw-container-title">
					<i className="fas fa-shopping-bag" />
					<span>Cart Summary</span>
				</h5>

				<div className="rw-container">
					<div className="cart-summary-container">
						<div className="rw-table">
							<div className="rw-table-header">
								<div className="rw-th description">Description</div>
								<div className="rw-th price">Price</div>
							</div>

							{selectedCartItems &&
							selectedCartItems.length > 0 && (
								<Fragment>
									{selectedCartItems.map((domain) => (
										<CartSummaryItem key={domain.Id} domain={domain} showDescription={true} />
									))}
								</Fragment>
							)}

							<div className="rw-row total-row">
								<div className="rw-td description text-right">
									<span>Total:</span>
								</div>
								<div className="rw-td price">
									<span>${total.toFixed(2)}</span>
								</div>
							</div>

							{user.CreditsBalance > 0 && (
								<div className="rw-row total-row">
									<div className="rw-td description text-right">
										<span>Credits Available:</span>
									</div>
									<div className="rw-td price">
										<span>${user.CreditsBalance.toFixed(2)}</span>
									</div>
								</div>
							)}

							<div className="rw-row total-row">
								<div className="rw-td description text-right">
									<span>Balance Due:</span>
								</div>
								<div className="rw-td price">
									<span>${balanceDue.toFixed(2)}</span>
								</div>
							</div>
						</div>
						<div className="rw-container-buttons">
							<button type="button" className="btn btn-primary" onClick={handleContinue}>
								<i className="fas fa-chevron-right" />
								<span>Continue</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartSummary;
