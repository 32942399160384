import { Fragment, useEffect, useState } from 'react';
import ccCirrus from '../../../img/cirrus.png';
import ccAmex from '../../../img/amex.png';
import ccDiscover from '../../../img/discover.png';
import ccMaestro from '../../../img/maestro.png';
import ccMastercard from '../../../img/mastercard.png';
import ccVisa from '../../../img/visa.png';
import './CreditCard.css';

const CreditCard = ({ creditCard, onSelect, selectedCard }) => {
	const [ activeClass, setActiveClass ] = useState('');
	const [ cardIcon, setCardIcon ] = useState(ccVisa);

	useEffect(() => {
		if (creditCard) {
			switch (creditCard.CardType.toLowerCase()) {
				case 'visa':
					setCardIcon(ccVisa);
					break;
				case 'mastercard':
					setCardIcon(ccMastercard);
					break;
				case 'americanexpress':
					setCardIcon(ccAmex);
					break;
				case 'discover':
					setCardIcon(ccDiscover);
					break;
				case 'maestro':
					setCardIcon(ccMaestro);
					break;
				case 'cirrus':
					setCardIcon(ccCirrus);
					break;
				default:
					break;
			}
		}
	}, []);

	useEffect(
		() => {
			if (selectedCard && selectedCard.Guid === creditCard.Guid) {
				setActiveClass('active');
			} else {
				setActiveClass('');
			}
		},
		[ selectedCard ]
	);

	const handleOnClick = () => {
		if (onSelect) {
			onSelect(creditCard);
		}
	};

	return (
		<div className={`app-credit-card ${creditCard.CardType.toLowerCase()} ${activeClass}`} onClick={handleOnClick}>
			<div className={`rw-cc-icon ${creditCard.CardType.toLowerCase()}`}>
				<img src={cardIcon} alt="Credit Card Icon" />
			</div>
			<div className="rw-cc-details">
				<div className="card-type">
					<span>{creditCard.CardType}</span>
				</div>
				<div className="card-numbers">
					<span>**** {creditCard.CardDigits}</span>
				</div>
				<div className="card-expiration">
					<span>
						{creditCard.ExpireMonth} / {creditCard.ExpireYear}
					</span>
				</div>
			</div>
		</div>
	);
};

export default CreditCard;
