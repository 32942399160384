import { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './EditUserInvoiceItem.css';

const EditUserInvoiceItem = ({ invoice, onDelete }) => {
	const [ invoiceLink, setInvoiceLink ] = useState('');
	const copyLinkRef = useRef(null);

	useEffect(
		() => {
			setInvoiceLink(
				`${window.location.origin}/c/invoice/${invoice.Guid}`
			);
		},
		[ invoice.Guid ]
	);

	const handleDeleteClick = () => {
		if (onDelete) {
			onDelete(invoice);
		}
	};

	const handleCopyClick = () => {
		if (copyLinkRef.current) {
			// copyLinkRef.current.focus();
			copyLinkRef.current.select();

			const success = document.execCommand('copy');

			if (success) {
				toast.success('Link copied successfully');
			} else {
				toast.error('Could not copy the link');
			}
		}
	};

	return (
		<Fragment>
			<div className="rw-row">
				<div className="rw-td invoice-description">
					<span>{invoice.InvoiceTitle}</span>
				</div>
				<div
					className={`rw-td invoice-status ${invoice.Status.toLowerCase()}`}
				>
					<span>{invoice.Status}</span>
				</div>
				<div className="rw-td invoice-amount">
					<span>${invoice.InvoiceAmount.toFixed(2)}</span>
				</div>
				<div className="rw-td action">
					<button
						type="button"
						className="btn btn-primary"
						onClick={handleCopyClick}
						title="Copy link to this invoice"
					>
						<i className="far fa-copy mr-0" />
					</button>
					<a
						href={invoiceLink}
						className="btn btn-primary"
						target="_blank"
					>
						<i className="fas fa-search mr-0" />
					</a>

					<Link
						to={`/administration/invoices/edit/${invoice.Guid}`}
						className="btn btn-primary"
					>
						<i className="fas fa-pencil-alt mr-0" />
					</Link>

					<button
						type="button"
						className="btn btn-danger"
						onClick={handleDeleteClick}
					>
						<i className="far fa-trash-alt mr-0" />
					</button>
				</div>
			</div>
			<textarea
				ref={copyLinkRef}
				value={invoiceLink}
				className="clipboard-input"
			/>
		</Fragment>
	);
};

export default EditUserInvoiceItem;
