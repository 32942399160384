import './NoDataMessage.css';

const NoDataMessage = ({ message }) => {
	return (
		<div className="rw-message alert alert-warning">
			{!message ? <span>No data at this time</span> : <span>{message}</span>}
		</div>
	);
};

export default NoDataMessage;
