import { Fragment } from 'react';
import { animationDefaults } from '../../AnimConfig';
import './ModalDialog.css';

const ModalDialogFullScreen = ({ children }) => {
	return (
		<Fragment>
			<div className="modal-background" />
			<div className="modal-dialog-container full-screen">
				<div className="modal-dialog shadow-large xyz-in" xyz={animationDefaults.xyzDefaultSettings}>
					{children}
				</div>
			</div>
		</Fragment>
	);
};

export default ModalDialogFullScreen;
