import { Fragment, useEffect, useState } from 'react';
import { AddDomainSubdomain } from '../../../../../../Services';
import { toast } from 'react-toastify';
import Spinner from '../../../../../../spinner/Spinner';
import './AddSubdomain.css';

const AddSubdomain = ({ domain, onSave, onCancel }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ subdomainDetails, setSubdomainDetails ] = useState({});

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleChange = (e) => {
		setSubdomainDetails({
			Name : e.target.value.trim()
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!subdomainDetails.Name || subdomainDetails.Name === '') {
			toast.error('Subdomain name is required!');
			return;
		}

		setIsSaving(true);

		let data = {
			Guid          : domain.Guid,
			SubdomainName : subdomainDetails.Name
		};

		const response = await AddDomainSubdomain(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onSave) {
				onSave();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<div className="add-subdomain-record">
				<form onSubmit={handleSubmit} autoComplete="off">
					<label htmlFor="subdomainName">Subdomain Name</label>
					<div className="input-group">
						<input
							name="Name"
							type="text"
							id="subdomainName"
							className="form-control form-control-lg"
							placeholder="Example: sales"
							disabled={isSaving}
							value={subdomainDetails.Name}
							onChange={handleChange}
						/>
						<div class="input-group-append">
							<span className="input-group-text">.{domain.DomainName.toLowerCase()}</span>
						</div>
					</div>
					<div className="buttons-container">
						<button type="submit" className="btn btn-primary" disabled={isSaving}>
							{!isSaving ? (
								<Fragment>
									<i className="fas fa-save" />
									<span>Save</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Saving Subdomain...</span>
								</Fragment>
							)}
						</button>

						<button
							type="button"
							className="btn btn-outline-primary"
							disabled={isSaving}
							onClick={handleCancel}
						>
							<i className="fas fa-chevron-left" />
							<span>Cancel</span>
						</button>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default AddSubdomain;
