import { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import './MainAppNav.css';

const MainAppNav = ({ onTrialClick }) => {
	const [ { user, brand }, dispatch ] = useStateValue();
	const [ userStatus, setUserStatus ] = useState('');
	const [ tabs, setTabs ] = useState();

	useEffect(
		() => {
			if (user) {
				setUserStatus(user.Status);
			}
		},
		[ user ]
	);

	useEffect(
		() => {
			if (brand && brand.Tabs && brand.Tabs.length > 0) {
				setTabs(brand.Tabs);
			} else {
				setTabs();
			}
		},
		[ brand ]
	);

	const handleTrialClick = () => {
		if (onTrialClick) {
			onTrialClick();
		}
	};

	return (
		<div className="app-navbar shadow-medium">
			<nav className="navbar">
				{(userStatus === 'Active' || userStatus === 'Trial') && (
					<NavLink
						to="/"
						exact={true}
						activeClassName="active"
						className="nav-item nav-websites"
					>
						<i className="far fa-list-alt" />
						<span>Websites</span>
					</NavLink>
				)}

				{userStatus === 'Active' && (
					<Fragment>
						<NavLink
							to="/crm"
							activeClassName="active"
							className="nav-item nav-leads"
						>
							<i className="fas fa-chalkboard-teacher" />
							<span>Leads</span>
						</NavLink>
					</Fragment>
				)}

				{userStatus === 'Trial' && (
					<Fragment>
						<a
							onClick={handleTrialClick}
							className="nav-item nav-leads btn-trial"
						>
							<i className="fas fa-chalkboard-teacher" />
							<span>Leads</span>
						</a>
					</Fragment>
				)}

				{/* temporarily disabled */}
				{/* {userStatus === 'Active' && (
					<Fragment>
						<NavLink
							to="/bookings"
							activeClassName="active"
							className="nav-item"
						>
							<i className="far fa-calendar-alt" />
							<span>Bookings</span>
						</NavLink>
					</Fragment>
				)}

				{userStatus === 'Trial' && (
					<Fragment>
						<a
							onClick={handleTrialClick}
							className="nav-item btn-trial"
						>
							<i className="far fa-calendar-alt" />
							<span>Bookings</span>
						</a>
					</Fragment>
				)}

				{userStatus === 'Active' && (
					<Fragment>
						<NavLink
							to="/payments"
							activeClassName="active"
							className="nav-item"
						>
							<i className="fas fa-wallet" />
							<span>Payments</span>
						</NavLink>
					</Fragment>
				)}

				{userStatus === 'Trial' && (
					<Fragment>
						<a
							onClick={handleTrialClick}
							className="nav-item btn-trial"
						>
							<i className="fas fa-wallet" />
							<span>Payments</span>
						</a>
					</Fragment>
				)}
				*/}

				{(userStatus === 'Active' || userStatus === 'Canceled') && (
					<Fragment>
						<NavLink
							to="/domains"
							activeClassName="active"
							className="nav-item nav-domains"
						>
							<i className="fas fa-globe-americas" />
							<span>Domains</span>
						</NavLink>
					</Fragment>
				)}

				{userStatus === 'Trial' && (
					<Fragment>
						<a
							onClick={handleTrialClick}
							className="nav-item nav-domains btn-trial"
						>
							<i className="fas fa-globe-americas" />
							<span>Domains</span>
						</a>
					</Fragment>
				)}

				<NavLink
					to="/account"
					activeClassName="active"
					className="nav-item nav-account"
				>
					<i className="far fa-user-circle" />
					<span>Account</span>
				</NavLink>

				{tabs && (
					<Fragment>
						{tabs.map((tab) => (
							<Fragment>
								{((tab.Icon && tab.Icon !== '') ||
									(tab.Name && tab.Name !== '')) && (
									<Fragment>
										{!tab.IsEmbedded ? (
											<Fragment>
												<a
													key={tab.Guid}
													href={tab.Content}
													target="_blank"
													className="nav-item"
												>
													{tab.Icon &&
													tab.Icon !== '' && (
														<i
															className={tab.Icon}
														/>
													)}
													{tab.Name &&
													tab.Name !== '' && (
														<span>{tab.Name}</span>
													)}
												</a>
											</Fragment>
										) : (
											<Fragment>
												<NavLink
													key={tab.Guid}
													to={`/content/${tab.Guid}`}
													activeClassName="active"
													className="nav-item"
												>
													{tab.Icon &&
													tab.Icon !== '' && (
														<i
															className={tab.Icon}
														/>
													)}
													{tab.Name &&
													tab.Name !== '' && (
														<span>{tab.Name}</span>
													)}
												</NavLink>
											</Fragment>
										)}
									</Fragment>
								)}
							</Fragment>
						))}
					</Fragment>
				)}
			</nav>
		</div>
	);
};

export default MainAppNav;
