import { Link } from 'react-router-dom';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import './Page404.css';

const Page404 = () => {
	return (
		<div className="app-404 error">
			<CenteredContainer>
				<div className="container">
					<div className="error-page-code">404</div>
					<h3>Unfortunately, what you're looking for isn't here</h3>
					<Link to="/" className="btn btn-primary">
						<i class="fas fa-home" />
						<span>Take me Home</span>
					</Link>
				</div>
			</CenteredContainer>
		</div>
	);
};

export default Page404;
