import { Fragment, useEffect, useRef, useState } from 'react';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import './CrmLeadTags.css';
import Creatable, { useCreatable } from 'react-select/creatable';
import Spinner from '../../../../../spinner/Spinner';
import { UpdateContactTags } from '../../../../../Services';
import { toast } from 'react-toastify';

const CrmLeadTags = ({ lead, onUpdate, onClose }) => {
	const [ tags, setTags ] = useState([]);
	const [ dropdownSource, setDropdownSource ] = useState();
	const [ defaultSelected, setDefaultSelected ] = useState();
	const [ isSaving, setIsSaving ] = useState(false);
	const dropdownRef = useRef();

	const components = {
		DropdownIndicator : null
	};

	useEffect(
		() => {
			if (lead) {
				if (lead.Tags && lead.Tags.length > 0) {
					setTags(lead.Tags);

					let source = lead.Tags.map((tag) => ({
						value : tag,
						label : tag
					}));

					setDropdownSource(source);
					setDefaultSelected(source);

					dropdownRef.current.setValue(source);
				}
			}
		},
		[ lead, dropdownRef ]
	);

	const handleChange = (selectedTags) => {
		let newTags = [];

		if (selectedTags && selectedTags.length > 0) {
			newTags = selectedTags.map((tag) => tag.value);
		}

		setTags(newTags);
	};

	const handleOnCancel = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let data = {
			Guid : lead.Guid,
			Tags : tags
		};

		const response = await UpdateContactTags(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onUpdate) {
				onUpdate();

				if (onClose) {
					onClose();
				}
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<ModalDialog>
			<form onSubmit={handleSubmit}>
				<ModalDialogContent>
					<h5>{lead.FullName} Tags</h5>
					<p>
						Manage the tags for this contact by typing in a new tag
						into the input box below or by removing one of the
						existing tags.
					</p>
					<Creatable
						components={components}
						ref={dropdownRef}
						options={dropdownSource}
						isMulti
						defaultValue={defaultSelected}
						closeMenuOnSelect={false}
						openMenuOnClick={false}
						onChange={handleChange}
						isDisabled={isSaving}
					/>
				</ModalDialogContent>
				<ModalDialogButtons align="left" shaded={true}>
					<button
						type="submit"
						className="btn btn-primary"
						disabled={isSaving}
					>
						{!isSaving ? (
							<Fragment>
								<i class="fas fa-cloud-upload-alt" />
								<span>Save</span>
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
								<span>Saving...</span>
							</Fragment>
						)}
					</button>
					<button
						type="button"
						className="btn btn-outline-primary"
						disabled={isSaving}
						onClick={handleOnCancel}
					>
						<i class="fas fa-window-close" />
						<span>Cancel</span>
					</button>
				</ModalDialogButtons>
			</form>
		</ModalDialog>
	);
};

export default CrmLeadTags;
