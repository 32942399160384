import CrmList from './crmList/CrmList';
import './Crm.css';
import { Fragment, useEffect, useState } from 'react';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import Spinner from '../../../spinner/Spinner';
import { GetLeads } from '../../../Services';
import LottiePlayer from '../../lottiePlayer/LottiePlayer';
import leadsAnimation from '../../lottiePlayer/animations/leads.json';
import ActionsBar from '../../actionsBar/ActionsBar';
import { Link } from 'react-router-dom';
import CrmNavigation from './crmNavigation/CrmNavigation';

const Crm = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ dashboardData, setDashboardData ] = useState(null);
	const [ leads, setLeads ] = useState([]);
	const [ fullLeadsList, setFullLeadsList ] = useState([]);

	useEffect(async () => {
		await getLeads();
	}, []);

	const getLeads = async () => {
		const response = await GetLeads();

		if (response.IsSuccessful) {
			const tempDashboardData = JSON.parse(response.Data);

			setDashboardData(tempDashboardData);
			setFullLeadsList(
				tempDashboardData.Contacts.filter(
					(x) => !x.IsSpam && !x.IsDeleted && x.Status !== 'Archived'
				)
			);

			setLeads(
				tempDashboardData.Contacts.filter(
					(x) => !x.IsSpam && !x.IsDeleted && x.Status !== 'Archived'
				)
			);
		}

		setIsLoading(false);
	};

	return (
		<div className="app-crm panel">
			{!isLoading ? (
				<Fragment>
					<CrmNavigation />

					{leads && leads.length > 0 ? (
						<Fragment>
							<div className="container text-left">
								<h5 className="rw-container-title">
									<i className="fas fa-chalkboard-teacher" />
									<span>Your Leads</span>
								</h5>

								{/* {dashboardData.SpamCount > 0 && (
									<Fragment>
										<div className="d-flex justify-content-end mb-3">
											<Link
												to="/crm/spam"
												className="btn btn-outline-secondary"
											>
												<i className="far fa-trash-alt" />
												<span>
													Spam ({dashboardData.SpamCount})
												</span>
											</Link>
										</div>
									</Fragment>
								)} */}

								<CrmList leads={leads} onUpdate={getLeads} />
							</div>
						</Fragment>
					) : (
						<Fragment>
							<CenteredContainer>
								<div className="container text-center">
									<LottiePlayer
										animation={leadsAnimation}
										width={300}
										height={300}
										loop={true}
									/>
									<h5 className="mt-1">
										Looks like you don't have any leads in
										your CRM at the moment
									</h5>
								</div>
							</CenteredContainer>
						</Fragment>
					)}
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default Crm;
