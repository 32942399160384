import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../../../spinner/Spinner';
import { countriesList } from '../../../../../countries';
import './EditUserAccountSettings.css';
import { SaveUserSettingsAdmin } from '../../../../../Services';
import { toast } from 'react-toastify';

const EditUserAccountSettings = ({ userDetails, onUpdate }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);
	const USPhoneRegex = /^\d{10}$/;
	const phoneRegex = /^\d+/g;
	const [ formUser, setFormUser ] = useState(null);

	useEffect(
		() => {
			setFormUser({
				...userDetails,
				Password : ''
			});
		},
		[ userDetails ]
	);

	const handleChange = (e) => {
		const target = e.target.name;
		let value = e.target.value;

		if (target === 'Phone') {
			value = e.target.value.replace(/[^0-9]+/g, '');
		}

		setFormUser({
			...formUser,
			[target] : value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let isValid = false;

		const isPhoneNumber = phoneRegex.test(formUser.Phone);

		if (formUser.Phone !== '') {
			if (formUser.Country === 'United States') {
				if (USPhoneRegex.test(formUser.Phone)) {
					isValid = true;
				}
			} else if (isPhoneNumber) {
				isValid = true;
			}
		}

		if (!isValid) {
			toast.error('Please enter a valid phone number to proceed');
			return;
		}

		if (formUser.Email.trim() === '') {
			toast.error('Email address is required');
			return;
		}

		if (formUser.FirstName.trim() === '') {
			toast.error('First name is required');
			return;
		}

		if (formUser.LastName.trim() === '') {
			toast.error('Last name is required');
			return;
		}

		setIsSaving(true);

		let tempData = formUser;
		tempData.EmailAddress = formUser.Email;
		tempData.UserGuid = userDetails.Guid;

		const response = await SaveUserSettingsAdmin(tempData);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onUpdate) {
				onUpdate(tempData);
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<div className="rw-container shadow-large settings">
			{formUser ? (
				<form onSubmit={handleSubmit} autoComplete="off">
					<div className="form-inputs">
						<div className="form-group">
							<label htmlFor="email">Email</label>
							<input
								type="email"
								id="email"
								name="Email"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.Email}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="password">Password</label>
							<input
								type="password"
								id="password"
								name="Password"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.Password}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="firstName">First name</label>
							<input
								type="text"
								id="firstName"
								name="FirstName"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.FirstName}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="lastName">Last name</label>
							<input
								type="text"
								id="lastName"
								name="LastName"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.LastName}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="phone">Phone</label>
							<div className="input-group">
								<select
									className="form-control form-control-lg"
									style={{ width: '30%' }}
									name="Country"
									disabled={isSaving}
									value={formUser.Country}
									onChange={handleChange}
								>
									{countriesList.map((country) => (
										<option
											key={country.name}
											value={country.name}
										>
											{country.name} ({country.dial_code})
										</option>
									))}
								</select>
								<input
									onChange={handleChange}
									id="phone"
									name="Phone"
									type="tel"
									disabled={isSaving}
									value={formUser.Phone}
									className="form-control form-control-lg"
									placeholder="Phone (format: 8005130456)"
									style={{ width: '70%' }}
								/>
							</div>
						</div>
					</div>
					<div className="rw-container-buttons">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={isSaving}
						>
							{!isSaving ? (
								<Fragment>
									<i class="fas fa-cloud-upload-alt" />
									<span>Save Changes</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Saving...</span>
								</Fragment>
							)}
						</button>
					</div>
				</form>
			) : (
				<div className="text-center p-3">
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default EditUserAccountSettings;
