import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../../spinner/Spinner';
import { countriesList } from '../../../../countries';
import './CreateUser.css';
import ActionsBar from '../../../actionsBar/ActionsBar';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../../StateProvider';
import { CreateUserAccount, GetBrandUsersLimit } from '../../../../Services';

const CreateUser = () => {
	const [ { user, brand }, dispatch ] = useStateValue();
	const [ formUser, setFormUser ] = useState({});
	const [ isSaving, setIsSaving ] = useState(false);
	const USPhoneRegex = /^\d{10}$/;
	const phoneRegex = /^\d+/g;
	const history = useHistory();
	const [ brandLimit, setBrandLimits ] = useState();

	useEffect(() => {
		getBrandLimits();
		if (!formUser.Country) {
			formUser.Country = 'United States';
		}

		if (!formUser.BrandId) {
			formUser.BrandId = brand.Id;
		}

		if (!formUser.SendWelcomeEmail) {
			formUser.SendWelcomeEmail = false;
		}
	}, []);

	const getBrandLimits = async () => {
		const response = await GetBrandUsersLimit();

		if (response.Success) {
			setBrandLimits(response.Data);
		} else {
			setBrandLimits();
		}
	};

	const handleChange = (e) => {
		if (e.target.checked) {
			if (e.target.name !== 'Status') {
				setFormUser({
					...formUser,
					[e.target.name]: e.target.checked
				});
			} else {
				setFormUser({
					...formUser,
					[e.target.name]: e.target.value
				});
			}
		} else {
			if (e.target.name === 'Phone') {
				let value = e.target.value.replace(/[^0-9]+/g, '');

				setFormUser({
					...formUser,
					[e.target.name]: value
				});
			} else {
				setFormUser({
					...formUser,
					[e.target.name]: e.target.value
				});
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let tempFormUser = formUser;

		if (!tempFormUser.Email) {
			toast.error('Email address is required!');
			return;
		}

		if (!tempFormUser.Password) {
			toast.error('Password is required!');
			return;
		}

		if (!tempFormUser.FirstName || !tempFormUser.LastName) {
			toast.error('First and last name are required!');
			return;
		}

		if (!tempFormUser.Phone) {
			toast.error('Phone is required!');
			return;
		}

		if (brand.Id === 1) {
			if (!tempFormUser.Status) {
				toast.error('Account Status is required!');
				return;
			}
		} else {
			//always Active for brand
			tempFormUser.Status = 'Active';
		}

		setIsSaving(true);

		// console.log(tempFormUser);

		tempFormUser.CompanyName = tempFormUser.CompanyName || '';
		tempFormUser.CompanyAddress1 = tempFormUser.CompanyAddress1 || '';
		tempFormUser.CompanyCity = tempFormUser.CompanyCity || '';
		tempFormUser.CompanyState = tempFormUser.CompanyState || '';
		tempFormUser.CompanyPostalCode = tempFormUser.CompanyPostalCode || '';
		tempFormUser.CompanyPhone = tempFormUser.CompanyPhone || '';

		const response = await CreateUserAccount(tempFormUser);

		if (response.IsSuccessful) {
			toast.success(response.Message);
			history.push(`/administration/edit/${response.Data}`);
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<div className="app-create-user">
				<div className="container">
					<ActionsBar>
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={() => history.push('/administration')}
						>
							<i className="fas fa-chevron-left" />
							<span>Back to Administration</span>
						</button>
					</ActionsBar>

					{formUser ? (
						<Fragment>
							<form onSubmit={handleSubmit} autoComplete="off">
								<h5 className="rw-container-title">
									<i className="fas fa-sliders-h" />
									<span>Contact Information</span>
								</h5>
								<div className="rw-container shadow-large settings">
									<div className="form-inputs">
										<div className="form-group">
											<label htmlFor="email">Email</label>
											<input
												type="email"
												id="email"
												name="Email"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={formUser.Email}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="password">
												Password
											</label>
											<input
												type="password"
												id="password"
												name="Password"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={formUser.Password}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="firstName">
												First name
											</label>
											<input
												type="text"
												id="firstName"
												name="FirstName"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={formUser.FirstName}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="lastName">
												Last name
											</label>
											<input
												type="text"
												id="lastName"
												name="LastName"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={formUser.LastName}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="phone">Phone</label>
											<div className="input-group">
												<select
													className="form-control form-control-lg"
													style={{ width: '30%' }}
													name="Country"
													disabled={isSaving}
													value={formUser.Country}
													onChange={handleChange}
												>
													{countriesList.map(
														(country) => (
															<option
																key={
																	country.name
																}
																value={
																	country.name
																}
															>
																{country.name} ({country.dial_code})
															</option>
														)
													)}
												</select>
												<input
													onChange={handleChange}
													id="phone"
													name="Phone"
													type="tel"
													disabled={isSaving}
													value={formUser.Phone}
													className="form-control form-control-lg"
													placeholder="Phone (format: 8005130456)"
													style={{ width: '70%' }}
												/>
											</div>
										</div>
									</div>
								</div>

								<h5 className="rw-container-title">
									<i className="far fa-building" />
									<span>Company Information</span>
								</h5>

								<div className="rw-container shadow-large company-information">
									<div className="form-inputs">
										<div className="form-group">
											<label htmlFor="companyName">
												Company name
											</label>
											<input
												type="text"
												id="companyName"
												name="CompanyName"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={formUser.CompanyName}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="companyAddress1">
												Company Address
											</label>
											<input
												type="text"
												id="companyAddress1"
												name="CompanyAddress1"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={formUser.CompanyAddress1}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="companyCity">
												Company City
											</label>
											<input
												type="text"
												id="companyCity"
												name="CompanyCity"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={formUser.CompanyCity}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="companyState">
												Company State
											</label>
											<input
												type="text"
												id="companyState"
												name="CompanyState"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={formUser.CompanyState}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="companyPostalCode">
												Company Postal Code
											</label>
											<input
												type="text"
												id="companyPostalCode"
												name="CompanyPostalCode"
												className="form-control form-control-lg"
												disabled={isSaving}
												value={
													formUser.CompanyPostalCode
												}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="companyPhone">
												Company Phone
											</label>
											<input
												onChange={handleChange}
												id="companyPhone"
												name="CompanyPhone"
												type="tel"
												disabled={isSaving}
												value={formUser.CompanyPhone}
												className="form-control form-control-lg"
												placeholder="Phone (format: 8005130456)"
											/>
										</div>
									</div>
								</div>

								<h5 className="rw-container-title">
									<i className="fas fa-stream" />
									<span>Account Status</span>
								</h5>

								<div className="rw-container shadow-large company-information">
									<div className="form-inputs">
										{brand.Id === 1 && (
											<div className="account-status mb-3">
												<div className="form-check form-check-inline">
													<input
														onChange={handleChange}
														id="accountStatusActive"
														name="Status"
														type="radio"
														disabled={isSaving}
														value="Active"
														className="form-check-input"
													/>
													<label
														htmlFor="accountStatusActive"
														className="form-check-label"
													>
														Active
													</label>
												</div>

												<div className="form-check form-check-inline">
													<input
														onChange={handleChange}
														id="accountStatusTrial"
														name="Status"
														type="radio"
														disabled={isSaving}
														value="Trial"
														className="form-check-input"
													/>
													<label
														htmlFor="accountStatusTrial"
														className="form-check-label"
													>
														Trial
													</label>
												</div>

												<div className="form-check form-check-inline">
													<input
														onChange={handleChange}
														id="accountStatusCanceled"
														name="Status"
														type="radio"
														disabled={isSaving}
														value="Canceled"
														className="form-check-input"
													/>
													<label
														htmlFor="accountStatusCanceled"
														className="form-check-label"
													>
														Canceled
													</label>
												</div>
											</div>
										)}

										{formUser.SendWelcomeEmail !==
											undefined && (
											<Fragment>
												<div className="welcome-email-toggle">
													<input
														id="chkSendWelcomeEmail"
														type="checkbox"
														className="rw-checkbox-switch"
														onChange={handleChange}
														defaultChecked={
															formUser.SendWelcomeEmail
														}
														disabled={isSaving}
													/>
													<label htmlFor="chkSendWelcomeEmail">
														Send Welcome Email
													</label>
												</div>
											</Fragment>
										)}
									</div>

									<div className="rw-container-buttons">
										<button
											type="submit"
											className="btn btn-primary"
											disabled={
												isSaving ||
												(brandLimit &&
													brandLimit.UsersLimit &&
													brandLimit.UsersLimit <=
														brandLimit.UsersCount)
											}
										>
											{!isSaving ? (
												<Fragment>
													<i class="fas fa-cloud-upload-alt" />
													<span>Create User</span>
												</Fragment>
											) : (
												<Fragment>
													<Spinner />
													<span>Saving...</span>
												</Fragment>
											)}
										</button>
									</div>
								</div>
							</form>
						</Fragment>
					) : (
						<div className="text-center p-3">
							<Spinner />
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default CreateUser;
