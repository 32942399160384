import { toast } from 'react-toastify';
import { CopyPreconfiguredWebsite, GetAllWebsitesData, GetUsersList } from '../../../../../Services';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import './PreconfigCopy.css';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { useEffect, useState } from 'react';

const PreconfigCopy = ({ targetAccountId, onClose }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [websites, setWebsites] = useState(null);
    const [users, setUsers] = useState(null);
    const [formData, setFormData] = useState({
        PreconfigId: '',
        TargetAccountId: targetAccountId,
    });

    useEffect(() => {
        getAllWebsitesData();
        getUsersList();
    }, []);

    useEffect(() => {
        if (websites && users) {
            setIsLoading(false);
        }
    }, [websites, users]);

    const getAllWebsitesData = async () => {
        setIsLoading(true);

        const response = await GetAllWebsitesData();

        if (response.IsSuccessful) {
            let tempWebsitesData = JSON.parse(response.Data);

            setWebsites(tempWebsitesData);
        } else {
            toast.error('Could not load the list of preconfigured websites');
        }
    }

    const getUsersList = async () => {
        setIsLoading(false);

        let searchData = {
            SearchTerm: '',
            ShowDeleted: false,
            PageNumber: 1,
            PageSize: 2000
        };

        const response = await GetUsersList(searchData);

        if (response.IsSuccessful) {
            let tempUsersData = JSON.parse(response.Data);

            setUsers(tempUsersData);
        } else {
            toast.error('Could not load the list of users');
        }
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        if (name !== 'PreconfigId' && e.target.type !== 'checkbox') {
            value = value.trim();
        }

        setFormData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(formData);

        setIsSaving(true);

        const response = await CopyPreconfiguredWebsite(formData);

        if (response.IsSuccessful) {
            if (onClose) {
                toast.success('Preconfig copied successfully');
                onClose();
            } else {
                toast.error(response.Message);
            }
        }

        setIsSaving(false);
    }

    return (
        <ModalDialog>
            <ModalDialogContent>
                <div className="form-inputs">
                    <div className="form-group">
                        <label htmlFor="PreconfigId">Preconfig Website:</label>
                        <select id='PreconfigId' className='form-control' disabled={isLoading || isSaving} value={formData.PreconfigId} onChange={handleChange} name='PreconfigId'>
                            <option value='' disabled={true}>Select a preconfig</option>
                            {websites && websites.map(website => (
                                <option key={website.Guid} value={website.Guid}>{website.Name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </ModalDialogContent>
            <ModalDialogButtons>
                <button type='button' className='btn btn-primary' disabled={isLoading || isSaving} onClick={handleSubmit}>
                    {isSaving ? 'Copying Preconfig...' : 'Copy Preconfig'}
                </button>
                <button type='button' className='btn btn-outline-primary' onClick={onClose}>Cancel</button>
            </ModalDialogButtons>
        </ModalDialog>
    )
}

export default PreconfigCopy;