import './GoogleCallback.css';
import queryString from 'query-string';
import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../../spinner/Spinner';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import { useLocation } from 'react-router-dom';
import { ConnectGoogle } from '../../../../Services';
import { toast } from 'react-toastify';

const GoogleCallback = () => {
	const location = useLocation();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isInvalid, setIsInvalid ] = useState(false);
	const [ token, setToken ] = useState();
	const [ expiresIn, setExpiresIn ] = useState(-1);

	useEffect(() => {
		let params = queryString.parse(location.search);

		if (!params || !params.code) {
			setIsInvalid(true);
			setIsLoading(false);
		} else {
			setToken(params.code);
			setExpiresIn(parseInt(params.expires_in ? params.expires_in : -1));
			setIsLoading(false);
		}
	}, []);

	useEffect(
		() => {
			if (!isLoading && token !== '') {
				handleGoogleConnection();
			}
		},
		[ isLoading, token ]
	);

	const handleGoogleConnection = async () => {
		let data = {
			AccessToken : token,
			ExpiresIn   : expiresIn
		};

		const response = await ConnectGoogle(data);

		if (response.IsSuccessful) {
			window.close();
		} else {
			toast.error(response.Message);
		}
	};

	const handleWindowClose = () => {
		window.close();
	};

	return (
		<Fragment>
			<CenteredContainer>
				{isLoading ? (
					<Fragment>
						<Spinner />
						<h3 className="mt-3">Verifying connection...</h3>
					</Fragment>
				) : (
					<Fragment>
						{isInvalid ? (
							<Fragment>
								<h3 className="mb-3">
									It looks like you didn't authorize our
									application to access your Google account.
								</h3>
								<button
									type="button"
									className="btn btn-primary"
									onClick={handleWindowClose}
								>
									<span>Close this Window</span>
								</button>
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
								<h3 className="mt-3">Connecting you up...</h3>
							</Fragment>
						)}
					</Fragment>
				)}
			</CenteredContainer>
		</Fragment>
	);
};

export default GoogleCallback;
