import { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import '@animxyz/core';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AppLogin from './components/appLogin/AppLogin';
import BrandLoader from './components/brandLoader/BrandLoader';
import CenteredContainer from './components/centeredContainer/CenteredContainer';
import SessionLoader from './components/sessionLoader/SessionLoader';
import Header from './header/Header';
import Spinner from './spinner/Spinner';
import { useStateValue } from './StateProvider';
import MainApp from './components/mainApp/MainApp';
import AssetsLoader from './components/assetsLoader/AssetsLoader';
import AppForgotPassword from './components/appForgotPassword/AppForgotPassword';
import AppLogout from './components/appLogout/AppLogout';
import CalendlyOAuth from './components/oauth/calendly/CalendlyOAuth';
import OneTimeUseToken from './components/oneTimeUserToken/OneTimeUseToken';
import GoogleOAuth from './components/oauth/google/GoogleOAuth';

function App() {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ sessionLoaded, setSessionLoaded ] = useState(false);
	const [ assetsPreloaded, setAssetsPreloaded ] = useState(false);
	const [ preloadedAssets, setPreloadedAssets ] = useState([]);
	const [ toastifyPosition, setToastifyPosition ] = useState('bottom-left');
	const [ { brand }, dispatch ] = useStateValue();

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(
		() => {
			if (brand && sessionLoaded && assetsPreloaded) {
				// TODO: load brand logo, stylesheet and initialize app
				setIsLoading(false);
			}
		},
		[ brand, sessionLoaded, assetsPreloaded ]
	);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW <= 767) {
			setToastifyPosition('top-center');
		} else {
			setToastifyPosition('bottom-left');
		}
	};

	return (
		<div className="App">
			<Router basename="/c">
				<BrandLoader />
				{brand && (
					<Fragment>
						<SessionLoader setSessionLoaded={setSessionLoaded} />
						<AssetsLoader
							setPreloadedAssets={setPreloadedAssets}
							setAssetsPreloaded={setAssetsPreloaded}
						/>
					</Fragment>
				)}

				{isLoading ? (
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				) : (
					<Fragment>
						<Switch>
							<Route path="/logout">
								<AppLogout />
							</Route>
							<Route path="/login">
								<AppLogin />
							</Route>
							<Route path="/forgot-password">
								<AppForgotPassword />
							</Route>
							<Route path="/oauth/calendly">
								<CalendlyOAuth />
							</Route>
							<Route path="/oauth/google">
								<GoogleOAuth />
							</Route>
							<Route path="/otut/:token">
								<OneTimeUseToken />
							</Route>
							<Route path="/">
								<MainApp />
							</Route>
						</Switch>
					</Fragment>
				)}

				{/* <Route path="/:offerGuid">
					<Header />
					<Wizard />
					<ToastContainer
						position="bottom-left"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
					/>
				</Route> */}
				<ToastContainer
					position={toastifyPosition}
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
				/>
			</Router>
		</div>
	);
}

export default App;
