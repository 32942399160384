import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetDomainDetails, UpdateDomainAutoRenew } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import NoDataMessage from '../../../noDataMessage/NoDataMessage';
import DeleteDomain from './deleteDomain/DeleteDomain';
import './DomainDetails.css';
import DomainForwarding from './domainForwarding/DomainForwarding';
import DomainRecords from './domainRecords/DomainRecords';
import SubdomainsList from './subdomainsList/SubdomainsList';

const DomainDetails = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ showDomainDelete, setShowDomainDelete ] = useState(false);
	const [ { brand }, dispatch ] = useStateValue();
	const [ domainDetails, setDomainDetails ] = useState(null);
	const match = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		getDomainDetails();
	}, []);

	const getDomainDetails = async () => {
		let domainGuid = match.params.domainGuid;

		let data = {
			Guid : domainGuid
		};

		const response = await GetDomainDetails(data);

		if (response.IsSuccessful) {
			setDomainDetails(JSON.parse(response.Data));
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const handleAutoRenewChange = async (e) => {
		const isRenewAuto = e.target.checked;

		setIsSaving(true);

		let data = {
			Guid        : domainDetails.Guid,
			IsAutoRenew : isRenewAuto
		};

		const response = await UpdateDomainAutoRenew(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleOpenChat = () => {
		window.LC_API.open_chat_window();
	};

	const toggleDeleteDomainDialog = () => {
		setShowDomainDelete((prev) => !prev);
	};

	const handleDomainDeleteCallback = () => {
		history.push('/domains');
	};

	return (
		<div className="app-domain-details panel">
			{!isLoading ? (
				<Fragment>
					{domainDetails ? (
						<Fragment>
							<div className="container">
								<h5 className="rw-container-title domain-name">
									<i className="fas fa-sliders-h" />
									<span>
										{domainDetails.DomainName} DNS Settings
									</span>
								</h5>
								<div className="domain-settings-container">
									{domainDetails.Status !== 'Added-On' &&
										(domainDetails.Status !== 'In Cart' && (
											<Fragment>
												<div className="expires">
													<span className="text-muted">
														Expires{' '}
														{domainDetails.DateExpires}
													</span>
												</div>
												<div className="auto-renew">
													<input
														id={`chk${domainDetails.Guid}`}
														type="checkbox"
														className="rw-checkbox-switch"
														disabled={
															isLoading ||
															isSaving
														}
														defaultChecked={
															domainDetails.IsAutoRenew
														}
														onChange={
															handleAutoRenewChange
														}
													/>
													<label
														htmlFor={`chk${domainDetails.Guid}`}
													>
														Auto Renew
													</label>
												</div>
											</Fragment>
										))}

									{domainDetails.Status === 'In Cart' && (
										<Link
											to="/cart"
											className="btn btn-primary"
										>
											<i className="fas fa-shopping-bag" />
											<span>Checkout</span>
										</Link>
									)}
								</div>

								{domainDetails.PointedStatus ===
									'Not-Pointed' && (
									<div className="domain-not-pointed-message">
										<div className="alert alert-warning m0">
											{`This domain doesn't seem to be pointed to our servers. The nameservers of the domain have to be set to ${brand.NS1} and ${brand.NS2}. `}
											<a
												onClick={handleOpenChat}
												className="app-link"
											>
												Click here
											</a>{' '}
											if you need assistance with this
											step.
										</div>
									</div>
								)}

								<h5 className="rw-container-title">
									<i className="fas fa-bars" />
									<span>Host Records</span>
								</h5>

								<DomainRecords domain={domainDetails} />

								<h5 className="rw-container-title">
									<i className="fas fa-route" />
									<span>Domain Forwarding</span>
								</h5>

								<DomainForwarding domain={domainDetails} />

								<h5 className="rw-container-title">
									<i className="fas fa-project-diagram" />
									<span>Subdomains List</span>
								</h5>

								<SubdomainsList domain={domainDetails} />

								{domainDetails.Status === 'Added-On' && (
									<Fragment>
										<div className="delete-domain-container">
											<button
												type="button"
												className="btn btn-danger"
												onClick={
													toggleDeleteDomainDialog
												}
											>
												<span>Delete Domain</span>
											</button>
										</div>
									</Fragment>
								)}

								{showDomainDelete && (
									<Fragment>
										<DeleteDomain
											domain={domainDetails}
											closeDialog={
												toggleDeleteDomainDialog
											}
											callback={
												handleDomainDeleteCallback
											}
										/>
									</Fragment>
								)}
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className="container">
								<NoDataMessage />
							</div>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Fragment>
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				</Fragment>
			)}
		</div>
	);
};

export default DomainDetails;
