import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { XyzTransitionGroup } from '@animxyz/react';
import { FindDomains } from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import DomainSearchResult from './domainSearchResult/DomainSearchResult';
import { useStateValue } from '../../../../../StateProvider';
import './DomainSearchList.css';
import { animationDefaults } from '../../../../../AnimConfig';

const DomainSearchList = ({ searchTerm, setLoading }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();
	const [ searchResults, setSearchResults ] = useState(null);

	useEffect(
		async () => {
			if (searchTerm) {
				setIsLoading(true);
				setLoading(true);

				let searchData = {};
				searchData.SearchTerm = searchTerm;

				const response = await FindDomains(searchData);

				if (Array.isArray(response)) {
					console.log(response);
					setSearchResults(response);
				} else {
					// TODO: no results found
					setSearchResults(null);
				}

				setIsLoading(false);
				setLoading(false);
			} else {
				setSearchResults(null);
				setIsLoading(false);
				setLoading(false);
			}
		},
		[ searchTerm ]
	);

	return (
		<div className="rw-domain-search-results">
			{!isLoading ? (
				<Fragment>
					{searchResults &&
					searchResults.filter((x) => x.IsAvailable).length > 0 ? (
						<Fragment>
							<h5 className="rw-container-title">
								{user.FirstName}, here's what we found for
								you...
							</h5>
							{/* <div className="rw-search-list-container"> */}
							<XyzTransitionGroup
								className="rw-search-list-container item-group"
								xyz={animationDefaults.xyzBottomStagger}
							>
								{searchResults.map((domain) => {
									if (domain.IsAvailable) {
										return (
											<DomainSearchResult
												key={domain.DomainName}
												domain={domain}
											/>
										);
									}
								})}
							</XyzTransitionGroup>
							{/* </div> */}
						</Fragment>
					) : (
						<Fragment>
							{searchTerm.trim() !== '' ? (
								<Fragment>
									<span>No results found</span>
								</Fragment>
							) : (
								<Fragment />
							)}
						</Fragment>
					)}
				</Fragment>
			) : (
				<div className="text-center p-3">
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default DomainSearchList;
