import React from 'react';
import CrmNavigation from './crmNavigation/CrmNavigation';
import CrmSpamFiltersList from './crmSpamFiltersList/CrmSpamFiltersList';

const CrmSpamFilters = () => {
	return (
		<div className="app-crm spam-filters panel">
			<CrmNavigation />

			<CrmSpamFiltersList />
		</div>
	);
};

export default CrmSpamFilters;
