import { Fragment, useEffect, useState } from 'react';
import LottiePlayer from '../lottiePlayer/LottiePlayer';
import './BulkActionsContextProgressDialog.css';
import spinnerAnimation from '../lottiePlayer/animations/spinner.json';
import checkAnimation from '../lottiePlayer/animations/check.json';

const BulkActionsContextProgressDialog = ({
	records,
	message,
	action,
	onComplete,
	additionalPayload
}) => {
	const [ isWorking, setIsWorking ] = useState(true);
	const [ currentRecord, setCurrentRecord ] = useState(0);
	const [ startedProcessing, setStartedProcessing ] = useState(false);
	const [ isComplete, setIsComplete ] = useState(false);
	const [ result, setResult ] = useState({});

	useEffect(
		() => {
			if (records && records.length > 0 && action) {
				setIsWorking(true);
			}
		},
		[ records, action ]
	);

	useEffect(
		async () => {
			if (!startedProcessing) {
				if (isWorking) {
					await processRecords();
				}
			} else {
				if (!isWorking) {
					// process completed, call the onComplete function with the result object
					setIsComplete(true);
				}
			}
		},
		[ isWorking, startedProcessing, result ]
	);

	useEffect(
		() => {
			if (isComplete) {
				setTimeout(() => {
					onComplete(result);
				}, 2000);
			}
		},
		[ isComplete ]
	);

	const processRecords = async () => {
		setStartedProcessing(true);

		let result = {
			success : true,
			items   : []
		};

		for (let i = 0; i < records.length; i++) {
			setCurrentRecord(i + 1);
			let record = records[i];
			let data = {
				...record,
				...additionalPayload
			};

			try {
				const response = await action(data);

				if (!response.IsSuccessful) {
					result.success = false;
				}

				data.success = response.IsSuccessful;
			} catch (err) {
				result.success = false;
				data.success = false;
			}
			result.items.push(data);
		}

		setResult(result);
		setIsWorking(false);
	};

	const sleep = async (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	return (
		<Fragment>
			<div className="app-bulk-progress-dialog">
				<div className="app-bulk-progress-dialog-content">
					<Fragment>
						<div className="app-progress-counter">
							{isWorking && (
								<Fragment>
									<LottiePlayer
										animation={spinnerAnimation}
										loop
										width={70}
										height={70}
									/>
									<div className="app-current-progress">
										{`${currentRecord} / ${records.length}`}
									</div>
								</Fragment>
							)}

							{isComplete && (
								<LottiePlayer
									animation={checkAnimation}
									width={70}
									height={70}
								/>
							)}
						</div>
					</Fragment>

					<div className="app-progress-description">
						{isWorking && <Fragment>{message}</Fragment>}

						{isComplete && <Fragment>Completed.</Fragment>}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default BulkActionsContextProgressDialog;
