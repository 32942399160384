import {
	useHistory,
	useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import './BrandCustomTab.css';
import { useStateValue } from '../../../StateProvider';
import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../spinner/Spinner';
import TabContent from './tabContent/TabContent';
import CenteredContainer from '../../centeredContainer/CenteredContainer';

const BrandCustomTab = () => {
	const [ { brand }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const { tabGuid } = useParams();
	const [ tab, setTab ] = useState();
	const history = useHistory();

	useEffect(
		() => {
			if (tabGuid) {
				if (brand.Tabs && brand.Tabs.length > 0) {
					const targetTab = brand.Tabs.find(
						(tab) => tab.Guid === tabGuid
					);

					if (targetTab) {
						setTab(targetTab);
						setIsLoading(false);
					}
				} else {
					history.push('/');
				}
			} else {
				history.push('/');
			}
		},
		[ tabGuid ]
	);

	return (
		<div className="app-brand-content panel">
			<Fragment>
				{!isLoading && tab ? (
					<Fragment>
						<div className="container">
							<h5 className="rw-container-title domain-name">
								{tab.Icon &&
								tab.Icon !== '' && <i className={tab.Icon} />}

								{tab.Name &&
								tab.Name !== '' && <span>{tab.Name}</span>}
							</h5>
						</div>

						<TabContent tab={tab} />
					</Fragment>
				) : (
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				)}
			</Fragment>
		</div>
	);
};

export default BrandCustomTab;
