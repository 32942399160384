import { Fragment, useEffect, useRef, useState } from 'react';
import './AccountIntegrations.css';
import mailchimpLogo from '../../../../img/mailchimp-logo.jpg';
import zapierLogo from '../../../../img/zapier-logo.svg';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import { SaveIntegrations } from '../../../../Services';
import { toast } from 'react-toastify';

const AccountIntegrations = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ mailchimpApiKey, setMailchimpApiKey ] = useState('');
	const [ { user, brand }, dispatch ] = useStateValue();
	const zapierInputRef = useRef();

	useEffect(
		() => {
			if (user) {
				setMailchimpApiKey(user.MailChimpApiKey);
				setFormData({
					MailChimpApiKey : user.MailChimpAPIKey,
					ZapierApiKey    : user.ZapierApiKey
				});

				setIsLoading(false);
			}
		},
		[ user ]
	);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setMailchimpApiKey(value);
		setFormData({
			...formData,
			[name] : value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let tempData = { ...formData };
		// tempData.MailChimpAPIKey = formData.MailChimpAPIKey;

		setIsSaving(true);

		const response = await SaveIntegrations(tempData);

		if (response.IsSuccessful) {
			toast.success(response.Message);
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleZapierCopy = () => {
		const copyText = zapierInputRef.current;

		// Select the text field
		copyText.select();
		copyText.setSelectionRange(0, 99999); // For mobile devices

		// Copy the text inside the text field
		navigator.clipboard.writeText(copyText.value);
		toast.success('Zapier key copied successfully!');
	};

	return (
		<div className="rw-container shadow-large integrations">
			{!isLoading ? (
				<form onSubmit={handleSubmit}>
					<div className="form-inputs">
						<div className="form-group">
							<div className="input-group">
								<img src={mailchimpLogo} alt="Mailchimp Logo" />
								<input
									type="text"
									id="mailchimpApiKey"
									name="MailChimpAPIKey"
									placeholder="Your MailChimp API Key"
									className="form-control form-control-lg"
									disabled={isLoading || isSaving}
									value={mailchimpApiKey}
									onChange={handleChange}
								/>
							</div>
						</div>

						{brand &&
						brand.Id === 1 && (
							<Fragment>
								<div className="form-group">
									<div className="input-group">
										<img
											src={zapierLogo}
											alt="Zapier Logo"
										/>
										<div className="input-clipboard-container flex-fill">
											<input
												ref={zapierInputRef}
												type="text"
												id="zapierApiKey"
												name="ZapierApiKey"
												placeholder="Your RW Zapier API Key"
												className="form-control form-control-lg"
												disabled
												value={formData.ZapierApiKey}
											/>
											<a
												className="clipboard-copy"
												onClick={handleZapierCopy}
											>
												<i className="fa-regular fa-copy" />
											</a>
										</div>
										<div className="input-group-append pl-3 zapier-documentation-link">
											<a
												href="https://www.realwebsite.com/zapier"
												target="_blank"
												className="app-link"
											>
												Documentation
											</a>
										</div>
									</div>
								</div>
							</Fragment>
						)}
					</div>
					<div className="rw-container-buttons">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={isLoading || isSaving}
						>
							{!isSaving ? (
								<Fragment>
									<i class="fas fa-cloud-upload-alt" />
									<span>Save Changes</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Saving...</span>
								</Fragment>
							)}
						</button>
					</div>
				</form>
			) : (
				<div className="text-center p-3">
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default AccountIntegrations;
