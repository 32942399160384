import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { GetWebsites } from '../../../../Services';
import { XyzTransitionGroup } from '@animxyz/react';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import Spinner from '../../../../spinner/Spinner';
// import Website from './website/Website';
import './WebsitesList.css';
import Search from '../../../search/Search';
import { animationDefaults } from '../../../../AnimConfig';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import SaveAsPreconfiguredWebsite from '../saveAsPreconfiguredWebsite/SaveAsPreconfiguredWebsite';
import DeleteWebsite from '../deleteWebsite/DeleteWebsite';
import CloneWebsiteDialog from '../cloneWebsiteDialog/CloneWebsiteDialog';
import { Link } from 'react-router-dom';
import NeedHelpSetup from '../../../needHelpSetup/NeedHelpSetup';
import { useStateValue } from '../../../../StateProvider';
import LottiePlayer from '../../../lottiePlayer/LottiePlayer';
import createWebsiteAnimation from '../../../lottiePlayer/animations/create-website.json';
import createWebsiteRocketAnimation from '../../../lottiePlayer/animations/create-website-rocket.json';
import WebsiteV2 from './website/WebsiteV2';
import { ShepherdTourContext } from 'react-shepherd';

const WebsitesList = ({ toggleTrialDialog }) => {
	const [
		{ user, makeItLiveSettings, guidedTourSteps },
		dispatch
	] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ websites, setWebsites ] = useState([]);
	const [ fullWebsitesList, setFullWebsitesList ] = useState([]);
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ selectedWebsite, setSelectedWebsite ] = useState(null);
	const [ showSaveAsPreconfig, setShowSaveAsPreconfig ] = useState(false);
	const [ showCloneWebsite, setShowCloneWebsite ] = useState(false);
	const [ showDeleteWebsite, setShowDeleteWebsite ] = useState(false);
	const [ shouldReloadWebsitesList, setShouldReloadWebsitesList ] = useState(
		false
	);
	const tour = useContext(ShepherdTourContext);

	useEffect(() => {
		loadUserWebsites();
	}, []);

	useEffect(
		() => {
			if (makeItLiveSettings) {
				setShouldReloadWebsitesList(true);
			} else {
				if (shouldReloadWebsitesList) {
					setShouldReloadWebsitesList(false);
					loadUserWebsites();
				}
			}
		},
		[ makeItLiveSettings ]
	);

	const loadUserWebsites = async () => {
		const response = await GetWebsites();

		if (response.IsSuccessful) {
			const tempWebsites = JSON.parse(response.Data);
			setFullWebsitesList(tempWebsites);

			setWebsites(tempWebsites);
		} else {
			// TODO: show no websites message
			if (tour && guidedTourSteps && guidedTourSteps.length > 0) {
				tour.removeStep('viewEditStep');
				tour.removeStep('applyDomainStep');
			}
		}

		setIsLoading(false);
	};

	const handleGridRef = (e) => {
		if (e) {
			// console.log(e, e.style, e.style.gridTemplateColumns);
			if (websites) {
				if (websites.length >= 3) {
					e.style.gridTemplateColumns = '1fr 1fr 1fr';
				} else {
					e.style.gridTemplateColumns = '1fr 1fr';
				}
			} else {
				e.style.gridTemplateColumns = '1fr 1fr';
			}
		}
	};

	const handleSearch = (e) => {
		let term = e.target.value;

		setSearchTerm(term ? term.toLowerCase() : '');

		if (term.trim().length >= 3) {
			let filteredList = fullWebsitesList.filter(
				(x) =>
					x.WebsiteName.toLowerCase().indexOf(term) > -1 ||
					(x.DomainName !== null &&
						x.DomainName.toLowerCase().indexOf(term) > -1) ||
					x.Url.toLowerCase().indexOf(term) > -1
			);

			if (!filteredList) {
				filteredList = [];
			}

			if (!Array.isArray(filteredList)) {
				let tempArray = new Array();
				tempArray.push(filteredList);

				filteredList = tempArray;
			}

			setWebsites(filteredList);
		} else {
			setWebsites(fullWebsitesList);
		}
	};

	const handleSaveAsPreconfigured = (website) => {
		if (website) {
			setSelectedWebsite(website);
			setShowSaveAsPreconfig(true);
		}
	};

	const closeSaveAsPreconfiguredDialog = () => {
		setShowSaveAsPreconfig(false);
		setSelectedWebsite(null);
	};

	const handleCloneWebsite = (website) => {
		if (website) {
			setSelectedWebsite(website);
			setShowCloneWebsite(true);
		}
	};

	const closeCloneWebsiteDialog = () => {
		setShowCloneWebsite(false);
		setSelectedWebsite(null);
	};

	const handleDeleteWebsite = (website) => {
		if (website) {
			setSelectedWebsite(website);
			setShowDeleteWebsite(true);
		}
	};

	const closeDeleteWebsiteDialog = () => {
		setShowDeleteWebsite(false);
		setSelectedWebsite(null);
	};

	const handleTrialClick = () => {
		if (toggleTrialDialog) {
			toggleTrialDialog();
		}
	};

	return (
		<div className="app-websites-list panel">
			{!isLoading ? (
				<Fragment>
					{websites && websites.length > 0 ? (
						<Fragment>
							<div className="actions-bar-container">
								<div className="panel-actions">
									{user &&
									user.Status === 'Active' && (
										<Fragment>
											<Link
												to="/create-website"
												className="btn btn-primary btn-create-website"
											>
												<i className="fas fa-database" />
												<span>Create New Website</span>
											</Link>
										</Fragment>
									)}

									{user &&
									user.Status === 'Trial' && (
										<Fragment>
											<a
												onClick={handleTrialClick}
												className="btn btn-primary btn-create-website btn-trial"
											>
												<i className="fas fa-database" />
												<span>Create New Website</span>
											</a>
										</Fragment>
									)}
								</div>

								{fullWebsitesList &&
								fullWebsitesList.length > 3 && (
									<div className="search-bar">
										<Search
											searchTerm={searchTerm}
											handleChange={handleSearch}
											placeholder="Search websites"
										/>
									</div>
								)}
							</div>

							{websites &&
							websites.length > 0 && (
								<Fragment>
									<div
										ref={handleGridRef}
										className="websites-grid item-group"
										appear
										appear-visible
										xyz={animationDefaults.xyzBottomStagger}
									>
										{websites.map((website, index) => (
											<WebsiteV2
												key={website.Guid}
												index={index}
												website={website}
												screenshotUrl={
													website.ScreenshotUrl
												}
												saveAsPreconfigured={
													handleSaveAsPreconfigured
												}
												cloneWebsite={
													handleCloneWebsite
												}
												deleteWebsite={
													handleDeleteWebsite
												}
												toggleTrialDialog={
													handleTrialClick
												}
												refreshWebsites={
													loadUserWebsites
												}
											/>
										))}

										{/* <NeedHelpSetup /> */}
									</div>
								</Fragment>
							)}

							{showSaveAsPreconfig &&
							selectedWebsite && (
								<SaveAsPreconfiguredWebsite
									website={selectedWebsite}
									closeDialog={closeSaveAsPreconfiguredDialog}
								/>
							)}

							{showDeleteWebsite &&
							selectedWebsite && (
								<DeleteWebsite
									website={selectedWebsite}
									closeDialog={closeDeleteWebsiteDialog}
									callback={loadUserWebsites}
								/>
							)}

							{showCloneWebsite &&
							selectedWebsite && (
								<CloneWebsiteDialog
									website={selectedWebsite}
									closeDialog={closeCloneWebsiteDialog}
									callback={loadUserWebsites}
								/>
							)}
						</Fragment>
					) : (
						<Fragment>
							<CenteredContainer>
								<div className="container text-center">
									{/* <LottiePlayer width={300} height={300} animation={createWebsiteAnimation} loop /> */}
									<LottiePlayer
										width={300}
										height={300}
										animation={createWebsiteRocketAnimation}
										loop
									/>

									<h5 className="text-center mb-3">
										Looks like you don't have any websites
										at the moment
									</h5>
									{user &&
									user.Status === 'Active' && (
										<Fragment>
											<Link
												to="/create-website"
												className="btn btn-primary btn-create-website"
											>
												{/* <i className="fas fa-database" /> */}
												<span>
													Launch Website Wizard
												</span>
											</Link>
										</Fragment>
									)}

									{user &&
									user.Status === 'Trial' && (
										<Fragment>
											<a
												onClick={handleTrialClick}
												className="btn btn-primary btn-create-website btn-trial"
											>
												{/* <i className="fas fa-database" /> */}
												<span>
													Launch Website Wizard
												</span>
											</a>
										</Fragment>
									)}
								</div>
							</CenteredContainer>
						</Fragment>
					)}
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default WebsitesList;
