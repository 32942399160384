import CrmList from './crmList/CrmList';
import './Crm.css';
import { Fragment, useEffect, useState } from 'react';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import Spinner from '../../../spinner/Spinner';
import { GetLeads } from '../../../Services';
import LottiePlayer from '../../lottiePlayer/LottiePlayer';
import leadsAnimation from '../../lottiePlayer/animations/leads.json';
import ActionsBar from '../../actionsBar/ActionsBar';
import { Link } from 'react-router-dom';
import CrmNavigation from './crmNavigation/CrmNavigation';

const CrmArchive = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ dashboardData, setDashboardData ] = useState(null);
	const [ leads, setLeads ] = useState([]);
	const [ fullLeadsList, setFullLeadsList ] = useState([]);

	useEffect(() => {
		getLeads();
	}, []);

	const getLeads = async () => {
		const response = await GetLeads();

		if (response.IsSuccessful) {
			const tempDashboardData = JSON.parse(response.Data);

			setDashboardData(tempDashboardData);
			setFullLeadsList(
				tempDashboardData.Contacts.filter(
					(x) => !x.IsSpam && !x.IsDeleted && x.Status === 'Archived'
				)
			);

			setLeads(
				tempDashboardData.Contacts.filter(
					(x) => !x.IsSpam && !x.IsDeleted && x.Status === 'Archived'
				)
			);
		}

		setIsLoading(false);
	};

	return (
		<div className="app-crm panel">
			{!isLoading ? (
				<Fragment>
					<CrmNavigation />

					{leads && leads.length > 0 ? (
						<Fragment>
							<div className="container text-left">
								<h5 className="rw-container-title">
									<i className="fa-solid fa-box-archive" />
									<span>Archive</span>
								</h5>

								<CrmList
									leads={leads}
									onUpdate={getLeads}
									listType="archive"
								/>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<CenteredContainer>
								<div className="container text-center">
									<LottiePlayer
										animation={leadsAnimation}
										width={300}
										height={300}
										loop={true}
									/>
									<h5 className="mt-1">
										No Archived leads found.
									</h5>
									<Link
										to="/crm"
										className="btn btn-outline-primary mt-3"
									>
										<span>Back to Leads</span>
									</Link>
								</div>
							</CenteredContainer>
						</Fragment>
					)}
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default CrmArchive;
