import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
	CreateUserSpamFilter,
	UpdateUserSpamFilter
} from '../../../../../Services';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import './EditSpamFilterModal.css';

const EditSpamFilterModal = ({ filter, onUpdate, onCancel }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({
		filterType : 'all',
		FieldName  : '*',
		IsActive   : true
	});

	useEffect(
		() => {
			if (filter) {
				let tempFilter = { ...filter };

				if (tempFilter.FieldName === '*') {
					tempFilter.filterType = 'all';
				} else {
					tempFilter.filterType = 'field';
				}

				setFormData(tempFilter);
			}
		},
		[ filter ]
	);

	useEffect(
		() => {
			console.log('form data', formData);
		},
		[ formData ]
	);

	const handleChange = (e) => {
		const target = e.target.name;
		const value =
			e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempFormData = { ...formData };
		tempFormData.FieldFilter = tempFormData.FieldFilter.trim();

		let data = {};

		if (filter) {
			data = {
				...filter,
				...tempFormData
			};
		} else {
			data = {
				...tempFormData
			};
		}

		let response;

		if (filter) {
			response = await UpdateUserSpamFilter(data);
		} else {
			response = await CreateUserSpamFilter(data);
		}

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onUpdate) {
				onUpdate();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<ModalDialog>
			<form onSubmit={handleSubmit}>
				<ModalDialogContent>
					{filter ? (
						<Fragment>
							<h5>Edit Filter</h5>
						</Fragment>
					) : (
						<Fragment>
							<h5>Add New Filter</h5>
						</Fragment>
					)}

					<p>
						Choose whether you want to look for a value in all
						contact fields or just a specific one.
					</p>
					{/* <div class="form-check form-check-inline">
						<input
							class="form-check-input"
							type="radio"
							name="filterType"
							id="filterAll"
							value="all"
							checked={formData.filterType === 'all'}
							onChange={handleChange}
							disabled={isSaving}
						/>
						<label class="form-check-label" htmlFor="filterAll">
							All Input Fields
						</label>
					</div>
					<div class="form-check form-check-inline mb-3">
						<input
							class="form-check-input"
							type="radio"
							name="filterType"
							id="filterField"
							value="field"
							checked={formData.filterType === 'field'}
							onChange={handleChange}
							disabled={isSaving}
						/>
						<label class="form-check-label" htmlFor="filterField">
							Specific Input Field
						</label>
					</div> */}

					<label for="fieldNameSelect" class="form-label">
						Field:
					</label>
					<select
						id="fieldNameSelect"
						name="FieldName"
						className="form-control form-control-lg mb-3"
						value={formData.FieldName}
						onChange={handleChange}
					>
						<option value="*">All Input Fields</option>
						<option value="Email">Email Address</option>
						<option value="Phone">Phone</option>
						<option value="FirstName">First Name</option>
						<option value="LastName">Last Name</option>
						<option value="Website">Website</option>
						<option value="CompanyName">Company Name</option>
						<option value="Description">Description</option>
						<option value="Notes">Notes</option>
					</select>

					<label for="fieldFilterText" class="form-label">
						Field Filter:
					</label>
					<input
						id="fieldFilterText"
						type="text"
						name="FieldFilter"
						className="form-control form-control-lg"
						placeholder="Enter a filter term"
						value={formData.FieldFilter}
						onChange={handleChange}
						disabled={isSaving}
					/>
				</ModalDialogContent>
				<ModalDialogButtons shaded>
					<button
						type="submit"
						className="btn btn-primary"
						disabled={isSaving}
					>
						{!isSaving ? <span>Save</span> : <span>Saving...</span>}
					</button>
					<button
						type="button"
						className="btn btn-outline-primary"
						onClick={handleCancel}
						disabled={isSaving}
					>
						<span>Cancel</span>
					</button>
				</ModalDialogButtons>
			</form>
		</ModalDialog>
	);
};

export default EditSpamFilterModal;
