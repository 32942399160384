import { NavLink } from 'react-router-dom';
import './BrandSendMessageTabs.css';

const BrandSendMessageTabs = ({ type }) => {
	return (
		<ul className="nav nav-tabs app-tabs tabs-crm">
			<li className="nav-item">
				<NavLink to={`/administration/${type}/`} exact={true} className="nav-link" activeClassName="active">
					<span>Send</span>
				</NavLink>
			</li>
			<li className="nav-item">
				<NavLink
					to={`/administration/${type}/history/`}
					exact={true}
					className="nav-link"
					activeClassName="active"
				>
					<span>History</span>
				</NavLink>
			</li>
		</ul>
	);
};

export default BrandSendMessageTabs;
