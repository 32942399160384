import LottiePlayer from '../../../../lottiePlayer/LottiePlayer';
import './UserNotes.css';
import emptyNotesAnimation from '../../../../lottiePlayer/animations/userNotesEmpty.json';
import { Fragment, useState } from 'react';
import CreateUserNote from './createUserNote/CreateUserNote';
import UserNote from './userNote/UserNote';

const UserNotes = ({ notes, onUpdate, onDelete }) => {
	const [ showAddNote, setShowAddNote ] = useState(false);

	const toggleAddNote = () => {
		setShowAddNote((prev) => !prev);
	};

	const handleAddNoteClick = () => {
		setShowAddNote(true);
	};

	const onNoteCreate = (note) => {
		if (onUpdate) {
			onUpdate(note);
			setShowAddNote(false);
		}
	};

	return (
		<div className="user-notes-container">
			{notes && notes.length > 0 ? (
				<Fragment>
					{showAddNote ? (
						<Fragment>
							<CreateUserNote
								onCreate={onNoteCreate}
								onCancel={toggleAddNote}
							/>
						</Fragment>
					) : (
						<Fragment>
							<button
								type="button"
								className="btn btn-primary"
								onClick={toggleAddNote}
							>
								<span>Add a Note</span>
							</button>
						</Fragment>
					)}

					<div className="user-notes mt-3">
						{notes.map((note) => (
							<UserNote
								key={note.Guid}
								note={note}
								onUpdate={onUpdate}
								onDelete={onDelete}
							/>
						))}
					</div>
				</Fragment>
			) : (
				<Fragment>
					{!showAddNote ? (
						<Fragment>
							<div className="user-notes-empty text-center">
								<h5>Looks a bit empty in here</h5>
								<LottiePlayer
									animation={emptyNotesAnimation}
									loop={true}
								/>

								<button
									className="btn btn-primary"
									onClick={toggleAddNote}
								>
									<span>Add a First Note</span>
								</button>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<CreateUserNote
								onCreate={onNoteCreate}
								onCancel={toggleAddNote}
							/>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default UserNotes;
