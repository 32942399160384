import axios from 'axios';

const apiHost = window.location.origin + '/Services/';
// const apiHost = 'https://app.realwebsite.com/Services/';

export const GetBrand = async () => {
	let data = {};
	data.Url = window.location.origin;

	const response = await axios
		.post(apiHost + 'BrandsService.asmx/GetBrand', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	// console.log('response-data:', response, response.data.d);
	return response.data.d;
};

export const GetBrandDomainBalance = async () => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/GetBrandDomainBalance', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	// console.log('response-data:', response, response.data.d);
	return response.data.d;
};

export const GetBrandUsersLimit = async () => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/GetBrandUsersLimit', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	// console.log('response-data:', response, response.data.d);
	return response.data.d;
};

export const GetBrandWebhooks = async () => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/GetBrandWebhooks', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddNewBrandWebhook = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/AddBrandWebhook', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateBrandWebhook = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/UpdateBrandWebhook', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteBrandWebhook = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/DeleteBrandWebhook', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserWebhooks = async () => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/GetUserWebhooks', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddNewUserWebhook = async (data) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/AddUserWebhook', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateUserWebhook = async (data) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/UpdateUserWebhook', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteUserWebhook = async (data) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/RemoveUserWebhook', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserDetails = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserDetails', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const ValidateToken = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/ValidateOTUTToken', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetAccountInvoices = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserInvoices', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetBillingHistory = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetBillingHistoryNew', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SaveUserDetails = async (details) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SaveUserSettings', details, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SaveCompanyDetails = async (details) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SaveCompanyDetails', details, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const CancelSubscription = async (details) => {
	const response = await axios
		.post(apiHost + 'SignupService.asmx/CancelSubscription', details, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const CancelUserSubscription = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/CancelSubscription', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SaveIntegrations = async (details) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SaveIntegrations', details, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const ConnectGoogle = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/ConnectGoogle', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const ConnectCalendly = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/ConnectCalendly', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetCalendlyEvents = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetCalendlyEvents', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const ValidateUser = async (email, password, brandId) => {
	let data = {};
	data.Email = email;
	data.Password = password;
	data.BrandId = brandId;

	const response = await axios
		.post(apiHost + 'UsersService.asmx/ValidateUser', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SendForgotPasswordEmail = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SendForgotPasswordEmail', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const Logout = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/Logout', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SendFeedback = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SendFeedback', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const LegacyLogin = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/LegacyLogin', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetWebsites = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserWebsites', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetWebsiteWizardData = async (brandData) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetWebsiteWizardData', brandData, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetAllWebsitesData = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetAllPreconfiguredWebsites', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetWebsitePreconfigIndustries = async (data) => {
	const response = await axios
		.post(
			apiHost + 'UsersService.asmx/GetWebsitePreconfigIndustries',
			data,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetPreconfiguredWebsite = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetPreconfiguredWebsite', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SaveWebsiteAsPreconfigured = async (website) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SaveWebsiteAsPreconfig', website, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const CopyPreconfiguredWebsite = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/CopyPreconfigWebsite', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateUserSubscriptionId = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/UpdateUserSubscriptionIdAdmin', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const CreateWebsite = async (website) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/CreateWebsite', website, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const CreateWebsiteAdmin = async (website) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/CreateWebsiteAdmin', website, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const CloneWebsite = async (website) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/CloneWebsite', website, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteUserWebsite = async (website) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/DeleteWebsite', website, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const RefreshWebsiteData = async (website) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/RefreshWebsiteData', website, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetWebsiteDetails = async (website) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetWebsiteDetails', website, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetLeads = async () => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/GetLeadsDashboardDataNEW', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetLeadDetails = async (contact) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/GetContactDetailsNEW', contact, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateContact = async (contact) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/UpdateContact', contact, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateContactTags = async (contact) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/UpdateContactTags', contact, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const MarkContactSpam = async (contact) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/MarkContactSpamNew', contact, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateContactStatus = async (contact) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/UpdateContactStatus', contact, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteContact = async (contact) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/RemoveContact', contact, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const RestoreContact = async (contact) => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/RestoreContact', contact, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const EmptyContactsTrash = async () => {
	const response = await axios
		.post(apiHost + 'ContactsService.asmx/EmptyContactsTrash', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetDomains = async () => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/GetUserDomains', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserAvailableDomains = async () => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/GetUserAvailableDomains', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const FindDomains = async (search) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/FindDomains', search, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const FindDomainSuggestions = async () => {
	const response = await axios
		.post(
			apiHost + 'DomainsService.asmx/GetSuggestedCompanyDomains',
			null,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const ApplyDomainToWebsite = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/ApplyDomain', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const RemoveDomain = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/DetachDomain', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteUserDomain = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/DeleteDomain', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const RegisterDomainByDomainName = async (data) => {
	const response = await axios
		.post(
			apiHost + 'DomainsService.asmx/RegisterDomainByDomainName',
			data,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddExistingDomain = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/AddonDomain', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetDomainDetails = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/GetDomainDetails', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetDomainRecords = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/GetDomainRecords', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetDomainForwardingDetails = async (data) => {
	const response = await axios
		.post(
			apiHost + 'DomainsService.asmx/GetDomainForwardingDetails',
			data,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetDomainSubdomains = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/GetDomainSubdomains', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateDomainAutoRenew = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/UpdateDomainAutoRenew', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddNewDomainRecord = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/AddDomainRecord', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteDomainRecord = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/DeleteDomainRecord', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateDomainRecord = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/UpdateDomainRecord', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateDomainForwardingDetails = async (data) => {
	const response = await axios
		.post(
			apiHost + 'DomainsService.asmx/UpdateDomainForwardingDetails',
			data,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddDomainSubdomain = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/AddSubdomain', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SetupEmailService = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/SetupEmailRecords', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddDomainToCart = async (domain) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/AddDomainToCart', domain, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetCartCount = async () => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/GetCartItemsCount', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetCartItems = async () => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/GetCartItems', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteCartItem = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/DeleteCartItem', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SetDomainPrivacy = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/SetDomainPrivacyState', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const PurchaseCartItems = async (data) => {
	const response = await axios
		.post(apiHost + 'DomainsService.asmx/PurchaseCartItems', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserSpamFilters = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserSpamFilters', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserActiveSpamFilters = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserActiveSpamFilters', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserSpamFilter = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserSpamFilter', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateUserSpamFilter = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/UpdateUserSpamFilter', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const CreateUserSpamFilter = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/CreateUserSpamFilter', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteUserSpamFilter = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/DeleteUserSpamFilter', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const ChangeUserSpamFilterStatus = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/ChangeUserSpamFilterStatus', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetAccountCards = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserCreditCards', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddCreditCard = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/AddCreditCard', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SetDefaultCreditCard = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SetDefaultCreditCard', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUsersList = async (search) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUsersAdminView', search, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddTagToUser = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/AddTagToUser', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const RemoveTagFromUser = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/RemoveTagFromUser', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddUserNote = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/AddUserNote', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateUserNote = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/UpdateUserNote', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteUserNote = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/DeleteUserNote', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserDetailsAdmin = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserDetailsAdminView', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SaveUserSettingsAdmin = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SaveUserSettingsAdmin', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserInvoicesAdmin = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserInvoicesAdminView', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteInvoiceAdmin = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/DeleteInvoiceAdmin', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetBillingHistoryAdmin = async (data) => {
	const response = await axios
		.post(
			apiHost + 'UsersService.asmx/GetBillingHistoryAdminViewNew',
			data,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const RefundPayment = async (data) => {
	const response = await axios
		.post(apiHost + 'CartService.asmx/RefundPayment', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SendPasswordResetAdmin = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/SendResetPasswordEmail', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserWebsitesAdmin = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserWebsitesAdminView', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateWebsiteUnderConstruction = async (data) => {
	const response = await axios
		.post(
			apiHost + 'UsersService.asmx/UpdateWebsiteUnderConstruction',
			data,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DisableWebsitePlugins = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/DisableWebsitePlugins', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateWebsiteTitle = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/UpdateWebsiteTitle', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const LoginIntoCustomerAccount = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/LoginAsUser', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const ExitCustomerAccount = async () => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/ExitUserAccount', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const CreateUserAccount = async (data) => {
	const response = await axios
		.post(apiHost + 'SignupService.asmx/CreateUserAccount', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetInvoiceProducts = async () => {
	const response = await axios
		.get(window.location.origin + '/assets/invoice-products.json', null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response;
};

export const CreateInvoiceAdmin = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/CreateInvoice', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetUserInvoice = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetUserInvoice', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const PayInvoice = async (data) => {
	const response = await axios
		.post(apiHost + 'CartService.asmx/PayInvoice', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateInvoiceAdmin = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/UpdateInvoice', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetInvoiceLinkByCode = async (data) => {
	const response = await axios
		.post(apiHost + 'UsersService.asmx/GetInvoiceLinkByCode', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const PayCustomSetup = async (data) => {
	const response = await axios
		.post(apiHost + 'CartService.asmx/PayCustomSetup', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetMessageTemplates = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/GetMessageTemplates', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetMessageTemplateDetails = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/GetMessageTemplateDetails', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetMessageHistory = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/GetMessageHistory', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetMessageHistoryDetails = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/GetMessageHistoryDetails', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddMessageTemplate = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/AddMessageTemplate', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const UpdateMessageTemplate = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/UpdateMessageTemplate', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const DeleteMessageTemplate = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/DeleteMessageTemplate', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const SendMessage = async (data) => {
	const response = await axios
		.post(apiHost + 'BrandsService.asmx/SendMessage', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};
