import { useHistory } from 'react-router-dom';
import CardInput from '../../../selectCreditCard/cardInput/CardInput';
import './AddCreditCard.css';

const AddCreditCard = () => {
	const history = useHistory();

	const handleOnSave = () => {
		history.push('/account');
	};

	const handleOnCancel = () => {
		history.push('/account');
	};

	return (
		<div className="app-add-credit-card panel">
			<div className="container">
				<h5 className="rw-container-title">
					<i className="far fa-credit-card" />
					<span>Add New Card</span>
				</h5>

				<div className="rw-container shadow-large add-card">
					<CardInput onSave={handleOnSave} onCancel={handleOnCancel} />
				</div>
			</div>
		</div>
	);
};

export default AddCreditCard;
