import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DisableWebsitePlugins, UpdateWebsiteUnderConstruction } from '../../../../../../Services';
import './UserWebsiteV2.css';

const UserWebsiteV2 = ({ website, user }) => {
	const [ isUpdating, setIsUpdating ] = useState(false);
	const [ isUnderConstruction, setIsUnderConstruction ] = useState(null);
	const [ loginUrl, setLoginUrl ] = useState('');

	useEffect(() => {
		setIsUnderConstruction(website.UnderConstruction ? website.UnderConstruction : false);

		let tempLoginUrl = `/EditWebsite.aspx?guid=${website.Guid}&redirect=_wp-admin_&unlock=${website.Guid}&uguid=${user.Guid}`;

		setLoginUrl(tempLoginUrl);
	}, []);

	const handleUnderConstructionChange = async (e) => {
		setIsUpdating(true);

		let data = {
			WebsiteGuid       : website.Guid,
			UnderConstruction : e.target.checked
		};

		const response = await UpdateWebsiteUnderConstruction(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);
			setIsUnderConstruction(e.target.checked);
		} else {
			toast.error(response.Message);
		}

		setIsUpdating(false);
	};

	const handleDisablePlugins = async () => {
		if (
			window.confirm(
				'Are you sure that you want to disable ALL plugins on website ' +
					website.Url.replace('https://', '').replace('http://', '') +
					'?'
			)
		) {
			setIsUpdating(true);

			let data = {
				WebsiteGuid : website.Guid
			};

			const response = await DisableWebsitePlugins(data);

			if (response.IsSuccessful) {
				toast.success(response.Message);
			} else {
				if (response.Message && response.Message.toString().indexOf('404') > -1) {
					toast.error('Requested Api endpoint not found in plugin.');
				} else {
					toast.error(response.Message);
				}
			}

			setIsUpdating(false);
		}
	};

	return (
		<div className="rw-row">
			<div className="rw-td name">
				<Fragment>
					<div className="user-website-name text-truncate">
						<span>{website.WebsiteName}</span>
					</div>

					<div className="user-website-url text-truncate">
						<a href={website.Url} target="_blank" className="app-link" title="Opens in a new tab">
							<i className="fas fa-external-link-alt" />
							<span>{website.Url}</span>
						</a>
					</div>
				</Fragment>
			</div>
			<div className="rw-td support-id">
				<span>
					{website.WPAdminUsername}:{website.WPAdminPassword}
				</span>
			</div>
			<div className="rw-td under-construction">
				{isUnderConstruction !== null && (
					<Fragment>
						<input
							id={`chkWebsite${website.Guid}`}
							type="checkbox"
							className="rw-checkbox-switch"
							defaultChecked={isUnderConstruction}
							onChange={handleUnderConstructionChange}
						/>
						<label htmlFor={`chkWebsite${website.Guid}`} />
					</Fragment>
				)}
			</div>
			<div className="rw-td action">
				<Fragment>
					<a href={loginUrl} className="btn btn-primary" target="_blank" title="WP Login">
						<i class="fa-solid fa-right-to-bracket mr-0" />
						{/* <span>WP Login</span> */}
					</a>
					<button className="btn btn-primary ml-2" onClick={handleDisablePlugins} title="Disable Plugins">
						<i class="fa-solid fa-plug-circle-xmark mr-0" />
						{/* <span>Disable Plugins</span> */}
					</button>
				</Fragment>
			</div>
		</div>
	);
};

export default UserWebsiteV2;
