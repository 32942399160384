import { Fragment, useEffect, useState } from 'react';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import officeLogo from '../../../../../img/office365logo.png';
import gmailLogo from '../../../../../img/gmaillogo.png';
import godaddyLogo from '../../../../../img/godaddylogo.png';
import './SetupEmailRecords.css';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import EmailProvider from './emailProvider/EmailProvider';
import { SetupEmailService } from '../../../../../Services';
import { toast } from 'react-toastify';
import Spinner from '../../../../../spinner/Spinner';

const SetupEmailRecords = ({ domain, onSave, onCancel }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isValid, setIsValid ] = useState(false);
	const [ selectedProvider, setSelectedProvider ] = useState('');

	useEffect(
		() => {
			if (selectedProvider && selectedProvider !== '') {
				setIsValid(true);
			} else {
				setIsValid(false);
			}
		},
		[ selectedProvider ]
	);

	const handleSelectProvider = (provider) => {
		setSelectedProvider(provider);
	};

	const handleOpenChat = () => {
		window.LC_API.open_chat_window();
	};

	const handleConfirmation = async () => {
		setIsSaving(true);

		let data = {
			Guid     : domain.Guid,
			Provider : selectedProvider
		};

		const response = await SetupEmailService(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onSave) {
				onSave();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<ModalDialog>
				<ModalDialogContent>
					<div className="email-setup-container">
						<h5 className="rw-container-title">
							<span>Choose Your Email Provider</span>
						</h5>

						<div className="email-providers-container">
							<EmailProvider
								provider="Google Apps"
								logo={gmailLogo}
								disabled={isSaving}
								selectedProvider={selectedProvider}
								onSelect={handleSelectProvider}
							/>
							<EmailProvider
								provider="Office 365"
								logo={officeLogo}
								disabled={isSaving}
								selectedProvider={selectedProvider}
								onSelect={handleSelectProvider}
							/>
							<EmailProvider
								provider="Godaddy"
								logo={godaddyLogo}
								disabled={isSaving}
								selectedProvider={selectedProvider}
								onSelect={handleSelectProvider}
							/>
						</div>

						<div className="not-listed-container text-muted">
							If your provider is not listed please{' '}
							<a onClick={handleOpenChat} className="app-link">
								click here
							</a>{' '}
							for assistance.
						</div>
					</div>
				</ModalDialogContent>
				<ModalDialogButtons shaded>
					<button
						type="button"
						className="btn btn-primary"
						disabled={!isValid || isSaving}
						onClick={handleConfirmation}
					>
						{!isSaving ? (
							<Fragment>
								<i className="fas fa-save" />
								<span>Save</span>
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
								<span>Saving...</span>
							</Fragment>
						)}
					</button>

					<button type="button" className="btn btn-outline-primary" disabled={isSaving} onClick={onCancel}>
						<i className="fas fa-chevron-left" />
						<span>Cancel</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default SetupEmailRecords;
