import { Fragment } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

const MakeItLiveStart = () => {
	const match = useRouteMatch();
	const btnBackStyle = `
		.domain-apply-wizard .btn-back {
			display: none;
		}`;

	return (
		<Fragment>
			<div className="wizard-start wizard-step">
				<h3>Let's make your website live!</h3>
				<p className="text-muted">
					Follow the next few steps to apply a domain to your website
					so we can publish your site live on the internet.
				</p>

				<div className="buttons-container">
					<Link
						to={`${match.url}/have-domain`}
						className="btn btn-primary"
					>
						<i className="fas fa-chevron-right" />
						<span>Continue</span>
					</Link>
				</div>
			</div>
			<style type="text/css">{btnBackStyle}</style>
		</Fragment>
	);
};

export default MakeItLiveStart;
