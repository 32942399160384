import { Fragment, useEffect, useState } from 'react';
import Slider from '@material-ui/core/Slider';
import './DomainSearchResult.css';
import { AddDomainToCart, GetCartCount } from '../../../../../../Services';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../../../../StateProvider';
import Spinner from '../../../../../../spinner/Spinner';
import { Link } from 'react-router-dom';
import { actionTypes } from '../../../../../../reducer';

const DomainSearchResult = ({ domain }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isInCart, setIsInCart ] = useState(false);
	const [ localDomain, setLocalDomain ] = useState(null);
	const [ duration, setDuration ] = useState(1);
	const [ privacy, setPrivacy ] = useState(false);
	const [ total, setTotal ] = useState(0);
	const [ durationLabel, setDurationLabel ] = useState('year');
	const [ { cartItemsCount }, dispatch ] = useStateValue();

	useEffect(
		() => {
			setLocalDomain({
				...domain,
				IsPrivate : privacy,
				Duration  : duration
			});
			setTotal(domain.Price * duration);
			setIsInCart(domain.IsInCart);
		},
		[ domain ]
	);

	const handlePrivacyChange = (e) => {
		setPrivacy(e.target.checked);
	};

	const handleDurationChange = (e, value) => {
		setDuration(value);
	};

	useEffect(
		() => {
			if (localDomain) {
				let price = parseFloat(duration) * localDomain.Price;

				if (privacy) {
					price += parseFloat(duration) * 10;
				}

				setDurationLabel(duration > 1 ? 'years' : 'year');

				// console.log(price);

				setTotal(price);
			}
		},
		[ duration, privacy ]
	);

	const checkCartItemsCount = async () => {
		const response = await GetCartCount();

		if (response.IsSuccessful) {
			dispatch({
				type           : actionTypes.SET_CART_ITEMS_COUNT,
				cartItemsCount : parseInt(response.Message)
			});
		} else {
			dispatch({
				type           : actionTypes.SET_CART_ITEMS_COUNT,
				cartItemsCount : 0
			});
		}
	};

	const handleAddToCart = async (e) => {
		setIsLoading(true);

		let tempDomain = {};
		tempDomain.DomainName = localDomain.DomainName;
		tempDomain.Term = duration;
		tempDomain.IsPrivateRegistration = privacy;

		const response = await AddDomainToCart(tempDomain);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			setIsInCart(true);

			const cartCountResponse = await checkCartItemsCount();
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			{localDomain && (
				<div className="rw-domain-search-result shadow-large xyz-in">
					<div className="rw-search-domain">
						{localDomain.DomainName}
					</div>
					<small className="text-muted">
						${localDomain.Price} / year
					</small>
					<div className="rw-domain-privacy">
						<small className="alert alert-success">
							Includes Domain Privacy
						</small>
						{/* <h6 className="rw-domain-privacy-title">
							Domain Privacy
						</h6>
						<div className="rw-domain-privacy-checkbox">
							<input
								id={`chk${localDomain.DomainName}`}
								type="checkbox"
								className="rw-checkbox-switch"
								defaultChecked={privacy}
								onChange={handlePrivacyChange}
							/>
							<label htmlFor={`chk${localDomain.DomainName}`} />
						</div> */}
					</div>
					{/* <small className="rw-privacy-price mb-4">$10 / year</small>
					<small className="text-muted privacy-text">
						Privacy hides your personal information from the public.
					</small> */}
					<h6 className="rw-domain-duration-title">Years</h6>
					<Slider
						defaultValue={duration}
						min={1}
						max={10}
						step={1}
						marks
						valueLabelDisplay="auto"
						onChange={handleDurationChange}
					/>
					<hr />
					<div className="rw-domain-total-line">
						<div className="rw-domain-total-label">
							{duration} {durationLabel} x ${localDomain.Price}
						</div>
						<div className="rw-domain-total-value">
							${duration * localDomain.Price}
						</div>
					</div>
					{privacy && (
						<div className="rw-domain-total-line">
							<div className="rw-domain-total-label">
								{duration} {durationLabel} privacy x $10
							</div>
							<div className="rw-domain-total-value">
								${duration * 10}
							</div>
						</div>
					)}
					<div className="rw-domain-total-line total">
						<div className="rw-domain-total-label">Total</div>
						<div className="rw-domain-total-value">${total}</div>
					</div>
					<hr />
					{!isInCart ? (
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleAddToCart}
							disabled={isLoading}
						>
							{!isLoading ? (
								<Fragment>
									<i className="fas fa-cart-plus" />
									<span>Add to Cart</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Adding to Cart...</span>
								</Fragment>
							)}
						</button>
					) : (
						<Link to="/cart" className="btn btn-primary">
							<i class="fas fa-shopping-bag" />
							<span>Checkout</span>
						</Link>
					)}
				</div>
			)}
		</Fragment>
	);
};

export default DomainSearchResult;
