import { useState } from 'react';
import { useStateValue } from '../../StateProvider';
import './AppForgotPassword.css';
import { animationDefaults } from '../../AnimConfig';
import { ValidateUser } from '../../Services';
import { actionTypes } from '../../reducer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ForgotPasswordForm from './forgotPasswordForm/ForgotPasswordForm';

const AppForgotPassword = () => {
	const [ { brand }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const history = useHistory();

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		if (target === 'email') {
			setEmail(value);
		} else if (target === 'password') {
			setPassword(value);
		}
	};

	const loginCallback = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		const response = await ValidateUser(email, password, brand.Id);

		if (response.IsSuccessful) {
			dispatch({
				type : actionTypes.SET_USER,
				user : JSON.parse(response.Data)
			});

			toast.success(response.Message);

			history.push('/');
		} else {
			toast.error(response.Message);
			setIsLoading(false);
		}
	};

	return (
		<div className="app-login-container">
			<div className="login-column-container">
				{brand &&
				brand.BrandLogo && (
					<div className="logo-box">
						<img src={brand.BrandLogo} alt="Logo" />
					</div>
				)}
				<div
					className={`login-box shadow-large ${animationDefaults.animInClassName}`}
					xyz={animationDefaults.xyzDefaultSettings}
				>
					<h3>Forgot Your Password</h3>
					<p>
						Enter your login email address to reset your password. If an account is found under the email
						address you entered an email will be sent to your inbox with instructions on resetting the
						account password.
					</p>
					<ForgotPasswordForm />
				</div>
				<button type="button" className="btn btn-link btn-block mt-3" onClick={() => history.goBack()}>
					<i className="fas fa-chevron-left" />
					<span>Go Back</span>
				</button>
			</div>
		</div>
	);
};

export default AppForgotPassword;
