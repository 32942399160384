import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ValidateToken } from '../../Services';
import Spinner from '../../spinner/Spinner';
import MainAppHeader from '../mainApp/mainAppHeader/MainAppHeader';
import './OneTimeUseToken.css';

const OneTimeUseToken = () => {
	const { token } = useParams();
	const [ tryCount, setTryCount ] = useState(0);
	const history = useHistory();

	useEffect(() => {
		verifyToken();

		let verifyInterval = setInterval(() => {
			verifyToken();
		}, 2000);

		return () => {
			clearInterval(verifyInterval);
		};
	}, []);

	useEffect(
		() => {
			if (tryCount >= 25) {
				history.push('/login');
			}
		},
		[ tryCount ]
	);

	const verifyToken = async () => {
		let data = {
			Token : token
		};

		const response = await ValidateToken(data);

		if (response.IsSuccessful) {
			// token valid, redirect to app
			localStorage.setItem('rw-gtour', '1');
			history.push('/');
		} else {
			setTryCount((prev) => prev + 1);
		}
	};

	return (
		<div className="app-otut-panel">
			<MainAppHeader hideBanners hideCart hideProfileInfo />
			<div className="app-otut-container">
				<div className="app-otut">
					{tryCount <= 10 ? (
						<div className="text-muted mb-3">
							Please wait while we setup your account...
						</div>
					) : tryCount <= 20 ? (
						<div className="alert alert-warning mb-3">
							This is taking longer than usual. Keep in mind that
							you can also contact our support team via chat by
							clicking on the button on the top right of this
							window.
						</div>
					) : (
						<div className="alert alert-danger mb-3">
							It looks like something is wrong. We will now
							redirect you to our login page. Please check your
							email Inbox and Spam folders for an email from us
							with your login details.
						</div>
					)}
					<Spinner />
				</div>
			</div>
		</div>
	);
};

export default OneTimeUseToken;
