import './BrandHistoryItem.css';
import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BrandHistoryItem = ({ item }) => {
	const [ itemRecipients, setItemRecipients ] = useState([]);

	useEffect(() => {
		if (item) {
			if (item.Recipients) {
				setItemRecipients(item.Recipients.split(','));
			}
		}
	}, []);

	return (
		<Fragment>
			<div className="rw-row collapsable-parent item-container">
				<div className="rw-td template">
					{item.TemplateType !== 'system' && item.TemplateName ? item.TemplateName : ''}
				</div>
				<div className="rw-td recipient">
					{itemRecipients.map((recipient, index) => <div key={index}>{recipient}</div>)}
				</div>
				<div className="rw-td created">{item.DateCreatedStr}</div>
				<div className="rw-td action">
					<Link to={`/administration/${item.Type}/history/view/${item.Guid}`} className="btn btn-link">
						<span>View</span>
					</Link>
				</div>
			</div>
		</Fragment>
	);
};

export default BrandHistoryItem;
