import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GetDomains } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import { useStateValue } from '../../../../StateProvider';
import './DomainsList.css';
import Domain from './domain/Domain';
import Search from '../../../search/Search';
import { actionTypes } from '../../../../reducer';

const DomainsList = () => {
	const [ { brand, cartItemsCount }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ domains, setDomains ] = useState([]);
	const [ fullDomainsList, setFullDomainsList ] = useState([]);
	const [ domainSearchTerm, setDomainSearchTerm ] = useState('');
	const [ searchTerm, setSearchTerm ] = useState('');
	const history = useHistory();

	useEffect(() => {
		loadDomains();
	}, []);

	useEffect(
		() => {
			setIsLoading(true);

			loadDomains();
		},
		[ cartItemsCount ]
	);

	const loadDomains = async () => {
		const response = await GetDomains();

		if (response.IsSuccessful) {
			const tempDomains = JSON.parse(response.Data);
			setFullDomainsList(tempDomains);

			setDomains(tempDomains);
		} else {
			// TODO: show no domains message
		}

		setIsLoading(false);
	};

	const handleDomainSearchTermChange = (e) => {
		const term = e.target.value;

		setDomainSearchTerm(term);
	};

	const handleDomainSearchSubmission = (e) => {
		e.preventDefault();

		dispatch({
			type             : actionTypes.SET_DOMAIN_SEARCH_TERM,
			domainSearchTerm : domainSearchTerm
		});

		history.push('/domains/search');
	};

	const handleAddAddonDomain = () => {
		history.push('/domains/add-existing-domain');
	};

	const handleDomainUpdate = () => {
		loadDomains();
	};

	return (
		<div className="app-domains-list panel">
			{!isLoading ? (
				<Fragment>
					{domains && domains.length > 0 ? (
						<Fragment>
							<div className="domain-search-container">
								<div className="container text-left">
									<h5>
										<i class="fas fa-globe-americas" />
										<span>Search for a new domain</span>
									</h5>
									<div className="search-bar">
										<Search
											searchTerm={domainSearchTerm}
											handleChange={handleDomainSearchTermChange}
											submitEvent={handleDomainSearchSubmission}
											buttonText="Search"
											placeholder="Enter a search term"
										/>
									</div>

									<div className="buttons-container">
										<button
											type="button"
											className="btn btn-primary"
											onClick={handleAddAddonDomain}
										>
											<i class="fas fa-globe-americas" />
											<span>Addon Existing Domain</span>
										</button>
									</div>
								</div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<CenteredContainer>
								<div className="domain-search-container">
									<div className="container text-left">
										<h5>
											<i class="fas fa-globe-americas" />
											<span>Search for a new domain</span>
										</h5>
										<Search
											searchTerm={domainSearchTerm}
											handleChange={handleDomainSearchTermChange}
											submitEvent={handleDomainSearchSubmission}
											buttonText="Search"
											placeholder="Enter a search term"
										/>

										<div className="buttons-container">
											<button
												type="button"
												className="btn btn-primary"
												onClick={handleAddAddonDomain}
											>
												<i class="fas fa-globe-americas" />
												<span>Addon Existing Domain</span>
											</button>
										</div>
									</div>
								</div>
							</CenteredContainer>
						</Fragment>
					)}

					{domains &&
					domains.length > 0 && (
						<div className="domains-list">
							<div className="container">
								<h5 className="rw-container-title">
									<i class="fas fa-globe-americas" />
									<span>Your Domains</span>
								</h5>
								<div className="rw-container">
									<div className="rw-table">
										<div className="domains-list-header rw-table-header">
											<div className="rw-th domain-name">Domain Name</div>
											<div className="rw-th auto-renew">Auto Renew</div>
											<div className="rw-th expires">Expires</div>
											<div className="rw-th notice" />
											<div className="rw-th status">Status</div>
											<div className="rw-th action" />
										</div>
										{domains.map((domain) => (
											<Domain key={domain.Guid} domain={domain} onSave={handleDomainUpdate} />
										))}
									</div>
								</div>
							</div>
						</div>
					)}
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default DomainsList;
