import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../reducer';
import {
	AddExistingDomain,
	GetUserAvailableDomains
} from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import MakeItLiveDomainSuggestion from './MakeItLiveDomainSuggestion';

const MakeItLiveExistingDomain = () => {
	const [ { makeItLiveSettings }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ domains, setDomains ] = useState([]);
	const [ selectedDomain, setSelectedDomain ] = useState(null);
	const [ showDomainAddon, setShowDomainAddon ] = useState(false);
	const [ domainName, setDomainName ] = useState('');
	const [ showDomainSearchMessage, setShowDomainSearchMessage ] = useState(
		false
	);
	const { guid } = useParams();
	const history = useHistory();

	useEffect(() => {
		getExistingDomains();
	}, []);

	useEffect(
		() => {
			if (makeItLiveSettings && makeItLiveSettings.domain) {
				setSelectedDomain(makeItLiveSettings.domain.DomainName);
			}
		},
		[ makeItLiveSettings ]
	);

	const getExistingDomains = async () => {
		const response = await GetUserAvailableDomains();

		if (response.IsSuccessful) {
			let tempDomains = JSON.parse(response.Data);
			console.table(tempDomains);

			setDomains(tempDomains);
		}

		setIsLoading(false);
	};

	const toggleAddonDomain = () => {
		setShowDomainAddon((prev) => !prev);

		if (!domains || domains.length === 0) {
			handleCancel();
		}
	};

	const handleSelectedDomain = (domain) => {
		setSelectedDomain(domain);
	};

	const handleContinue = () => {
		let tempSettings = {
			domain : selectedDomain
		};

		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : { ...tempSettings }
		});

		history.push(`/make-it-live/${guid}/apply-domain`);
	};

	const handleCancel = () => {
		history.push(`/make-it-live/${guid}/have-domain`);
	};

	// addon domain methods
	const handleChange = (e) => {
		setDomainName(e.target.value.trim());
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setShowDomainSearchMessage(false);

		if (domainName === '') {
			toast.error('Domain name is required');
			return;
		}

		setIsSaving(true);

		let data = {
			DomainName : domainName
		};

		const response = await AddExistingDomain(data);

		if (response.IsSuccessful) {
			let tempDomain = JSON.parse(response.Data);
			// console.log(tempDomain);

			let tempSettings = {
				domain : tempDomain,
				card   : null
			};

			dispatch({
				type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
				makeItLiveSettings : { ...tempSettings }
			});

			history.push(`/make-it-live/${guid}/apply-domain`);
		} else {
			toast.error(response.Message);

			if (response.Data && response.Data !== '') {
				if (domainName.indexOf(response.Data) > -1) {
					// this means they tried to add-on a domain that isn't registered,
					// so we'll redirect them to the domain registration page
					setShowDomainSearchMessage(true);
				}
			}
		}

		setIsSaving(false);
	};

	return (
		<div className="wizard-domain-addon wizard-step">
			{!isLoading ? (
				<Fragment>
					{domains && domains.length > 0 && !showDomainAddon ? (
						<Fragment>
							<h3>Select one of your available domains</h3>
							<p className="text-muted">
								Only the domains NOT ALREADY APPLIED to a
								website are listed below
							</p>

							<div className="domain-suggestions-container">
								{domains.map((domain) => {
									return (
										<MakeItLiveDomainSuggestion
											key={domain.DomainName}
											domain={domain}
											selectedDomain={selectedDomain}
											onSelect={handleSelectedDomain}
										/>
									);
								})}
							</div>

							<div className="buttons-container">
								<button
									type="button"
									className="btn btn-primary"
									disabled={!selectedDomain}
									onClick={handleContinue}
								>
									<i className="fas fa-chevron-right" />
									<span>Apply Domain</span>
								</button>

								<button
									type="button"
									className="btn btn-outline-primary"
									onClick={toggleAddonDomain}
								>
									<i className="fas fa-plus" />
									<span>Add New Domain</span>
								</button>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<h3>Addon Your Domain</h3>
							<p className="text-muted">
								Enter the domain name you already own
							</p>

							<form onSubmit={handleSubmit} autoComplete="off">
								<div className="form-inputs">
									<div className="form-group">
										<input
											type="text"
											id="domainName"
											name="domainName"
											className="form-control form-control-lg"
											placeholder="Example: mybusiness.com"
											disabled={isSaving}
											value={domainName}
											onChange={handleChange}
										/>
									</div>
								</div>

								{showDomainSearchMessage && (
									<Fragment>
										<div className="alert alert-warning mt-3">
											The domain you are trying to add-on
											is not registered.{' '}
											<a
												href={`/c/make-it-live/${guid}/domain-search`}
											>
												Click here
											</a>{' '}
											to register this domain name.
										</div>
									</Fragment>
								)}

								<div className="buttons-container">
									<button
										type="submit"
										className="btn btn-primary"
										disabled={isSaving}
									>
										{!isSaving ? (
											<Fragment>
												<i className="fas fa-save" />
												<span>Save</span>
											</Fragment>
										) : (
											<Fragment>
												<Spinner />
												<span>Adding Domain...</span>
											</Fragment>
										)}
									</button>

									<button
										type="button"
										className="btn btn-outline-primary"
										disabled={isSaving}
										onClick={toggleAddonDomain}
									>
										<i className="fas fa-chevron-left" />
										<span>Cancel</span>
									</button>
								</div>
							</form>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Fragment>
					<Spinner />
				</Fragment>
			)}
			{/* <h3>Do you have a domain for your website?</h3>
			<p className="text-muted">
				Do you already have a domain that you have previously registered that you would like to use for your
				website?
			</p>

			<div className="buttons-container">
				<Link to={`/make-it-live/${guid}/add-domain`} className="btn btn-primary">
					<i className="fas fa-check" />
					<span>Yes</span>
				</Link>

				<Link to={`/make-it-live/${guid}/find-domain`} className="btn btn-primary">
					<i className="fas fa-times" />
					<span>No</span>
				</Link>
			</div> */}
		</div>
	);
};

export default MakeItLiveExistingDomain;
