import React, { Fragment, useEffect, useRef, useState } from 'react';
import Select from 'react-select';

const SaveAsPreconfiguredIndustrySelect = ({
	industries,
	onUpdate,
	isSaving
}) => {
	const [ preconfigIndustries, setPreconfigIndustries ] = useState();
	const [ dropdownSource, setDropdownSource ] = useState();
	const [ defaultSelected, setDefaultSelected ] = useState();
	const dropdownRef = useRef();

	useEffect(
		() => {
			if (industries) {
				let tempIndustries = industries.slice();

				if (
					tempIndustries.find((x) => x.IndustryName === 'My Sites') >
					-1
				) {
					let mySitesIndustry = tempIndustries.find(
						(x) => x.IndustryName === 'My Sites'
					);

					tempIndustries = tempIndustries.splice(
						tempIndustries.indexOf(mySitesIndustry),
						1
					);
				}

				const tempDropdownSource = tempIndustries.map((industry) => ({
					value : industry.Id,
					label : industry.IndustryName
				}));

				setDropdownSource(tempDropdownSource);

				// set default selected values
				if (tempDropdownSource && tempDropdownSource.length > 0) {
					let tempSelected = tempIndustries
						.filter((x) => x.IsSelected)
						.map((industry) => ({
							value : industry.Id,
							label : industry.IndustryName
						}));

					setDefaultSelected(tempSelected);

					if (dropdownRef.current) {
						dropdownRef.current.setValue(tempSelected);
					}
				}

				setPreconfigIndustries(tempIndustries);
			}
		},
		[ industries ]
	);

	const handleChange = (selectedIndustries) => {
		if (onUpdate) {
			onUpdate(selectedIndustries);
		}
	};

	return (
		<Fragment>
			<Select
				ref={dropdownRef}
				options={dropdownSource}
				isMulti
				defaultValue={defaultSelected}
				closeMenuOnSelect={false}
				openMenuOnClick
				onChange={handleChange}
				isDisabled={isSaving}
				placeholder="Select Categories (optional)"
			/>
		</Fragment>
	);
};

export default SaveAsPreconfiguredIndustrySelect;
