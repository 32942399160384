import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteContact } from '../../../../../../Services';
import Spinner from '../../../../../../spinner/Spinner';
import ModalDialog from '../../../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../../../modalDialog/modalDialogContent/ModalDialogContent';
import './DeleteLeadDialog.css';

const DeleteLeadDialog = ({ lead, onDelete, onCancel }) => {
	const [ isDeleting, setIsDeleting ] = useState(false);

	const handleDelete = async () => {
		setIsDeleting(true);

		const response = await DeleteContact(lead);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onDelete) {
				onDelete();
			}
		} else {
			toast.error(response.Message);
		}

		setIsDeleting(false);
	};

	const handleOnCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<ModalDialog>
			<ModalDialogContent>
				<h5>Delete This Lead?</h5>
				<p>Are you sure you want to delete this lead?</p>
			</ModalDialogContent>
			<ModalDialogButtons shaded align="left">
				<button
					type="button"
					className="btn btn-danger"
					disabled={isDeleting}
					onClick={handleDelete}
				>
					{!isDeleting ? (
						<Fragment>
							<i className="far fa-trash-alt" />
							<span>Delete Lead</span>
						</Fragment>
					) : (
						<Fragment>
							<Spinner />
							<span>Deleting</span>
						</Fragment>
					)}
				</button>

				<button
					type="button"
					className="btn btn-outline-primary"
					disabled={isDeleting}
					onClick={handleOnCancel}
				>
					<span>Cancel</span>
				</button>
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default DeleteLeadDialog;
