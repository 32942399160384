import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../../../../StateProvider';

const CartSummaryItem = ({ domain, showDescription }) => {
	const [ { freeDomain }, dispatch ] = useStateValue();
	const [ durationLabel, setDurationLabel ] = useState('year');
	const privacyPrice = 10;

	useEffect(
		() => {
			if (domain) {
				if (domain.Duration > 1) {
					setDurationLabel('years');
				} else {
					setDurationLabel('year');
				}
			}
		},
		[ domain ]
	);

	return (
		<Fragment>
			<div className="rw-row">
				<div className="rw-td description">
					<span>{domain.DomainName.toLowerCase()}</span>
				</div>
				{freeDomain && domain.Id == freeDomain.Id ? (
					<div className="rw-td price">
						<span>${(domain.TermPrice - domain.YearlyPrice).toFixed(2)}</span>
					</div>
				) : (
					<div className="rw-td price">
						<span>${domain.TermPrice.toFixed(2)}</span>
					</div>
				)}
			</div>
			{showDescription && (
				<Fragment>
					<div className="rw-row sub-item">
						<div className="rw-td description">
							<i className="fas fa-caret-right" />
							<span>
								{domain.Duration} {durationLabel} domain registration
							</span>
						</div>
						<div className="rw-td price">
							<span>${(domain.Duration * domain.YearlyPrice).toFixed(2)}</span>
						</div>
					</div>
					{domain.IsPrivateRegistration && (
						<div className="rw-row sub-item">
							<div className="rw-td description">
								<i className="fas fa-caret-right" />
								<span>
									{domain.Duration} {durationLabel} domain privacy
								</span>
							</div>
							<div className="rw-td price">
								<span>${(domain.Duration * privacyPrice).toFixed(2)}</span>
							</div>
						</div>
					)}

					{freeDomain &&
					domain.Id == freeDomain.Id && (
						<div className="rw-row sub-item">
							<div className="rw-td description">
								<i className="fas fa-caret-right" />
								<span>1 year free domain registration</span>
							</div>
							<div className="rw-td price">
								<span>-${domain.YearlyPrice.toFixed(2)}</span>
							</div>
						</div>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default CartSummaryItem;
