import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../reducer';
import { GetCartItems, GetUserDetails } from '../../../Services';
import Spinner from '../../../spinner/Spinner';
import { useStateValue } from '../../../StateProvider';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import './Cart.css';
// import CartItem from './cartItem/CartItem';
import CartItemV2 from './cartItemV2/CartItemV2';

const Cart = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ userSet, setUserSet ] = useState(false);
	const [ cartItemsSet, setCartItemsSet ] = useState(false);
	const [
		{ selectedCartItems, freeDomain, user },
		dispatch
	] = useStateValue();
	const [ cartCount, setCartCount ] = useState(0);
	const [ cartItems, setCartItems ] = useState([]);
	const [ total, setTotal ] = useState(0);
	const history = useHistory();

	// load check
	useEffect(
		() => {
			if (cartItemsSet && userSet) {
				setIsLoading(false);
			}
		},
		[ cartItemsSet, userSet ]
	);

	useEffect(() => {
		dispatch({
			type              : actionTypes.SET_SELECTED_CART_ITEMS,
			selectedCartItems : []
		});

		dispatch({
			type       : actionTypes.SET_FREE_DOMAIN,
			freeDomain : null
		});

		dispatch({
			type             : actionTypes.SET_CART_SELECTED_CARD,
			cartSelectedCard : null
		});

		dispatch({
			type              : actionTypes.SET_CART_ITEMS_RESPONSE,
			cartItemsResponse : null
		});
		getCartItems();
		getUserDetails();
	}, []);

	useEffect(
		() => {
			// console.log('cart items updated');
		},
		[ cartItems ]
	);

	const getUserDetails = async () => {
		const response = await GetUserDetails();

		if (response.IsSuccessful) {
			const tempUser = JSON.parse(response.Data);
			tempUser.Password = '';

			dispatch({
				type : actionTypes.SET_USER,
				user : tempUser
			});

			setUserSet(true);
		} else {
			history.push('/login');
		}
	};

	const getCartItems = async () => {
		const response = await GetCartItems();

		if (response.IsSuccessful) {
			let tempItems = JSON.parse(response.Data);

			if (tempItems && tempItems.length > 0) {
				let tempTotal = 0;
				tempItems.map((item) => {
					item.Selected = true;

					tempTotal += item.TermPrice;
				});

				setTotal(tempTotal);
			}

			setCartItems([ ...tempItems ]);

			// tempItems = tempItems.sort((a, b) => b.Selected - a.Selected);
		} else {
			toast.error(response.Message);
		}

		setCartItemsSet(true);
	};

	const handleItemChange = (cartItem) => {
		let tempItems = cartItems;
		let itemIndex = tempItems.findIndex((x) => x.Id == cartItem.Id);
		tempItems[itemIndex] = cartItem;

		setCartItems(tempItems);

		let selectedItems = tempItems.filter((x) => x.Selected);

		if (selectedItems && selectedItems.length > 0) {
			let tempTotal = 0;

			selectedItems.map((item) => {
				tempTotal += item.TermPrice;
			});

			setTotal(tempTotal);
		} else {
			setTotal(0);
		}
	};

	const handleContinue = () => {
		let tempItems = cartItems;
		let selectedItems = tempItems.filter((x) => x.Selected);

		if (selectedItems && selectedItems.length > 0) {
			dispatch({
				type              : actionTypes.SET_SELECTED_CART_ITEMS,
				selectedCartItems : selectedItems
			});

			if (user.FreeDomains > 0) {
				history.push('/cart/free-domain');
			} else {
				history.push('/cart/summary');
			}
		} else {
			toast.error(
				'Please select an item to process in order to continue'
			);
		}
	};

	return (
		<div className="app-cart panel">
			{!isLoading ? (
				<div className="container">
					<h5 className="rw-container-title">
						<i className="fas fa-shopping-bag" />
						<span>Cart Items</span>
					</h5>

					<div className="rw-container">
						<div className="cart-items">
							{/* <div className="rw-table">
								<div className="rw-table-header">
									<div className="rw-th check" />
									<div className="rw-th name">Name</div>
									<div className="rw-th privacy">Privacy</div>
									<div className="rw-th price">Price</div>
									<div className="rw-th action" />
								</div> */}

							{cartItems &&
							cartItems.length > 0 && (
								<Fragment>
									{cartItems.map((item) => (
										<CartItemV2
											key={item.Id}
											item={item}
											onItemChange={handleItemChange}
											onPrivacyChange={getCartItems}
											onDelete={getCartItems}
										/>
									))}
								</Fragment>
							)}

							<div className="rw-row total">
								<div className="rw-td total-column">
									<strong>Total: </strong>
									${total.toFixed(2)}
								</div>
							</div>
							{/* </div> */}
						</div>
						<div className="rw-container-buttons">
							<button
								type="button"
								className="btn btn-primary"
								disabled={!total || total === 0}
								onClick={handleContinue}
							>
								<i className="fas fa-chevron-right" />
								<span>Continue</span>
							</button>
						</div>
					</div>
				</div>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default Cart;
