import { Fragment, useEffect, useState } from 'react';
import { GetDomainRecords } from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import NoDataMessage from '../../../../noDataMessage/NoDataMessage';
import SetupEmailRecords from '../setupEmailRecords/SetupEmailRecords';
import AddDomainRecord from './addDomainRecord/AddDomainRecord';
import DomainRecord from './domainRecord/DomainRecord';
import './DomainRecords.css';

const DomainRecords = ({ domain }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ showSetupEmail, setShowSetupEmail ] = useState(false);
	const [ isInAddMode, setIsInAddMode ] = useState(false);
	const [ domainRecords, setDomainRecords ] = useState([]);

	useEffect(
		() => {
			if (domain) {
				getDomainRecords();
			} else {
				setDomainRecords([]);
			}
		},
		[ domain ]
	);

	const getDomainRecords = async () => {
		let data = {
			Guid : domain.Guid
		};

		const response = await GetDomainRecords(data);

		if (response.IsSuccessful) {
			let tempRecords = JSON.parse(response.Data);
			console.log(tempRecords);

			setDomainRecords(tempRecords);
		}

		setIsLoading(false);
	};

	const handleRecordUpdated = () => {
		getDomainRecords();
	};

	const toggleAddMode = () => {
		setIsInAddMode((prev) => !prev);
	};

	const handleAddNewRecord = () => {
		toggleAddMode();
		handleRecordUpdated();
	};

	const toggleSetupEmail = () => {
		setShowSetupEmail((prev) => !prev);
	};

	const handleSetupEmail = () => {
		setShowSetupEmail(false);
		handleRecordUpdated();
	};

	return (
		<Fragment>
			<div className="rw-container">
				<div className="domain-records-container">
					{!isLoading ? (
						<Fragment>
							{domainRecords && domainRecords.length > 0 ? (
								<Fragment>
									<div className="domain-records">
										<div className="rw-table">
											<div className="rw-table-header">
												<div className="rw-th type">Type</div>
												<div className="rw-th host">Host / Name</div>
												<div className="rw-th value">Value</div>
												<div className="rw-th action" />
											</div>

											{domainRecords.map((domainRecord) => (
												<DomainRecord
													key={domainRecord.Id}
													domainRecord={domainRecord}
													onSave={handleRecordUpdated}
												/>
											))}
										</div>
									</div>
								</Fragment>
							) : (
								<NoDataMessage />
							)}
						</Fragment>
					) : (
						<CenteredContainer>
							<Spinner />
						</CenteredContainer>
					)}
				</div>

				{isInAddMode && (
					<AddDomainRecord domain={domain} onSave={handleAddNewRecord} onCancel={toggleAddMode} />
				)}

				<div className="rw-container-buttons">
					<button type="button" className="btn btn-primary" disabled={isLoading} onClick={toggleAddMode}>
						<i className="fas fa-plus" />
						<span>Add New Record</span>
					</button>

					<button type="button" className="btn btn-secondary" onClick={toggleSetupEmail} disabled={isLoading}>
						<i className="fas fa-envelope" />
						<span>Setup Email Records</span>
					</button>
				</div>
			</div>

			{showSetupEmail && (
				<SetupEmailRecords domain={domain} onSave={handleSetupEmail} onCancel={toggleSetupEmail} />
			)}
		</Fragment>
	);
};

export default DomainRecords;
