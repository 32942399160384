import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddExistingDomain } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import './AddAddonDomain.css';

const AddAddonDomain = () => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ domainName, setDomainName ] = useState('');
	const history = useHistory();
	const validDomainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/gim;

	const handleChange = (e) => {
		let tempDomainName = e.target.value;

		if (tempDomainName) {
			tempDomainName = tempDomainName.trim();

			if (tempDomainName.startsWith('www.')) {
				tempDomainName = tempDomainName.replace('www.', '');
			}

			if (
				tempDomainName.startsWith('http://') ||
				tempDomainName.startsWith('https://')
			) {
				tempDomainName = tempDomainName
					.replace('http://', '')
					.replace('https://', '');
			}

			if (tempDomainName.endsWith('/')) {
				tempDomainName = tempDomainName.substring(
					0,
					tempDomainName.length - 1
				);
			}
		}

		setDomainName(tempDomainName);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let tempDomainName = domainName.trim();

		if (tempDomainName.startsWith('www.')) {
			tempDomainName = tempDomainName.replace('www.', '');
		}

		if (
			tempDomainName.startsWith('http://') ||
			tempDomainName.startsWith('https://')
		) {
			tempDomainName = tempDomainName
				.replace('http://', '')
				.replace('https://', '');
		}

		if (tempDomainName.endsWith('/')) {
			tempDomainName = tempDomainName.substring(
				0,
				tempDomainName.length - 1
			);
		}

		if (!tempDomainName.match(validDomainRegex)) {
			toast.error(
				'Please enter a valid domain name. Example: mybusiness.com'
			);
			return;
		}

		setIsSaving(true);

		const data = {
			DomainName : tempDomainName
		};

		const response = await AddExistingDomain(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			history.push('/domains');
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<div className="app-add-addon-domain panel">
			<div className="container">
				<div className="rw-container-title">
					<h5>
						<i class="fas fa-globe-americas" />
						<span>Addon Existing Domain</span>
					</h5>
				</div>
				<div className="rw-container">
					<form onSubmit={handleSubmit} autoComplete="off">
						<div className="form-inputs">
							<div className="form-group">
								<label htmlFor="domainName">
									Enter your domain name
								</label>
								<input
									type="text"
									id="domainName"
									name="domainName"
									className="form-control form-control-lg"
									placeholder="Example: mybusiness.com"
									disabled={isSaving}
									value={domainName}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="rw-container-buttons">
							<button
								type="submit"
								className="btn btn-primary"
								disabled={isSaving}
							>
								{!isSaving ? (
									<Fragment>
										<i className="fas fa-save" />
										<span>Save</span>
									</Fragment>
								) : (
									<Fragment>
										<Spinner />
										<span>Adding Domain...</span>
									</Fragment>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddAddonDomain;
