import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import { ApplyDomainToWebsite, RegisterDomainByDomainName } from '../../../../Services';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../reducer';

const MakeItLiveApplyDomain = () => {
	const [ { user, makeItLiveSettings }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(true);
	const [ success, setSuccess ] = useState(false);
	const [ savingMessage, setSavingMessage ] = useState('Please wait while we apply your domain...');
	const [ message, setMessage ] = useState('');
	const { guid } = useParams();
	const history = useHistory();

	useEffect(
		() => {
			if (makeItLiveSettings && makeItLiveSettings.domain) {
				handleApplyDomain();
			}
		},
		[ makeItLiveSettings ]
	);

	const handleApplyDomain = async () => {
		if (makeItLiveSettings.domain.Guid) {
			// this means the domain is an existing domain,
			// not a new purchase

			let data = {
				WebsiteId : guid,
				DomainId  : makeItLiveSettings.domain.Guid
			};

			const response = await ApplyDomainToWebsite(data);

			if (response.IsSuccessful) {
				dispatch({
					type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
					makeItLiveSettings : null
				});

				setSuccess(true);
			} else {
				toast.error(response.Message);
				setMessage(response.Message);
				setSuccess(false);
			}

			setIsSaving(false);
		} else {
			setSavingMessage("We're registering this domain for you...");

			let data = {
				WebsiteId             : guid,
				DomainName            : makeItLiveSettings.domain.DomainName,
				Term                  : 1,
				IsPrivateRegistration : makeItLiveSettings.IsPrivateRegistration,
				CardId                : makeItLiveSettings.card ? makeItLiveSettings.card.Guid : ''
			};

			const response = await RegisterDomainByDomainName(data);

			if (response.IsSuccessful) {
				const domainData = JSON.parse(response.Data);

				setSavingMessage('Please wait while we apply your new domain...');

				dispatch({
					type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
					makeItLiveSettings : {
						...makeItLiveSettings,
						domain : {
							...makeItLiveSettings.domain,
							Guid : domainData.DomainGuid
						}
					}
				});

				let data = {
					WebsiteId : guid,
					DomainId  : domainData.DomainGuid
				};

				const applyResponse = await ApplyDomainToWebsite(data);

				if (applyResponse.IsSuccessful) {
					dispatch({
						type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
						makeItLiveSettings : null
					});

					setSuccess(true);
				} else {
					toast.error(applyResponse.Message);
					setMessage(applyResponse.Message);
					setSuccess(false);
				}
			} else {
				toast.error(response.Message);
				setMessage(response.Message);
				setSuccess(false);
			}

			setIsSaving(false);
		}
	};

	const handleOpenChat = () => {
		if (window.LC_API) {
			if (user && user.Email) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email.toLowerCase();

				window.LC_API.set_visitor_name(name);
				window.LC_API.set_visitor_email(email);
			}
			window.LC_API.open_chat_window();
		}
	};

	const handleCloseWizard = () => {
		history.push('/');
	};

	return (
		<div className="wizard-apply-domain wizard-step">
			{isSaving ? (
				<Fragment>
					<h3>{savingMessage}</h3>
					<Spinner />
				</Fragment>
			) : (
				<Fragment>
					{success ? (
						<Fragment>
							<h3>Congratulations! Your website is now live!</h3>
							<div className="buttons-container">
								<button className="btn btn-primary" onClick={handleCloseWizard}>
									<i className="fas fa-home" />
									<span>Show My Websites</span>
								</button>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<h3>{message}</h3>
							<div className="alert alert-warning">
								It looks like something went wrong.{' '}
								<a onClick={handleOpenChat} className="app-link">
									Click here
								</a>{' '}
								if you would like assistance with this step.
							</div>

							<div className="buttons-container">
								<button className="btn btn-primary" onClick={handleCloseWizard}>
									<i className="fas fa-home" />
									<span>Close Wizard</span>
								</button>
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default MakeItLiveApplyDomain;
