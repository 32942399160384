import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../reducer';
import {
	AddTagToUser,
	RemoveTagFromUser,
	AddUserNote,
	UpdateUserNote,
	DeleteUserNote,
	LoginIntoCustomerAccount
} from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import './UserListView.css';
import UserNotes from './userNotes/UserNotes';
import UserTags from './userTags/UserTags';
import UserWebsitesList from './userWebsitesList/UserWebsitesList';

const UserListView = ({ customer, onTagsUpdate, onNotesUpdate, tagsList = null }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ signupDate, setSignupDate ] = useState('');
	const [ statusClass, setStatusClass ] = useState('success');
	const [ showNotes, setShowNotes ] = useState(false);
	const [ showWebsites, setShowWebsites ] = useState(false);
	const [ isLoggingIn, setIsLoggingIn ] = useState(false);
	const [ showActivationOfferIndicator, setShowActivationOfferIndicator ] = useState(false);
	const [ offerViewCount, setOfferViewCount ] = useState(0);
	const [ agingInvoicesCount, setAgingInvoicesCount ] = useState(0);
	const history = useHistory();

	useEffect(
		() => {
			const tempDate = new Date(customer.DateCreated);
			const dateDiff = new Date() - tempDate;

			const daysDiff = Math.ceil(dateDiff / (1000 * 60 * 60 * 24));

			if (daysDiff === 0) {
				setSignupDate('today');
			} else {
				const options = {
					month : 'short',
					day   : 'numeric',
					year  : 'numeric'
				};
				const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);

				setSignupDate(dateTimeFormat.format(tempDate));
			}

			let status = customer.Status.toLowerCase();

			if (customer.ForDelete) {
				setStatusClass('danger');
			} else if (status === 'active') {
				setStatusClass('success');
			} else if (status === 'canceled' || status === 'declined') {
				setStatusClass('warning');
			} else if (status === 'trial') {
				setStatusClass('info');

				if (customer.HasSeenActivationOffer) {
					setShowActivationOfferIndicator(true);
					setOfferViewCount(customer.ActivationOfferViewCount);
				}
			} else if (status === 'signup') {
				setStatusClass('secondary');
			}

			if (customer.AgingInvoicesCount > 0) {
				setAgingInvoicesCount(customer.AgingInvoicesCount);
			} else {
				setAgingInvoicesCount(0);
			}
		},
		[ customer ]
	);

	const handleOnCreateTag = async (tagName) => {
		let data = {
			Guid : customer.Guid,
			Tag  : tagName
		};

		if (customer.Tags && customer.Tags.indexOf(tagName) > -1) {
			return;
		}

		const response = await AddTagToUser(data);

		if (response.IsSuccessful) {
			let tempTags = customer.Tags ? customer.Tags : new Array();
			tempTags.push(tagName);

			tempTags.sort((a, b) => (a > b ? 1 : -1));

			if (onTagsUpdate) {
				onTagsUpdate(customer, tempTags, tagName);
			}
		} else {
			toast.error(response.Message);
		}
	};

	const handleOnDeleteTag = async (tagName) => {
		let data = {
			Guid : customer.Guid,
			Tag  : tagName
		};

		const response = await RemoveTagFromUser(data);

		if (response.IsSuccessful) {
			let tempTags = customer.Tags ? customer.Tags : new Array();

			let tagIndex = tempTags.indexOf(tagName);
			if (tagIndex > -1) {
				tempTags.splice(tagIndex, 1);
			}

			if (onTagsUpdate) {
				onTagsUpdate(customer, tempTags);
			}
		} else {
			toast.error(response.Message);
		}
	};

	const toggleShowNotes = () => {
		setShowNotes((prev) => !prev);
	};

	const handleNoteUpdate = async (note, newText) => {
		if (!newText) {
			// this is a new note, call ws and create it
			let data = {
				Content      : note.Content,
				Guid         : customer.Guid,
				UserFullName : `${user.FirstName} ${user.LastName}`,
				DateCreated  : new Date()
			};

			const response = await AddUserNote(data);

			if (response.IsSuccessful) {
				data.Guid = new Date() / 100;

				let tempNotes = customer.Notes ? customer.Notes.slice() : new Array();

				if (tempNotes.length > 0) {
					tempNotes.splice(0, 0, data);
				} else {
					tempNotes.push(data);
				}

				if (onNotesUpdate) {
					onNotesUpdate(customer, tempNotes);
				}
			} else {
				toast.error(response.Message);
			}
		} else {
			// console.log('note updated:', note, newText);

			let data = {
				...note,
				Content : newText
			};

			const response = await UpdateUserNote(data);

			if (response.IsSuccessful) {
				let tempNotes = customer.Notes ? customer.Notes.slice() : new Array();

				if (tempNotes.length > 0) {
					let noteIndex = tempNotes.findIndex((x) => x.Guid === note.Guid);

					if (noteIndex > -1) {
						tempNotes.splice(noteIndex, 1, {
							...note,
							Content : newText
						});
					} else {
						toast.error('Could not update the note..');
						return;
					}
				} else {
					tempNotes.push(data);
				}

				if (onNotesUpdate) {
					onNotesUpdate(customer, tempNotes);
				}
			} else {
				toast.error(response.Message);
			}
		}
	};

	const handleNoteDelete = async (note) => {
		const response = await DeleteUserNote(note);

		if (response.IsSuccessful) {
			let tempNotes = customer.Notes ? customer.Notes.slice() : new Array();

			if (tempNotes.length > 0) {
				let noteIndex = tempNotes.findIndex((x) => x.Guid === note.Guid);

				if (noteIndex > -1) {
					tempNotes.splice(noteIndex, 1);
				} else {
					toast.error('Could not update the note..');
					return;
				}
			}

			if (onNotesUpdate) {
				onNotesUpdate(customer, tempNotes);
			}
		} else {
			toast.error(response.Message);
		}
	};

	const toggleShowWebsites = () => {
		setShowWebsites((prev) => !prev);
	};

	const handleLoginAs = async () => {
		setIsLoggingIn(true);

		let data = {
			UserGuid : customer.Guid
		};

		const response = await LoginIntoCustomerAccount(data);

		if (response.IsSuccessful) {
			let newUser = JSON.parse(response.Data);

			dispatch({
				type : actionTypes.SET_USER,
				user : newUser
			});

			toast.success(response.Message);

			history.push('/');
		} else {
			toast.error(response.Message);
		}

		setIsLoggingIn(false);
	};

	return (
		<Fragment>
			<div className={`user-list-view ${showWebsites ? 'show-websites' : ''}`}>
				<img
					className="brand-icon"
					src={customer.BrandFavIconUrl}
					alt={customer.BrandName}
					title={customer.BrandName}
				/>

				<div className="user-list-name">
					<div className="email">{customer.EmailAddress.toLowerCase()}</div>
					<div className="name text-muted">
						<span>
							{`${customer.FirstName ? customer.FirstName : ''} ${customer.LastName
								? customer.LastName
								: ''}`}
						</span>

						{/* {customer.FirstName && (
							<span
							>{`${customer.FirstName.toLowerCase()}} `}</span>
						)}

						{customer.LastName && (
							<span>{customer.LastName.toLowerCase()}</span>
						)} */}
					</div>
				</div>

				<div className="additional-details">
					<div className="user-tags desktop-only">
						<UserTags
							tags={customer.Tags}
							onCreate={handleOnCreateTag}
							onDelete={handleOnDeleteTag}
							tagsList={tagsList}
						/>
					</div>

					{customer.IsLegacyUser && (
						<div className="legacy-user desktop-only">
							<div className="alert alert-secondary">
								<span>Legacy User: {customer.LegacyUserId}</span>
							</div>
						</div>
					)}

					<div className="lead-indicators desktop-only">
						{agingInvoicesCount > 0 && (
							<Fragment>
								<i
									class="fas fa-receipt aging-invoices"
									title={`This customer has ${agingInvoicesCount} aging invoice(s)`}
								/>
							</Fragment>
						)}

						{showActivationOfferIndicator && (
							<Fragment>
								<i
									class="fa-solid fa-arrow-up activation-offer"
									title={`Customer saw the activation offer ${offerViewCount} time(s)`}
								/>
							</Fragment>
						)}
					</div>

					<button className="btn ml-2 desktop-only" onClick={toggleShowNotes}>
						{customer.Notes && customer.Notes.length > 0 ? (
							<Fragment>
								<i className="far fa-sticky-note" />
								<span>{customer.Notes.length}</span>
							</Fragment>
						) : (
							<Fragment>
								<i className="far fa-sticky-note m-0" />
							</Fragment>
						)}
					</button>

					<div className="user-details desktop-only">
						<i className="fas fa-address-book" />

						<div className="user-details-popup">
							<div className="user-detail-row">
								<div className="label">User Id:</div>
								<div className="value">{customer.Id}</div>
							</div>
							<div className="user-detail-row">
								<div className="label">User Guid:</div>
								<div className="value">{customer.Guid}</div>
							</div>
							<div className="user-detail-row">
								<div className="label">CPanel Id:</div>
								<div className="value">{customer.CPanelId}</div>
							</div>
						</div>
					</div>

					<div className="user-status">
						<div className={`alert alert-${statusClass}`}>
							{!customer.ForDelete ? (
								<Fragment>
									<span>{customer.Status}</span>
								</Fragment>
							) : (
								<Fragment>
									<span>Deleted</span>
								</Fragment>
							)}
						</div>
					</div>

					<div className="signup-date desktop-only">
						<i className="far fa-calendar-alt" />
						<span>{signupDate}</span>
					</div>
				</div>

				<div className="action">
					<Link to={`/administration/edit/${customer.Guid}`} className="btn btn-primary" title="Edit">
						<i className="fas fa-pencil-alt mr-0" />
						{/* <span>Edit</span> */}
					</Link>
					<button
						type="button"
						className="btn btn-primary desktop-only"
						onClick={handleLoginAs}
						disabled={isLoggingIn}
						title="Login As"
					>
						{!isLoggingIn ? (
							<Fragment>
								<i className="fas fa-user-circle mr-0" />
								{/* <span>Login As</span> */}
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
								{/* <span>Logging in...</span> */}
							</Fragment>
						)}
					</button>
					<button type="button" className="btn btn-primary" onClick={toggleShowWebsites} title="Websites">
						<i className="fas fa-database mr-0" />
						{/* <span>Websites</span> */}
					</button>
				</div>
			</div>

			{showNotes && (
				<Fragment>
					<UserNotes notes={customer.Notes} onUpdate={handleNoteUpdate} onDelete={handleNoteDelete} />
				</Fragment>
			)}

			{showWebsites && (
				<Fragment>
					<UserWebsitesList user={customer} />
				</Fragment>
			)}
		</Fragment>
	);
};

export default UserListView;
