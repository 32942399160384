import './BrandSendHistory.css';
import { Fragment, useState, useEffect } from 'react';
import Spinner from '../../../../spinner/Spinner';
import BrandSendMessageTabs from '../brandSendMessageTabs/BrandSendMessageTabs';
import { GetMessageHistory } from '../../../../Services';
import BrandHistoryList from './brandHistoryList/BrandHistoryList';
import { toast } from 'react-toastify';

const BrandSendHistory = ({ type }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ historyItems, setHistoryItems ] = useState(null);

	useEffect(() => {
		getMessageHistory(type);
	}, []);

	const getMessageHistory = async (type) => {
		let data = { Type: type };
		const response = await GetMessageHistory(data);

		if (response.Success) {
			setHistoryItems(response.Data);
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			<div className="container">{<BrandSendMessageTabs type={type} />}</div>
			<div className="container mb-3">
				<div className="rw-container shadow-large p-3">
					{!isLoading ? (
						<BrandHistoryList items={historyItems} />
					) : (
						<div className="text-center p-3">
							<Spinner />
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default BrandSendHistory;
