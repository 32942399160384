import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Logout } from '../../Services';
import Spinner from '../../spinner/Spinner';
import checkAnimation from '../lottiePlayer/animations/check.json';
import LottiePlayer from '../lottiePlayer/LottiePlayer';
import './AppLogout.css';

const AppLogout = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const history = useHistory();

	useEffect(() => {
		handleLogout();
	}, []);

	const handleRedirectToLogin = () => {
		history.push('/login');
	};

	const handleLogout = async () => {
		const response = await Logout();

		if (response.IsSuccessful) {
			localStorage.removeItem('rwau');
			setIsLoading(false);
		}
	};

	return (
		<div className="app-logout-container">
			{isLoading ? (
				<Fragment>
					<Spinner />
				</Fragment>
			) : (
				<Fragment>
					<LottiePlayer animation={checkAnimation} />
					<h3>Until next time!</h3>
					<p className="text-muted">
						You have been logged out successfully
					</p>

					<button
						type="button"
						className="btn btn-primary mt-3"
						onClick={handleRedirectToLogin}
					>
						<i className="fas fa-sign-in-alt" />
						<span>Click here to log in</span>
					</button>
				</Fragment>
			)}
		</div>
	);
};

export default AppLogout;
