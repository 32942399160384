import { Link } from 'react-router-dom';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import LottiePlayer from '../../lottiePlayer/LottiePlayer';
import paymentAnimation from '../../lottiePlayer/animations/payment.json';
import './Payments.css';

const Payments = () => {
	return (
		<div className="app-payments panel">
			<CenteredContainer>
				<div className="container">
					<LottiePlayer animation={paymentAnimation} width={250} height={250} loop={true} />
					<div className="app-feature-title">Coming Soon!</div>
					<h3>Collect payments straight from your website.</h3>
				</div>
			</CenteredContainer>
		</div>
	);
};

export default Payments;
