const FreeDomain = ({ domain, onSelect }) => {
	const handleSelect = () => {
		if (onSelect) {
			onSelect(domain);
		}
	};

	return (
		<div className="rw-row">
			<div className="rw-td name">{domain.DomainName.toLowerCase()}</div>
			<div className="rw-td action">
				<button type="button" className="btn btn-primary" onClick={handleSelect}>
					<i className="fas fa-gift" />
					<span>Apply</span>
				</button>
			</div>
		</div>
	);
};

export default FreeDomain;
