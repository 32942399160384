import './CancelledBanner.css';

const CancelledBanner = () => {
	const handleClick = () => {
		window.location = window.location.origin + '/reactivate';
	};

	return (
		<div className="container-fluid text-center app-cancelled-banner" onClick={handleClick}>
			<h3>
				Your Account is currently cancelled. <u>Click Here</u> to reactivate your account.
			</h3>
		</div>
	);
};

export default CancelledBanner;
