import { useHistory, useParams } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';

const MakeItLivePrivacy = () => {
	const [ { makeItLiveSettings }, dispatch ] = useStateValue();
	const { guid } = useParams();
	const history = useHistory();

	const handleYes = () => {
		let tempSettings = makeItLiveSettings;
		tempSettings.IsPrivateRegistration = true;

		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : { ...tempSettings }
		});

		history.push(`/make-it-live/${guid}/auto-renew`);
	};

	const handleNo = () => {
		let tempSettings = makeItLiveSettings;
		tempSettings.IsPrivateRegistration = false;

		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : { ...tempSettings }
		});

		history.push(`/make-it-live/${guid}/auto-renew`);
	};

	return (
		<div className="wizard-privacy wizard-step">
			<h3>Would you like to enable privacy on your domain?</h3>
			<p className="text-muted">
				Domain privacy protects your information from the public. If you do not choose to enable privacy your
				information will be visible via a &quot;whois&quot; query.
			</p>

			<div className="buttons-container">
				<button type="button" className="btn btn-primary" onClick={handleYes}>
					<i className="fas fa-check" />
					<span>Yes, Enable Privacy</span>
				</button>

				<button type="button" className="btn btn-outline-primary" onClick={handleNo}>
					<i className="fas fa-times" />
					<span>No, Thank You</span>
				</button>
			</div>
		</div>
	);
};

export default MakeItLivePrivacy;
