import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../../../StateProvider';
import ActionsBar from '../../../actionsBar/ActionsBar';
import './CrmWebhookInstructions.css';

const CrmWebhookInstructions = () => {
	const [ { brand, user }, dispatch ] = useStateValue();
	const history = useHistory();

	return (
		<div className="app-crm-webhook-instructions panel">
			<div className="container">
				<ActionsBar>
					<button
						type="button"
						className="btn btn-outline-primary"
						onClick={() => history.push('/crm/webhooks')}
					>
						<span>Back to Webhooks</span>
					</button>
				</ActionsBar>

				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>Incoming Webhook Instructions</span>
				</h5>

				<p>
					To be able to submit leads into your account from external
					applications you need to send an HTTP POST request to the
					below displayed URL:
				</p>
				<div className="alert alert-info">
					<span
					>{`https://app.${brand.BrandUrl}/leads/create/${user.Guid}`}</span>
				</div>

				<div className="rw-container shadow-large webhook-parameters-list">
					<div className="form-inputs">
						<table className="table">
							<thead>
								<tr>
									<th>Parameter Name</th>
									<th>Required</th>
									<th>Value Type</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Email</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>FirstName</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>LastName</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>FullName</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>Phone</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>Mobile</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>OtherPhone</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>Fax</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>CompanyName</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>SecondaryEmail</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>MailingAddress</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>MailingCity</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>MailingState</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>MailingZip</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>MailingCountry</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>Description</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>Notes</td>
									<td>no</td>
									<td>string</td>
								</tr>
								<tr>
									<td>LeadType</td>
									<td>no</td>
									<td>
										string that says Form, Call or another 1
										word description on how this lead was
										collected
									</td>
								</tr>
							</tbody>
						</table>

						<div className="alert alert-secondary">
							The system will also accept any other form fields
							you send over and store them as custom fields
							associated with the lead. These will be displayed
							under the "Other Data" section of lead details
							screen.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CrmWebhookInstructions;
