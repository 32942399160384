import { Fragment } from 'react';
import Spinner from '../../spinner/Spinner';
import './Search.css';

const Search = ({ searchTerm, handleChange, placeholder, buttonText, submitEvent, isDisabled }) => {
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (submitEvent) {
			submitEvent(e);
		}
	};

	return (
		<div className="search-box-container">
			<div className="search-box">
				<form onSubmit={handleSubmit}>
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<i class="fas fa-search" />
							</span>
						</div>
						<input
							type="text"
							className="form-control form-control-lg"
							placeholder={placeholder}
							value={searchTerm}
							onChange={handleChange}
							aria-label={placeholder}
							disabled={isDisabled}
						/>
						{buttonText && (
							<div className="input-group-append">
								<button type="submit" className="btn btn-primary" disabled={isDisabled}>
									{!isDisabled ? (
										<Fragment>
											<i class="fas fa-search" />
											<span>{buttonText}</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Searching</span>
										</Fragment>
									)}
								</button>
							</div>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default Search;
