import { Fragment, useEffect, useState } from 'react';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import Spinner from '../../../../../spinner/Spinner';
import './UserWebsitesList.css';
import { GetUserWebsitesAdmin } from '../../../../../Services';
import NoDataMessage from '../../../../noDataMessage/NoDataMessage';
import UserWebsite from './userWebsite/UserWebsite';
import UserWebsiteV2 from './userWebsiteV2/UserWebsiteV2';

const UserWebsitesList = ({ user }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ websites, setWebsites ] = useState([]);

	useEffect(() => {
		if (user) {
			getWebsitesList();
		}
	}, []);

	const getWebsitesList = async () => {
		let data = {
			UserGuid : user.Guid
		};

		const response = await GetUserWebsitesAdmin(data);

		if (response.IsSuccessful) {
			setWebsites(JSON.parse(response.Data));
		} else {
			setWebsites(new Array());
		}

		setIsLoading(false);
	};

	return (
		<div className="rw-container shadow-large user-websites">
			{!isLoading ? (
				<Fragment>
					{websites && websites.length > 0 ? (
						<Fragment>
							<div className="rw-table">
								<div className="rw-table-header">
									<div className="rw-th name">Name</div>
									<div className="rw-th support-id">Support Id</div>
									<div className="rw-th under-construction">Under Construction</div>
									<div className="rw-th action" />

									{/* {user.IsAdmin && (
										<Fragment>
											<div className="rw-th action" />
										</Fragment>
									)} */}
								</div>

								{websites.map((website) => (
									<UserWebsiteV2 key={website.Guid} website={website} user={user} />
								))}
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className="text-center">
								<NoDataMessage message="No websites found" />
							</div>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Fragment>
					<div className="container text-center loading">
						<Spinner />
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default UserWebsitesList;
