import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import {
	ChangeUserSpamFilterStatus,
	DeleteUserSpamFilter
} from '../../../../../Services';
import EditSpamFilterModal from '../editSpamFilterModal/EditSpamFilterModal';
import './SpamFilterListItem.css';

const SpamFilterListItem = ({ filter, onUpdate }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ showEditFilterDialog, setShowEditFilterDialog ] = useState(false);

	const handleStatusChange = async () => {
		setIsSaving(true);

		let data = {
			...filter,
			IsActive : !filter.IsActive
		};

		const response = await ChangeUserSpamFilterStatus(data);

		if (response.IsSuccessful) {
			toast.success('Updated successfully!');

			if (onUpdate) {
				onUpdate();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleDelete = async () => {
		setIsSaving(true);

		let data = {
			...filter
		};

		const response = await DeleteUserSpamFilter(data);

		if (response.IsSuccessful) {
			toast.success('Deleted successfully!');

			if (onUpdate) {
				onUpdate();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const toggleEditFilterDialog = () => {
		setShowEditFilterDialog((prev) => !prev);
	};

	const handleUpdate = () => {
		if (onUpdate) {
			onUpdate();
		}

		toggleEditFilterDialog();
	};

	return (
		<Fragment>
			<div
				className={`spam-filter-list-item ${filter.IsActive
					? ' active'
					: ''}`}
			>
				<div className="indicator">
					<i className="fa-solid fa-lock" />
					{filter.IsActive && (
						<div>
							<small>
								<strong>Active</strong>
							</small>
						</div>
					)}
				</div>
				<div className="content">
					<div className="term">
						{`Monitoring for the term "${filter.FieldFilter}"`}
					</div>
					<small className="fields">
						{`on ${filter.FieldName === '*'
							? 'all input fields.'
							: `the ${filter.FieldName} input field.`}`}
					</small>
				</div>
				<div className="actions">
					<button
						type="button"
						className="btn btn-outline-primary"
						onClick={handleStatusChange}
						disabled={isSaving}
					>
						{filter.IsActive ? (
							<span>Turn Off</span>
						) : (
							<span>Turn On</span>
						)}
					</button>
					<button
						type="button"
						className="btn btn-primary"
						onClick={toggleEditFilterDialog}
						disabled={isSaving}
					>
						<i className="fa-solid fa-pen" />
					</button>
					<button
						type="button"
						className="btn btn-danger"
						onClick={handleDelete}
						disabled={isSaving}
					>
						<i className="fa-regular fa-trash-can" />
					</button>
				</div>
			</div>

			{showEditFilterDialog && (
				<EditSpamFilterModal
					filter={filter}
					onCancel={toggleEditFilterDialog}
					onUpdate={handleUpdate}
				/>
			)}
		</Fragment>
	);
};

export default SpamFilterListItem;
