import { Fragment, useEffect, useState } from 'react';
import { SetDefaultCreditCard } from '../../../../../Services';
import ccCirrus from '../../../../../img/cirrus.png';
import ccAmex from '../../../../../img/amex.png';
import ccDiscover from '../../../../../img/discover.png';
import ccMaestro from '../../../../../img/maestro.png';
import ccMastercard from '../../../../../img/mastercard.png';
import ccVisa from '../../../../../img/visa.png';
import './AccountPaymentSource.css';
import { toast } from 'react-toastify';
import Spinner from '../../../../../spinner/Spinner';

const AccountPaymentSource = ({ card, onUpdate }) => {
	const [ cardType, setCardType ] = useState(null);
	const [ isSaving, setIsSaving ] = useState(false);

	useEffect(() => {
		const ccType = card.CardType.toLowerCase();

		if (ccType === 'visa') {
			setCardType(ccVisa);
		} else if (ccType === 'mastercard') {
			setCardType(ccMastercard);
		} else if (ccType === 'americanexpress') {
			setCardType(ccAmex);
		} else if (ccType === 'discover') {
			setCardType(ccDiscover);
		} else if (ccType === 'maestro') {
			setCardType(ccMaestro);
		} else if (ccType === 'cirrus') {
			setCardType(ccCirrus);
		}
	}, []);

	const handleMakeDefault = async () => {
		setIsSaving(true);

		let data = {
			Guid : card.Guid
		};

		const response = await SetDefaultCreditCard(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onUpdate) {
				onUpdate();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<div className="rw-cc">
			{cardType && (
				<div className="rw-cc-icon">
					<img src={cardType} alt="Credit Card Icon" />
				</div>
			)}

			<div className="rw-cc-details">
				<div className="rw-cc-numbers">
					{window.innerWidth > 767 ? (
						<Fragment>
							<span>**** {card.CardDigits}</span>
						</Fragment>
					) : (
						<Fragment>
							<span>{card.CardDigits}</span>
						</Fragment>
					)}
				</div>
				<div className="expiry text-muted">
					{card.ExpireMonth} / {card.ExpireYear}
				</div>
			</div>

			{!card.IsDefaultCard ? (
				<div className="rw-cc-actions">
					<button className="btn btn-outline-primary" disabled={isSaving} onClick={handleMakeDefault}>
						{!isSaving ? (
							<Fragment>
								<i className="far fa-credit-card" />
								<span>Make Default</span>
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
							</Fragment>
						)}
					</button>
					{/* TODO: add  */}
				</div>
			) : (
				<div className="rw-cc-default-card">
					<div className="alert alert-success">Default Card</div>
				</div>
			)}
		</div>
	);
};

export default AccountPaymentSource;
