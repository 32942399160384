import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../../StateProvider';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogFullScreen from '../../modalDialog/ModalDialogFullScreen';
import './WelcomeVideo.css';

const WelcomeVideo = ({ onClose }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ welcomeVideoHtml, setWelcomeVideoHtml ] = useState('');

	useEffect(
		() => {
			if (user) {
				if (
					user.Group &&
					user.Group.WelcomeVideoHtml &&
					user.Group.WelcomeVideoHtml !== ''
				) {
					setWelcomeVideoHtml(user.Group.WelcomeVideoHtml);
				}
			}
		},
		[ user ]
	);

	const handleClose = () => {
		localStorage.setItem('rwwv', '1');

		if (onClose) {
			onClose();
		}
	};

	return (
		<Fragment>
			<ModalDialogFullScreen>
				<div
					className="video-container"
					dangerouslySetInnerHTML={{ __html: welcomeVideoHtml }}
				/>
				<ModalDialogButtons shaded>
					<button
						type="button"
						className="btn btn-primary"
						onClick={handleClose}
					>
						<span>Close</span>
					</button>
				</ModalDialogButtons>
			</ModalDialogFullScreen>
		</Fragment>
	);
};

export default WelcomeVideo;
