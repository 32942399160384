import { Fragment, useState } from 'react';
import Spinner from '../../../../spinner/Spinner';
import NoDataMessage from '../../../noDataMessage/NoDataMessage';
import EditBrandWebhook from '../editBrandWebhook/EditBrandWebhook';
import BrandWebhookListItem from './brandWebhookListItem/BrandWebhookListItem';
import './BrandWebhooksList.css';

const BrandWebhooksList = ({ webhooks, webhookType, isLoading, onUpdate }) => {
    const [selectedWebhook, setSelectedWebhook] = useState();
    const [showEditModal, setShowEditModal] = useState(false);

    const handleWebhookUpdate = () => {
        toggleEditModal();
        if (onUpdate) {
            onUpdate();
        }
    }

    const onAddNew = () => {
        setSelectedWebhook(null);
        toggleEditModal();
    }

    const toggleEditModal = () => {
        setShowEditModal((prev) => !prev);
    }

    const handleEdit = (webhook) => {
        setSelectedWebhook(webhook);
        toggleEditModal();
    }

    const handleDelete = () => {
        if (onUpdate) {
            onUpdate();
        }
    }

    return (
        <Fragment>
            <div className="rw-container shadow-large webhooks-list">
                {!isLoading ? (
                    <Fragment>
                        {webhooks && webhooks.length > 0 ? (
                            <Fragment>
                                {webhooks.map(webhook => (
                                    <Fragment>
                                        <BrandWebhookListItem key={webhook.Guid} webhook={webhook} onEdit={handleEdit} onDelete={handleDelete} />
                                    </Fragment>
                                ))}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <NoDataMessage message="You currently have no webhooks of this type" />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="text-center p-3">
                            <Spinner />
                        </div>
                    </Fragment>
                )}

                <div className="rw-container-buttons">
                    <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={onAddNew}>
                        <i class="fas fa-cloud-upload-alt" />
                        <span>Add New</span>
                    </button>
                </div>
            </div>

            {showEditModal && (
                <Fragment>
                    <EditBrandWebhook webhookType={webhookType} webhook={selectedWebhook} onCancel={toggleEditModal} onUpdate={handleWebhookUpdate} />
                </Fragment>
            )}
        </Fragment>
    )
}

export default BrandWebhooksList;