import { Link } from 'react-router-dom';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import LottiePlayer from '../../lottiePlayer/LottiePlayer';
import trainingAnimation from '../../lottiePlayer/animations/training.json';
import './Training.css';
import { useStateValue } from '../../../StateProvider';

const Training = () => {
	const [ { brand }, dispatch ] = useStateValue();

	return (
		<div className="app-training panel">
			<CenteredContainer>
				<div className="container">
					<LottiePlayer animation={trainingAnimation} width={250} height={250} loop={false} />
					<div className="app-feature-title">Coming Soon!</div>
					<h3>{`Check back here soon for access to ${brand.BrandName} Training`}</h3>
				</div>
			</CenteredContainer>
		</div>
	);
};

export default Training;
