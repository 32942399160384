import { Fragment } from 'react';

const CartPurchaseSummaryItem = ({ item }) => {
	return (
		<div className="rw-row">
			<div className="rw-td description">
				<span>{item.DomainName.toLowerCase()}</span>
			</div>

			<div className="rw-td status">
				{item.IsSuccessful ? (
					<Fragment>
						<div className="alert alert-success m-0">
							<i className="fas fa-check" />
							<span>Registered</span>
						</div>
					</Fragment>
				) : (
					<Fragment>
						<div className="alert alert-danger m-0" title={item.Message}>
							<i className="fas fa-exclamation-triangle" />
							<span>Failed</span>
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default CartPurchaseSummaryItem;
