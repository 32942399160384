import './UserTag.css';

const UserTag = ({ tag, onDelete }) => {
	const handleDelete = () => {
		if (onDelete) {
			onDelete(tag);
		}
	};

	return (
		<div className="user-tag">
			<span>{tag}</span>
			<button type="button" className="btn-delete" onClick={handleDelete}>
				<i className="far fa-trash-alt" />
			</button>
		</div>
	);
};

export default UserTag;
