import { useEffect, useState } from 'react';
import { useStateValue } from '../../../../../../StateProvider';
import './WebsiteActions.css';

const WebsiteActions = ({
	saveAsPreconfigured,
	deleteWebsite,
	cloneWebsite,
	refreshWebsiteData,
	isRefreshingData,
	isLoading,
	toggleTrialDialog
}) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ cssSpinClass, setCssSpinClass ] = useState('');

	useEffect(
		() => {
			if (isRefreshingData) {
				setCssSpinClass('fa-spin');
			} else {
				setCssSpinClass('');
			}
		},
		[ isRefreshingData ]
	);

	const handleSaveAsPreconfiguredClick = (e) => {
		if (user) {
			if (user.Status === 'Active') {
				if (saveAsPreconfigured) {
					saveAsPreconfigured();
				}
			} else if (user.Status === 'Trial') {
				if (toggleTrialDialog) {
					toggleTrialDialog();
				}
			}
		}
	};

	const handleCloneWebsiteClick = (e) => {
		if (user) {
			if (user.Status === 'Active') {
				if (cloneWebsite) {
					cloneWebsite();
				}
			} else if (user.Status === 'Trial') {
				if (toggleTrialDialog) {
					toggleTrialDialog();
				}
			}
		}
	};

	const handleDeleteWebsiteClick = (e) => {
		if (user) {
			if (user.Status === 'Active') {
				if (deleteWebsite) {
					deleteWebsite();
				}
			} else if (user.Status === 'Trial') {
				if (toggleTrialDialog) {
					toggleTrialDialog();
				}
			}
		}
	};

	const handleRefreshDataClick = (e) => {
		if (user) {
			if (user.Status === 'Active') {
				if (refreshWebsiteData) {
					refreshWebsiteData();
				}
			} else if (user.Status === 'Trial') {
				if (toggleTrialDialog) {
					toggleTrialDialog();
				}
			}
		}
	};

	return (
		<div className="website-actions">
			<div className="left-actions-menu actions-menu" />
			<div className="right-actions-menu actions-menu">
				<button
					className="btn btn-refresh"
					title="Refresh"
					onClick={handleRefreshDataClick}
					disabled={isLoading}
				>
					<i className={`fas fa-sync-alt ${cssSpinClass}`} />
				</button>
				<button
					className="btn btn-preconfig"
					title="Save as a Preconfigured Website"
					onClick={handleSaveAsPreconfiguredClick}
					disabled={isLoading}
				>
					<i className="fa-regular fa-floppy-disk" />
				</button>
				<button
					className="btn btn-clone"
					title="Clone this Website"
					onClick={handleCloneWebsiteClick}
					disabled={isLoading}
				>
					<i className="far fa-clone" />
				</button>
				<button
					className="btn btn-delete"
					title="Delete this Website"
					onClick={handleDeleteWebsiteClick}
					disabled={isLoading}
				>
					<i className="far fa-trash-alt" />
				</button>
			</div>
		</div>
	);
};

export default WebsiteActions;
