import './ContextMenu.css';

const ContextMenu = ({ children, title = 'Bulk Actions' }) => {
	return (
		<div className="app-context-menu">
			{/* <div className="app-context-menu-title">{title}</div> */}
			<div className="app-context-menu-content">{children}</div>
		</div>
	);
};

export default ContextMenu;
