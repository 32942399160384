import { NavLink } from 'react-router-dom';
import './CrmNavigation.css';

const CrmNavigation = () => {
	return (
		<div className="container mb-3">
			<ul className="nav nav-tabs app-tabs tabs-crm">
				<li className="nav-item">
					<NavLink
						to="/crm"
						exact={true}
						className="nav-link"
						activeClassName="active"
					>
						<i className="fas fa-chalkboard-teacher" />
						<span>Leads</span>
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink
						to="/crm/archive"
						exact={true}
						className="nav-link"
						activeClassName="active"
					>
						<i className="fa-solid fa-box-archive" />
						<span>Archive</span>
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink
						to="/crm/spam"
						exact={true}
						className="nav-link"
						activeClassName="active"
					>
						<i className="fa-solid fa-robot" />
						<span>Spam</span>
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink
						to="/crm/filters"
						exact={true}
						className="nav-link"
						activeClassName="active"
					>
						<i className="fa-solid fa-lock" />
						<span>Spam Filters</span>
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink
						to="/crm/trash"
						exact={true}
						className="nav-link"
						activeClassName="active"
					>
						<i className="far fa-trash-alt" />
						<span>Trash</span>
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink
						to="/crm/webhooks"
						exact={true}
						className="nav-link"
						activeClassName="active"
					>
						<i className="fa-solid fa-route" />
						<span>Webhooks</span>
					</NavLink>
				</li>
			</ul>
		</div>
	);
};

export default CrmNavigation;
