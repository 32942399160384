import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../../spinner/Spinner';
import AppTermsAndConditions from '../../appTermsAndConditions/AppTermsAndConditions';

const ViewInvoiceCardInput = ({
	onSave,
	onCancel,
	isSaving,
	requireTermsAgreement,
	hideBackButton
}) => {
	const [ cardData, setCardData ] = useState({});
	const [ years, setYears ] = useState([]);
	const [ showTermsDialog, setShowTermsDialog ] = useState(false);

	useEffect(() => {
		let currentYear = new Date().getFullYear();
		let tempYears = new Array();

		for (let i = currentYear; i < currentYear + 11; i++) {
			tempYears.push(i);
		}

		setYears(tempYears);

		setCardData({
			...cardData,
			['ExpiryMonth'] : '1',
			['ExpiryYear']  : tempYears[0]
		});
	}, []);

	const handleChange = (e) => {
		setCardData({
			...cardData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!cardData.CardNumbers || cardData.CardNumbers.trim() === '') {
			toast.error('Card Numbers are required');
			return;
		}

		if (!cardData.ExpiryMonth || cardData.ExpiryMonth.trim() === '') {
			toast.error('Expiry month is required');
			return;
		}

		if (!cardData.CVC || cardData.CVC.trim() === '') {
			toast.error('CVC is required');
			return;
		}

		if (!cardData.ZIP || cardData.ZIP.trim() === '') {
			toast.error('CVC is required');
			return;
		}

		let data = {
			CardNumbers : cardData.CardNumbers,
			ExpireMonth : parseInt(cardData.ExpiryMonth),
			ExpireYear  : parseInt(cardData.ExpiryYear),
			CVC         : cardData.CVC,
			ZIP         : cardData.ZIP,
			CardName    : ''
		};

		if (onSave) {
			onSave(data);
		}
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const toggleTermsDialog = () => {
		setShowTermsDialog((prev) => !prev);
	};

	return (
		<div className="card-input">
			<form onSubmit={handleSubmit}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="form-group">
								<label
									htmlFor="cardNumbers"
									className="col-form-label"
								>
									Card Numbers:
								</label>
								<input
									onChange={handleChange}
									value={cardData.CardNumbers}
									id="cardNumbers"
									name="CardNumbers"
									type="text"
									className="form-control form-control-lg"
									placeholder="XXXX XXXX XXXX XXXX"
									disabled={isSaving}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-6">
							<div className="form-group">
								<label
									htmlFor="expiryMonth"
									className="col-form-label"
								>
									Expiry Month:
								</label>
								<select
									onChange={handleChange}
									value={cardData.ExpiryMonth}
									id="expiryMonth"
									name="ExpiryMonth"
									className="form-control form-control-lg"
									disabled={isSaving}
								>
									<option value="1">01 - January</option>
									<option value="2">02 - February</option>
									<option value="3">03 - March</option>
									<option value="4">04 - April</option>
									<option value="5">05 - May</option>
									<option value="6">06 - June</option>
									<option value="7">07 - July</option>
									<option value="8">08 - August</option>
									<option value="9">09 - September</option>
									<option value="10">10 - October</option>
									<option value="11">11 - November</option>
									<option value="12">12 - December</option>
								</select>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="form-group">
								<label
									htmlFor="expiryYear"
									className="col-form-label"
								>
									Expiry Year:
								</label>
								<select
									onChange={handleChange}
									value={cardData.ExpiryYear}
									id="expiryYear"
									name="ExpiryYear"
									className="form-control form-control-lg"
									disabled={isSaving}
								>
									{years.length > 0 && (
										<Fragment>
											{years.map((year) => (
												<option key={year} value={year}>
													{year}
												</option>
											))}
										</Fragment>
									)}
								</select>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-6">
							<div className="form-group">
								<label htmlFor="cvc" className="col-form-label">
									CVC:
								</label>
								<input
									onChange={handleChange}
									value={cardData.CVC}
									id="cvc"
									name="CVC"
									type="text"
									className="form-control form-control-lg"
									placeholder="CVC"
									disabled={isSaving}
								/>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="form-group">
								<label htmlFor="zip" className="col-form-label">
									Billing ZIP:
								</label>
								<input
									onChange={handleChange}
									value={cardData.ZIP}
									id="zip"
									name="ZIP"
									type="text"
									className="form-control form-control-lg"
									placeholder="Billing ZIP"
									disabled={isSaving}
								/>
							</div>
						</div>
					</div>

					{requireTermsAgreement && (
						<Fragment>
							<div className="alert alert-warning mb-3">
								<small>
									By clicking on "Pay Now" you agree to our{' '}
									<a
										onClick={toggleTermsDialog}
										className="app-link"
									>
										Terms and Conditions
									</a>
								</small>
							</div>

							{showTermsDialog && (
								<AppTermsAndConditions
									onClose={toggleTermsDialog}
								/>
							)}
						</Fragment>
					)}

					<div className="row buttons-container mt-3">
						<div className="col-sm-12 text-center">
							<button
								type="submit"
								className="btn btn-primary btn-block"
								disabled={isSaving}
							>
								{!isSaving ? (
									<Fragment>
										<span>Pay Now</span>
									</Fragment>
								) : (
									<Fragment>
										<Spinner />
										<span>Paying...</span>
									</Fragment>
								)}
							</button>
							{!hideBackButton && (
								<button
									type="button"
									className="btn btn-link mt-3"
									disabled={isSaving}
									onClick={handleCancel}
								>
									<span>Back</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ViewInvoiceCardInput;
