import { useEffect } from 'react';
import './TabContent.css';

const TabContent = ({ tab }) => {
	useEffect(
		() => {
			// console.log('tab:', tab);
		},
		[ tab ]
	);

	return (
		<div
			className="app-brand-tab-content container"
			dangerouslySetInnerHTML={{ __html: tab.Content }}
		/>
	);
};

export default TabContent;
