import { Fragment, useEffect, useRef, useState } from 'react';
import './UserNote.css';

const UserNote = ({ note, onUpdate, onDelete }) => {
	const [ isUpdating, setIsUpdating ] = useState(false);
	const [ isEditMode, setIsEditMode ] = useState(false);
	const [ noteText, setNoteText ] = useState('');
	const [ originalNoteText, setOriginalNoteText ] = useState('');
	const [ noteDate, setNoteDate ] = useState(null);
	const noteRef = useRef(null);

	useEffect(
		() => {
			if (note && note.Content) {
				setNoteText(note.Content);

				if (note.DateCreatedUnix) {
					setNoteDate(new Date(note.DateCreatedUnix));
				} else {
					setNoteDate(Date.parse(note.DateCreated));
				}
			} else {
				setNoteText('');
			}
		},
		[ note ]
	);

	useEffect(
		() => {
			if (isEditMode) {
				// set focus on input element when the edit mode is enabled
				noteRef.current.focus();
			}
		},
		[ isEditMode ]
	);

	const handleChange = (e) => {
		setNoteText(e.target.value);
	};

	const handleNoteClick = (e) => {
		if (!isEditMode) {
			setOriginalNoteText(noteText);
			setIsEditMode(true);
		}
	};

	const handleNoteBlur = async () => {
		if (isEditMode) {
			if (originalNoteText !== noteText.trim()) {
				// text was updated, update it and trigger update function
				setIsUpdating(true);

				const newText = noteText.trim();

				if (newText === '') {
					if (onDelete) {
						onDelete(note);
					}
				} else if (onUpdate) {
					onUpdate(note, newText);
					setIsEditMode(false);
				}

				setIsUpdating(false);
			} else {
				setOriginalNoteText('');
				setIsEditMode(false);
			}
		}
	};

	return (
		<div className="user-note">
			<textarea
				ref={noteRef}
				className="form-control form-control-lg note-control"
				rows={4}
				value={noteText}
				readOnly={!isEditMode || isUpdating}
				onChange={handleChange}
				onClick={handleNoteClick}
				onBlur={handleNoteBlur}
			/>

			<div className="user-note-posted-by">
				<span className="text-muted">
					Posted {` `}
					{note.UserFullName && (
						<Fragment>
							by{' '}
							<strong>
								{note.UserFullName} {` `}
							</strong>
						</Fragment>
					)}
					{noteDate && (
						<Fragment>
							at{' '}
							{new Intl.DateTimeFormat('en-US', {
								month  : 'numeric',
								year   : 'numeric',
								day    : 'numeric',
								hour   : 'numeric',
								minute : 'numeric',
								hour12 : true
							}).format(noteDate)}
						</Fragment>
					)}
				</span>
			</div>
		</div>
	);
};

export default UserNote;
