import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteCartItem, SetDomainPrivacy } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import './CartItemV2.css';

const CartItemV2 = ({ item, onItemChange, onPrivacyChange, onDelete }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ showDeleteConfirm, setShowDeleteConfirm ] = useState(false);
	const [ localCartItem, setLocalCartItem ] = useState(null);
	const [ lengthLabel, setLengthLabel ] = useState('year');
	const [ selectedClassName, setSelectedClassName ] = useState('');
	const [ isDeleting, setIsDeleting ] = useState(false);

	useEffect(
		() => {
			if (!localCartItem || localCartItem !== item) {
				setLocalCartItem(item);
			}
		},
		[ item ]
	);

	const handleChange = async (e) => {
		let tempLocalCartItem = localCartItem;
		if (e.target.name === 'IsPrivateRegistration') {
			setIsSaving(true);
			let data = {
				Id    : item.Id,
				State : e.target.checked
			};

			const response = await SetDomainPrivacy(data);

			if (response.IsSuccessful) {
				let yearPrice = localCartItem.YearlyPrice;
				let privacyPrice = 10;

				if (data.State) {
					yearPrice += privacyPrice;
				}

				let termPrice = localCartItem.Duration * yearPrice;

				tempLocalCartItem.TermPrice = termPrice;
			} else {
				toast.error(response.Message);
			}

			setIsSaving(false);
		}

		if (typeof e.target.checked != 'undefined') {
			tempLocalCartItem = {
				...tempLocalCartItem,
				[e.target.name]: e.target.checked
			};
			setLocalCartItem(tempLocalCartItem);
		} else {
			tempLocalCartItem = {
				...tempLocalCartItem,
				[e.target.name]: e.target.value
			};
			setLocalCartItem(tempLocalCartItem);
		}
	};

	useEffect(
		() => {
			if (localCartItem && localCartItem !== item) {
				if (localCartItem.Duration > 1) {
					setLengthLabel('years');
				} else {
					setLengthLabel('year');
				}

				if (onItemChange) {
					onItemChange(localCartItem);
				}
			}

			if (localCartItem) {
				if (localCartItem.Selected) {
					setSelectedClassName('selected');
				} else {
					setSelectedClassName('');
				}
			}
		},
		[ localCartItem ]
	);

	const toggleDeleteConfirmation = () => {
		setShowDeleteConfirm((prev) => !prev);
	};

	const handleDelete = async () => {
		setIsDeleting(true);

		let data = {
			Id : item.Id
		};

		const response = await DeleteCartItem(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onDelete) {
				onDelete();
			}

			setIsDeleting(false);
		} else {
			toast.error(response.Message);
			setIsDeleting(false);
		}
	};

	return (
		<Fragment>
			{localCartItem && (
				<Fragment>
					<div className={`cart-item ${selectedClassName}`}>
						<div className="check">
							<input
								id={`chkSelected${localCartItem.Id}`}
								type="checkbox"
								className="round"
								name="Selected"
								disabled={isSaving || isDeleting}
								defaultChecked={localCartItem.Selected}
								onChange={handleChange}
							/>
							<label htmlFor={`chkSelected${localCartItem.Id}`} />
						</div>
						<div className="cart-item-details">
							<div className="name">
								{localCartItem.DomainName.toLowerCase()}
							</div>
							{localCartItem.IsPrivateRegistration ? (
								<Fragment>
									<div className="text-muted">
										{localCartItem.Duration} {lengthLabel} x
										${(localCartItem.YearlyPrice +
											10).toFixed(2)}
									</div>
								</Fragment>
							) : (
								<Fragment>
									<div className="text-muted">
										{localCartItem.Duration} {lengthLabel} x
										${localCartItem.YearlyPrice.toFixed(2)}
									</div>
								</Fragment>
							)}
							{/*<div className="privacy">
								<input
									id={`chkPrivacy${localCartItem.Id}`}
									type="checkbox"
									className="toggle-pill-color"
									name="IsPrivateRegistration"
									disabled={isSaving || isDeleting}
									defaultChecked={
										localCartItem.IsPrivateRegistration
									}
									onChange={handleChange}
								/>
								<label
									htmlFor={`chkPrivacy${localCartItem.Id}`}
								/>

								 {localCartItem.IsPrivateRegistration ? (
									<div
										className="privacy-state on"
										title="Privacy protects your information from being publicly visible."
									>
										Privacy On
									</div>
								) : (
									<div
										className="privacy-state danger"
										title="Privacy protects your information from being publicly visible."
									>
										No Privacy
									</div>
								)}
							</div>
							*/}
						</div>
						{/* <div className="rw-td price">
							<span>${localCartItem.TermPrice.toFixed(2)}</span>
						</div> */}
						<div className="action">
							<button
								type="button"
								className="btn btn-danger"
								disabled={isSaving || isDeleting}
								title="Delete this item"
								onClick={toggleDeleteConfirmation}
							>
								{!isDeleting ? (
									<i className="far fa-trash-alt" />
								) : (
									<Spinner />
								)}
							</button>
						</div>
					</div>

					{showDeleteConfirm && (
						<ModalDialog>
							<ModalDialogContent>
								<h5>
									Are you sure you want to delete this cart
									item?
								</h5>
							</ModalDialogContent>
							<ModalDialogButtons shaded>
								<button
									type="button"
									className="btn btn-danger"
									disabled={isSaving || isDeleting}
									onClick={handleDelete}
								>
									{!isDeleting ? (
										<Fragment>
											<i className="far fa-trash-alt" />
											<span>Delete</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Deleting...</span>
										</Fragment>
									)}
								</button>

								<button
									type="button"
									className="btn btn-outline-primary"
									onClick={toggleDeleteConfirmation}
									disabled={isSaving || isDeleting}
								>
									<i className="fas fa-chevron-left" />
									<span>Cancel</span>
								</button>
							</ModalDialogButtons>
						</ModalDialog>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default CartItemV2;
