import { useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteBrandWebhook } from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import './BrandWebhookListItem.css';

const BrandWebhookListItem = ({ webhook, onEdit, onDelete }) => {
    const [isUpdating, setIsUpdating] = useState(false);

    const handleEdit = () => {
        if (onEdit) {
            onEdit(webhook);
        }
    }

    const handleDelete = async () => {
        setIsUpdating(true);
        let data = {
            Guid: webhook.Guid
        };
        const response = await DeleteBrandWebhook(data);

        if (response.Success) {
            toast.success(response.Message);

            if (onDelete) {
                onDelete();
            }
        } else {
            toast.error(response.Message);
        }

        setIsUpdating(false);
    }

    return (
        <div className='brand-webhook'>
            <div className='webhook-url'>
                {webhook.WebhookUrl}
            </div>
            <div className='webhook-actions'>
                <button type='button' className='btn btn-primary' disabled={isUpdating} onClick={handleEdit}>
                    <i className="fas fa-pencil-alt"></i>
                </button>
                <button type='button' className='btn btn-danger' disabled={isUpdating} onClick={handleDelete}>
                    {!isUpdating ? (
                        <i className="fas fa-trash-alt"></i>
                    ) : (
                        <Spinner />
                    )}
                </button>
            </div>
        </div>
    )
}

export default BrandWebhookListItem