import { useEffect, useState } from 'react';

const EmailProvider = ({ provider, logo, selectedProvider, onSelect, disabled }) => {
	const [ className, setClassName ] = useState('');

	useEffect(
		() => {
			if (selectedProvider && selectedProvider === provider) {
				setClassName('active');
			} else {
				setClassName('');
			}
		},
		[ selectedProvider ]
	);

	const handleClick = () => {
		if (!disabled) {
			onSelect(provider);
		}
	};

	return (
		<div className={`email-provider ${className}`} onClick={handleClick}>
			<div className="email-provider-logo">
				<img src={logo} alt={`${provider} Logo`} />
			</div>
			<span className="title">{provider}</span>
		</div>
	);
};

export default EmailProvider;
