import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';

const WebsiteWizardSetupWizardBusinessName = () => {
	const [ formData, setFormData ] = useState({
		businessName : ''
	});
	const [ { websiteWizardSetupData }, dispatch ] = useStateValue();
	const { preconfigId, websiteId } = useParams();
	const history = useHistory();
	const inputRef = useRef();

	useEffect(
		() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		},
		[ inputRef ]
	);

	useEffect(
		() => {
			if (websiteWizardSetupData && websiteWizardSetupData.businessName) {
				setFormData({
					...formData,
					businessName : websiteWizardSetupData.businessName
				});
			}
		},
		[ websiteWizardSetupData ]
	);

	const handleChange = (e) => {
		const target = e.target.name;
		const value =
			e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		dispatch({
			type                   : actionTypes.SET_WEBSITE_WIZARD_SETUP_DATA,
			websiteWizardSetupData : {
				...websiteWizardSetupData,
				...formData
			}
		});

		if (preconfigId) {
			history.push(`/website-setup-wizard/${preconfigId}/business-phone`);
		} else {
			history.push(`/configure-website/${websiteId}/business-phone`);
		}
	};

	return (
		<div className="container setup-wizard-step xyz-in">
			<h5 class="rw-container-title">
				<span>Enter Your Business Name</span>
			</h5>

			<form onSubmit={handleSubmit}>
				<div className="form-inputs">
					<div className="input-group">
						<input
							ref={inputRef}
							type="text"
							name="businessName"
							className="form-control form-control-lg"
							placeholder="Enter Your Business Name"
							value={formData.businessName}
							onChange={handleChange}
						/>
					</div>
				</div>

				<button type="submit" className="btn btn-primary">
					<span>Continue</span>
				</button>
			</form>
		</div>
	);
};

export default WebsiteWizardSetupWizardBusinessName;
