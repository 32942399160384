import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useStateValue } from '../../StateProvider';
import Spinner from '../../spinner/Spinner';
import './CustomSetupPage.css';
import Testimonial from './testimonial/Testimonial';
import photoMatt from '../../img/matt-headshot.jpg';
import photoErin from '../../img/erin-headshot.jpg';
import photoMike from '../../img/mike-headshot-1.jpg';
// import '../../js/flipdown.min.css';

const CustomSetupPage = () => {
	const routeMatch = useRouteMatch();
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ animClass, setAnimClass ] = useState('xyz-in');
	const [ upgradeByDate, setUpgradeByDate ] = useState();
	const dateTimeOptions = {
		hour   : 'numeric',
		minute : 'numeric',
		year   : 'numeric',
		month  : 'long',
		day    : 'numeric'
	};
	const { userGuid } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!userGuid) {
			// tracking code
			window.trackEvent('PageView', 'Custom Setup Offer');
			window.gtag('event', 'page_view', {
				page_title : 'Custom Setup Offer',
				page_path  : '/custom-setup'
			});

			if (user) {
				if (user.TrialExpires) {
					let flipDownScript = document.createElement('script');
					flipDownScript.src =
						window.location.origin +
						'/r/activate/js/flipdown.min.js';

					document.head.appendChild(flipDownScript);

					setUpgradeByDate(new Date(user.TrialExpires));

					const flipdownTime =
						new Date(user.TrialExpires).getTime() / 1000;

					setTimeout(() => {
						new window.FlipDown(flipdownTime, 'clock').start();
					}, 500);
				}
			}
		}
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		// tracking code
		window.trackEvent('ButtonClick', 'Custom Setup Offer - Continue');
		window.gtag('event', 'click', {
			event_category : 'button',
			event_label    : 'custom setup offer -  continue'
		});

		setAnimClass('xyz-out');
		window.scrollTo(0, 0);

		setTimeout(() => {
			if (!userGuid) {
				history.push('/custom-setup/order');
			} else {
				history.push(`/custom-setup/order/${userGuid}`);
			}
		}, 500);
	};

	return (
		<Fragment>
			<div className="app-custom-setup panel">
				<div
					className={`container container-offer wizard-step wizard-start ${animClass}`}
					xyz="fade perspective-4 down-4 origin-bottom small-3"
				>
					<h2 className="mb-0 font-weight-bold text-center display-title">
						<i className="far fa-check-square" />Yes! Start My 30
						Day Trial!
					</h2>
					<h3 className="mb-5 font-weight-normal text-center">
						Start your trial to get full access and{' '}
						<u>a FREE domain...</u>
					</h3>
					<div className="fluid-video mt-3">
						<div className="wistia_responsive_padding">
							<div className="wistia_responsive_wrapper">
								<iframe
									src="https://fast.wistia.net/embed/iframe/vtj09enhqq?seo=false&videoFoam=true"
									title="RW Activate Video"
									allow="autoplay; fullscreen"
									allowtransparency="true"
									frameborder="0"
									scrolling="no"
									className="wistia_embed"
									name="wistia_embed"
									allowfullscreen
									msallowfullscreen
								/>
							</div>
						</div>
						<script
							src="https://fast.wistia.net/assets/external/E-v1.js"
							async
						/>
					</div>

					<div className="how-it-works mt-5">
						<h3>When You Start Your Trial:</h3>
						<p className="mt-2">
							You'll get <u>full access</u> to upload your logo,
							make design changes, change content, add or edit
							pages, create blog posts and set your domain to{' '}
							<strong>
								make your website live and{' '}
								<u>ready for business</u>.
							</strong>
						</p>
					</div>

					<h2 className="text-center mt-5 font-weight-bold font-italic display-title">
						...For Just $1 Today!
					</h2>

					<h3 className="mt-5">You're Also Getting:</h3>
					<ul className="fa-ul">
						<li className="font-weight-bold">
							<span className="fa-li">
								<i className="fas fa-arrow-right" />
							</span>Unlimited Website Setup Assistance*
						</li>
						<li className="font-weight-bold">
							<span className="fa-li">
								<i className="fas fa-arrow-right" />
							</span>FREE SSL Certificate For Every Website
						</li>
						<li>
							<span className="fa-li">
								<i className="fas fa-arrow-right" />
							</span>Automated Daily Backups & Restore Up To 3
							Months
						</li>
						<li className="font-weight-bold">
							<span className="fa-li">
								<i className="fas fa-arrow-right" />
							</span>Domain Search, Registration & Hosting For
							Unlimited Domains
						</li>
						<li>
							<span className="fa-li">
								<i className="fas fa-arrow-right" />
							</span>High Performance Amazon Cloud Website Hosting
						</li>
						<li className="font-weight-bold">
							<span className="fa-li">
								<i className="fas fa-arrow-right" />
							</span>24/7 Live Chat Support
						</li>
					</ul>
					<h3 className="mt-4">
						PLUS... Here's 3 FREE Gifts For Giving Us A Try...
					</h3>

					{upgradeByDate && (
						<p id="lblActivateBefore" runat="server">
							If you start your trial before{' '}
							<span className="text-highlight">
								{new Intl.DateTimeFormat(
									'en-US',
									dateTimeOptions
								).format(upgradeByDate)}{' '}
								EST
							</span>{' '}
							you're also going to get the following 3 gifts...
						</p>
					)}

					<div className="how-it-works mt-4">
						<h3 className="text-highlight">
							<u>Gift #1:</u> A FREE Domain! ($20 Value)**
						</h3>
						<p>
							A domain is the website address people use to get to
							your website. For example, ours is realwebsite.com
							and yours will probably be your business name .com
							or .net <br />(ex. myawesomebusiness.com)
						</p>
					</div>

					<div className="how-it-works mt-4">
						<h3 className="text-highlight">
							<u>Gift #2:</u> Website Marketing Quick Start Guide:
							($97 Value)
						</h3>
						<p>
							The whole purpose of having a website is to get more
							leads, customers and sales. We've developed a simple
							4 step strategy that you can use to easily{' '}
							<strong>
								all the website visitors you need to get all the
								leads and customers you want
							</strong>{' '}
							to grow your business quickly!
						</p>
					</div>

					<div className="how-it-works mt-4">
						<h3 className="text-highlight">
							<u>Gift #3:</u> Website Marketing Consultation:
							($197 Value)
						</h3>
						<p>
							Website marketing is what we do, and we're REALLY
							good at it. We want you to have great success with
							your website, so we want to give you 30 minutes with
							one of our marketing experts to show you exactly how
							to use the quick start guide to start getting leads
							and customers from your site IMMEDIATELY!
						</p>
					</div>

					<h3 className="mt-5 mb-0 text-center">
						That's Over <u>$300 In Value</u> You're Getting{' '}
						<u>FOR FREE</u>!
					</h3>
					<p className="text-center">
						You keep the gifts even if you cancel your subscription.
					</p>
					<h3 className="mt-5 text-center text-highlight">
						But hurry, this offer expires in...
					</h3>

					<div className="flip-clock-container text-center mt-2 mb-4">
						<div
							id="clock"
							className="flipdown ml-auto mr-auto mt-2 mb-2"
						/>
					</div>

					<div className="text-muted">
						NOTE: Once this trial offer expires you will have to pay
						$27 to start your subscription and you will not receive
						a free domain or any of the FREE gifts.
					</div>

					<div className="buttons-container mt-5 text-center">
						{!isLoading ? (
							<a className="btn-pay" onClick={handleSubmit}>
								<span>Yes! Activate Me</span>
							</a>
						) : (
							<a className="btn-pay">
								<Spinner />
							</a>
						)}
					</div>

					<div className="text-center mt-3">
						<a className="app-link" onClick={handleSubmit}>
							<span>
								Click here to activate your subscription
							</span>
						</a>
					</div>
				</div>

				<div className="container container-testimonials mt-5">
					<h3 className="text-center mb-5">What People Are Saying</h3>

					<div className="testimonials-wrapper">
						<Testimonial
							picture={photoMike}
							name="Mike Sylvan"
							location="Las Vegas, NV"
							testimonial="I had my site up and ready for business in less than 15 minutes... and I don't know anything about websites!"
						/>

						<Testimonial
							picture={photoMatt}
							name="Matt Gerchow"
							location="Austin, TX"
							testimonial="Super easy to do, and the live chat support is awesome! I love my website!"
						/>

						<Testimonial
							picture={photoErin}
							name="Ein Melia"
							location="Sarasota, FL"
							testimonial="I've wasted hours on others so called easy website builders, and they never look good. My RealWebsite site looks great!"
						/>
					</div>
				</div>

				<div className="container container-asterix pt-5 text-left">
					<p>
						* With your subscription you have access to our training
						library of videos, and our live chat support and support
						tickets. If you need help with customizing your site or
						adding content you can ask our support team and they
						will gladly help. Any complex coding or web development
						requests can be completed via projects at our standard
						hourly development rate of $75/hr.
					</p>
					<p>
						** If you take advantage of this offer you'll receive
						order credits to purchase a domain for your website if
						you need one. You can also transfer and apply your own
						domain. If you decide to cancel your account within 30
						days before you first monthly payment of $27, you still
						get to keep the domain however you cannot transfer it
						out of your account with us for 90 days as per domain
						registration restrictions.
					</p>
					<p>
						*** When you start your 30 day trial you will receive 3
						gifts; 1) a free domain 2) our quick start marketing
						guide 3) a free 30 minute consultation with one of our
						marketing experts. You can cancel your trial or
						subscription at any time and you get to keep the gifts.
						However, the domain cannot be transferred to another web
						hosting provider for at least 60 days. During that 60
						days you are not able to change the name servers, but
						you CAN create host records and point the domain to
						another website.
					</p>
				</div>
			</div>
		</Fragment>
	);
};

export default CustomSetupPage;
