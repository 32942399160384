import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CloneWebsite } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';

const CloneWebsiteDialog = ({ website, closeDialog, callback }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isValid, setIsValid ] = useState(false);
	const [ cloneName, setCloneName ] = useState('');

	useEffect(
		() => {
			if (cloneName && cloneName.trim() !== '') {
				setIsValid(true);
			} else {
				setIsValid(false);
			}
		},
		[ cloneName ]
	);

	const handleChange = (e) => {
		setCloneName(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isValid) {
			toast.error('Website name is required');
			return;
		}

		setIsSaving(true);

		let websiteData = {};
		websiteData.SiteId = website.Guid;
		websiteData.WebsiteName = cloneName;

		const response = await CloneWebsite(websiteData);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (callback) {
				callback();
			}

			closeDialog();
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<ModalDialog>
				<form onSubmit={handleSubmit}>
					<ModalDialogContent align="left">
						<h5>Clone a Website</h5>
						<p>Enter the new website name into the box below</p>
						<input
							type="text"
							name="cloneName"
							placeholder="Website name"
							className="form-control form-control-lg"
							value={cloneName}
							onChange={handleChange}
							disabled={isSaving}
						/>
					</ModalDialogContent>
					<ModalDialogButtons align="left" shaded={true}>
						<button type="submit" className="btn btn-primary" disabled={isSaving}>
							{!isSaving ? (
								<Fragment>
									<i class="fas fa-cloud-upload-alt" />
									<span>Save</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Cloning...</span>
								</Fragment>
							)}
						</button>
						<button
							type="button"
							className="btn btn-outline-primary"
							disabled={isSaving}
							onClick={closeDialog}
						>
							<i class="fas fa-window-close" />
							<span>Cancel</span>
						</button>
					</ModalDialogButtons>
				</form>
			</ModalDialog>
		</Fragment>
	);
};

export default CloneWebsiteDialog;
