import { Fragment, useEffect } from 'react';
import { actionTypes } from '../../reducer';
import { GetBrand } from '../../Services';
import { useStateValue } from '../../StateProvider';

const BrandLoader = () => {
	const [ { brand }, dispatch ] = useStateValue();

	useEffect(async () => {
		let reloadBrandData = false;

		// load the sessionStorage brand data if available
		let tempBrand = sessionStorage.getItem('rwBrand');

		if (tempBrand) {
			tempBrand = JSON.parse(tempBrand);

			if (tempBrand.origin !== window.location.origin) {
				reloadBrandData = true;
			}
		} else {
			reloadBrandData = true;
		}

		if (reloadBrandData) {
			const response = await GetBrand();

			if (response.IsSuccessful) {
				let storageData = JSON.parse(response.Data);
				storageData.origin = window.location.origin;
				//storageData.BrandLogo = 'https://app.realwebsite.com/img/rw-logo-flat-small.png';

				sessionStorage.setItem('rwBrand', JSON.stringify(storageData));

				if (storageData.BrandLogo) {
					new Image().src = storageData.BrandLogo;
				}

				addBrandInformation(storageData);

				dispatch({
					type  : actionTypes.SET_BRAND,
					brand : storageData
				});
			} else {
				// TODO: show an error message, app failed to load
			}
		} else {
			if (tempBrand.BrandLogo) {
				new Image().src = tempBrand.BrandLogo;
			}

			addBrandInformation(tempBrand);

			dispatch({
				type  : actionTypes.SET_BRAND,
				brand : tempBrand
			});
		}
	}, []);

	const addBrandInformation = (brand) => {
		if (brand.CustomStylesheetUrl) {
			var head = document.head;
			var link = document.createElement('link');

			link.type = 'text/css';
			link.rel = 'stylesheet';
			link.href = brand.CustomStylesheetUrl;

			head.appendChild(link);
		}

		if (brand.FaviconUrl) {
			const favicon = document.getElementById('favicon');
			favicon.href = brand.FaviconUrl;
		}

		document.title = brand.BrandName;
	};

	return <Fragment />;
};

export default BrandLoader;
