import { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { actionTypes } from '../../../../../reducer';
import { useStateValue } from '../../../../../StateProvider';
import './WebsiteWizardWebsite.css';

const WebsiteWizardWebsite = ({ website }) => {
	const [ { websiteWizardPreconfig }, dispatch ] = useStateValue();
	const websiteDivRef = useRef(null);
	const screenshotRef = useRef(null);
	const [ showSelectButton, setShowSelectButton ] = useState(false);
	const { userGuid } = useParams();
	const history = useHistory();

	useEffect(
		() => {
			let screenshotUrl = website.ScreenshotUrl;
			if (screenshotUrl) {
				let screenshotStyle = `url("/${screenshotUrl}")`;

				if (screenshotUrl.startsWith('https:')) {
					screenshotStyle = `url("${screenshotUrl}")`;
				}

				screenshotRef.current.style.backgroundImage = screenshotStyle;
			}
		},
		[ website ]
	);

	const handleWebsiteClick = () => {
		window.addEventListener('mousedown', handleClickOutside);
		setShowSelectButton(true);
		websiteDivRef.current.classList.add('focus');
	};

	const handleClickOutside = (e) => {
		if (
			websiteDivRef.current &&
			!websiteDivRef.current.contains(e.target)
		) {
			window.removeEventListener('mousedown', handleClickOutside);
			setShowSelectButton(false);
			websiteDivRef.current.classList.remove('focus');
		}
	};

	const handleSelectWebsite = () => {
		dispatch({
			type                   : actionTypes.SET_WEBSITE_WIZARD_PRECONFIG,
			websiteWizardPreconfig : website
		});

		if (!userGuid) {
			//history.push(`/create-website/${website.PreconfigId}/name`);
			history.push(`/create-website/${website.PreconfigId}/name`);
		} else {
			history.push(
				`/administration/websites/create/${userGuid}/${website.PreconfigId}/name`
			);
		}
	};

	return (
		<div
			ref={websiteDivRef}
			className="website-wizard-website xyz-in"
			onClick={handleWebsiteClick}
		>
			<div ref={screenshotRef} className="website-wizard-screenshot" />
			<div className="website-name">{website.Name}</div>
			{website.Description && (
				<div className="website-description alert alert-secondary">
					<span>{website.Description}</span>
				</div>
			)}

			{showSelectButton && (
				<div className="select-button-container">
					<button
						type="button"
						className="btn btn-primary"
						onClick={handleSelectWebsite}
					>
						Continue
					</button>
				</div>
			)}
		</div>
	);
};

export default WebsiteWizardWebsite;
