import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GetBrandWebhooks } from '../../../Services';
import './BrandWebhooks.css';
import BrandWebhooksList from './brandWebhooksList/BrandWebhooksList';

const BrandWebhooks = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ webhooks, setWebhooks ] = useState();
	const [ userCreatedWebhooks, setUserCreatedWebhooks ] = useState();
	const [ userUpdatedWebhooks, setUserUpdatedWebhooks ] = useState();
	const [ userStatusWebhooks, setUserStatusWebhooks ] = useState();
	const [ productOrderWebhooks, setProductOrderWebhooks ] = useState();
	const [ websiteCreatedWebhooks, setWebsiteCreatedWebhooks ] = useState();

	useEffect(() => {
		// get brand webhooks
		getBrandWebhooks();
	}, []);

	useEffect(
		() => {
			// every time the webhooks list updates, update the individual lists
			if (webhooks && webhooks.length > 0) {
				setUserCreatedWebhooks(
					webhooks.filter((x) => x.WebhookType === 'OnUserCreated')
				);
				setUserUpdatedWebhooks(
					webhooks.filter(
						(x) => x.WebhookType === 'OnUserContactUpdate'
					)
				);
				setUserStatusWebhooks(
					webhooks.filter(
						(x) => x.WebhookType === 'OnUserStatusUpdate'
					)
				);
				setProductOrderWebhooks(
					webhooks.filter((x) => x.WebhookType === 'OnProductOrder')
				);

				setWebsiteCreatedWebhooks(
					webhooks.filter((x) => x.WebhookType === 'OnWebsiteCreated')
				);
			} else {
				setUserCreatedWebhooks();
				setUserUpdatedWebhooks();
				setUserStatusWebhooks();
				setProductOrderWebhooks();
				setWebsiteCreatedWebhooks();
			}
		},
		[ webhooks ]
	);

	const getBrandWebhooks = async () => {
		const response = await GetBrandWebhooks();

		if (response.Success) {
			setWebhooks(response.Data);
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const handleUpdate = () => {
		setIsLoading(true);
		getBrandWebhooks();
	};

	return (
		<div className="brand-webhooks panel">
			<div className="container">
				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>User Created</span>
				</h5>
				<div className="alert alert-secondary">
					Triggers when a new user is created in any manner.
				</div>

				{/* OnUserCreated */}
				<BrandWebhooksList
					isLoading={isLoading}
					webhookType="OnUserCreated"
					webhooks={userCreatedWebhooks}
					onUpdate={handleUpdate}
				/>

				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>User Contact Updated</span>
				</h5>
				<div className="alert alert-secondary">
					Triggers when a user updates their contact information or
					when that information is updated in any way through other
					means.
				</div>

				{/* OnUserContactUpdate */}
				<BrandWebhooksList
					isLoading={isLoading}
					webhookType="OnUserContactUpdate"
					webhooks={userUpdatedWebhooks}
					onUpdate={handleUpdate}
				/>

				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>User Status Updated</span>
				</h5>
				<div className="alert alert-secondary">
					Triggers when a user status changes (active to cancelled,
					trial to active, etc.).
				</div>

				{/* OnUserStatusUpdate */}
				<BrandWebhooksList
					isLoading={isLoading}
					webhookType="OnUserStatusUpdate"
					webhooks={userStatusWebhooks}
					onUpdate={handleUpdate}
				/>

				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>Product Ordered / Invoice Submitted</span>
				</h5>
				<div className="alert alert-secondary">
					Triggers when a user tries to order a product or pay an
					invoice.
				</div>

				{/* OnProductOrder */}
				<BrandWebhooksList
					isLoading={isLoading}
					webhookType="OnProductOrder"
					webhooks={productOrderWebhooks}
					onUpdate={handleUpdate}
				/>

				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>Website Created</span>
				</h5>
				<div className="alert alert-secondary">
					Triggers when a user creates a new website.
				</div>

				{/* OnWebsiteCreated */}
				<BrandWebhooksList
					isLoading={isLoading}
					webhookType="OnWebsiteCreated"
					webhooks={websiteCreatedWebhooks}
					onUpdate={handleUpdate}
				/>
			</div>
		</div>
	);
};

export default BrandWebhooks;
