import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../../../spinner/Spinner';
import { countriesList } from '../../../../../countries';
import './EditUserCompanyInformation.css';
import { SaveUserSettingsAdmin } from '../../../../../Services';
import { toast } from 'react-toastify';

const EditUserCompanyInformation = ({ userDetails, onUpdate }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);
	const USPhoneRegex = /^\d{10}$/;
	const phoneRegex = /^\d+/g;
	const [ formUser, setFormUser ] = useState(null);

	useEffect(
		() => {
			setFormUser({
				...userDetails,
				Password : ''
			});
		},
		[ userDetails ]
	);

	const handleChange = (e) => {
		const target = e.target.name;
		let value = e.target.value;

		if (target === 'CompanyPhone') {
			value = e.target.value.replace(/[^0-9]+/g, '');
		}

		setFormUser({
			...formUser,
			[target] : value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempData = formUser;
		tempData.EmailAddress = formUser.Email;
		tempData.UserGuid = userDetails.Guid;

		const response = await SaveUserSettingsAdmin(tempData);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onUpdate) {
				onUpdate(tempData);
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<div className="rw-container shadow-large company-information">
			{formUser ? (
				<form onSubmit={handleSubmit} autoComplete="off">
					<div className="form-inputs">
						<div className="form-group">
							<label htmlFor="companyName">Company name</label>
							<input
								type="text"
								id="companyName"
								name="CompanyName"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.CompanyName}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="companyAddress1">
								Company Address
							</label>
							<input
								type="text"
								id="companyAddress1"
								name="CompanyAddress1"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.CompanyAddress1}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="companyCity">Company City</label>
							<input
								type="text"
								id="companyCity"
								name="CompanyCity"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.CompanyCity}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="companyState">Company State</label>
							<input
								type="text"
								id="companyState"
								name="CompanyState"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.CompanyState}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="companyPostalCode">
								Company Postal Code
							</label>
							<input
								type="text"
								id="companyPostalCode"
								name="CompanyPostalCode"
								className="form-control form-control-lg"
								disabled={isSaving}
								value={formUser.CompanyPostalCode}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="companyPhone">Company Phone</label>
							<input
								onChange={handleChange}
								id="companyPhone"
								name="CompanyPhone"
								type="tel"
								disabled={isSaving}
								value={formUser.CompanyPhone}
								className="form-control form-control-lg"
								placeholder="Phone (format: 8005130456)"
							/>
						</div>
					</div>
					<div className="rw-container-buttons">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={isSaving}
						>
							{!isSaving ? (
								<Fragment>
									<i class="fas fa-cloud-upload-alt" />
									<span>Save Changes</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Saving...</span>
								</Fragment>
							)}
						</button>
					</div>
				</form>
			) : (
				<div className="text-center p-3">
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default EditUserCompanyInformation;
