import { useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';

const WebsiteWizardSetupWizardStart = () => {
	const { path, url } = useRouteMatch();
	const { preconfigId, websiteId } = useParams();
	const [ { websiteWizardSetupData }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(() => {
		let setupData = {
			preconfigId : preconfigId,
			websiteId   : websiteId
		};

		dispatch({
			type                   : actionTypes.SET_WEBSITE_WIZARD_SETUP_DATA,
			websiteWizardSetupData : setupData
		});
	}, []);

	const handleContinue = (e) => {
		e.preventDefault();
		history.push(`${url}/business-name`);
	};

	return (
		<div className="container setup-wizard-step xyz-in">
			<h5 class="rw-container-title">
				<span>
					Great! Your account is setup, now lets setup your website!
				</span>
			</h5>
			<button
				type="submit"
				className="btn btn-primary"
				onClick={handleContinue}
			>
				Continue
			</button>
		</div>
	);
};

export default WebsiteWizardSetupWizardStart;
