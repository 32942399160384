import { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CreateWebsite } from '../../Services';
import LoggedInAsAdmin from '../loggedInAsAdmin/LoggedInAsAdmin';
import MainAppHeader from '../mainApp/mainAppHeader/MainAppHeader';
import WebsiteWizard from '../mainApp/websites/websiteWizard/WebsiteWizard';
import WebsiteWizardCompanyDetails from '../mainApp/websites/websiteWizard/websiteWizardCompanyDetails/WebsiteWizardCompanyDetails';
import WebsiteWizardPreconfigCreate from '../mainApp/websites/websiteWizard/websiteWizardPreconfigCreate/WebsiteWizardPreconfigCreate';
import WebsiteWizardPreconfigSelected from '../mainApp/websites/websiteWizard/websiteWizardPreconfigSelected/WebsiteWizardPreconfigSelected';
import './Administration.css';
import AdministrationNav from './administrationNav/AdministrationNav';
import BrandSendMessage from './brandSendMessage/BrandSendMessage';
import BrandSendHistory from './brandSendMessage/brandSendHistory/BrandSendHistory';
import BrandSettings from './brandSettings/BrandSettings';
import BrandWebhooks from './brandWebhooks/BrandWebhooks';
import CreateInvoice from './invoices/createInvoice/CreateInvoice';
import CreateUser from './usersList/createUser/CreateUser';
import EditUser from './usersList/editUser/EditUser';
import UsersList from './usersList/UsersList';
import CancelUser from './usersList/editUser/cancelUser/CancelUser';

const Administration = () => {
	return (
		<Fragment>
			<LoggedInAsAdmin />
			<MainAppHeader chatButtonText="Get Help" />
			<AdministrationNav />

			<div className="administration panel">
				<Switch>
					<Route path="/administration/webhooks">
						<BrandWebhooks />
					</Route>
					<Route path="/administration/settings" exact>
						<BrandSettings />
					</Route>
					<Route path="/administration/email/history/view/:guid">
						<BrandSendMessage type="email" />
					</Route>
					<Route path="/administration/email/history">
						<BrandSendHistory type="email" />
					</Route>
					<Route path="/administration/email">
						<BrandSendMessage type="email" />
					</Route>
					<Route path="/administration/sms/history/view/:guid">
						<BrandSendMessage type="sms" />
					</Route>
					<Route path="/administration/sms/history">
						<BrandSendHistory type="sms" />
					</Route>
					<Route path="/administration/sms">
						<BrandSendMessage type="sms" />
					</Route>
					<Route path="/administration/invoices/edit/:invoiceGuid">
						<CreateInvoice />
					</Route>
					<Route path="/administration/invoices/create/:userGuid">
						<CreateInvoice />
					</Route>
					<Route path="/administration/edit/:userGuid">
						<EditUser />
					</Route>
					<Route path="/administration/websites/create/:userGuid/:preconfigId/create-website">
						<WebsiteWizardPreconfigCreate />
					</Route>
					<Route path="/administration/websites/create/:userGuid/:preconfigId/company-info">
						<WebsiteWizardCompanyDetails />
					</Route>
					<Route path="/administration/websites/create/:userGuid/:preconfigId/name">
						<WebsiteWizardPreconfigSelected />
					</Route>
					<Route path="/administration/websites/create/:userGuid">
						<WebsiteWizard />
					</Route>
					<Route path="/administration/users/create" exact>
						<CreateUser />
					</Route>
					<Route path="/administration/users/cancel/:userGuid" exact>
						<CancelUser />
					</Route>
					<Route path="/administration" exact>
						<UsersList />
					</Route>
				</Switch>
			</div>
		</Fragment>
	);
};

export default Administration;
