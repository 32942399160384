import { useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';
import LottiePlayer from '../../../../../lottiePlayer/LottiePlayer';
import checkAnimation from '../../../../../lottiePlayer/animations/check.json';

const WebsiteWizardSetupWizardSuccess = () => {
	const { preconfigId, websiteId } = useParams();
	const [ { websiteWizardSetupData }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(() => {
		dispatch({
			type                   : actionTypes.SET_WEBSITE_WIZARD_SETUP_DATA,
			websiteWizardSetupData : null
		});
	}, []);

	const handleContinue = (e) => {
		e.preventDefault();
		history.push('/');
	};

	return (
		<div className="container setup-wizard-step xyz-in">
			<LottiePlayer animation={checkAnimation} />
			<h3>Success!</h3>
			{preconfigId ? (
				<p className="text-muted">
					Your website has been created successfully
				</p>
			) : (
				<p className="text-muted">
					Your website has been updated successfully
				</p>
			)}

			<button
				type="submit"
				className="btn btn-primary"
				onClick={handleContinue}
			>
				Continue
			</button>
		</div>
	);
};

export default WebsiteWizardSetupWizardSuccess;
