import { useStateValue } from '../../StateProvider';
import { GetUserDetails } from '../../Services';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { actionTypes } from '../../reducer';

const SessionLoader = ({ setSessionLoaded }) => {
	const [ { brand, user, adminUser }, dispatch ] = useStateValue();
	const [ chatLoaded, setChatLoaded ] = useState(false);
	const userRef = useRef(null);
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		window.addEventListener('focus', handleWindowFocus);

		return () => {
			window.removeEventListener(handleWindowFocus);
		};
	}, []);

	const handleWindowFocus = async () => {
		if (userRef.current) {
			const response = await GetUserDetails();

			if (response.IsSuccessful) {
				let tempUser = JSON.parse(response.Data);

				dispatch({
					type : actionTypes.SET_USER,
					user : tempUser
				});

				if (userRef.current.Guid !== tempUser.Guid) {
					window.location.href = window.location.href;
				}
			}
		}
	};

	useEffect(
		() => {
			handleLocationChange();
		},
		[ location, brand ]
	);

	useEffect(
		() => {
			handleLCGroup();

			userRef.current = user;
		},
		[ user ]
	);

	const handleLCGroup = () => {
		if (!chatLoaded) {
			// load LC script
			window.__lc = window.__lc || {};
			window.__lc.license = 10943002;

			if (user) {
				if (user.Status === 'Trial') {
					window.__lc.group = 3;
				} else {
					window.__lc.group = 2;
				}
			} else {
				window.__lc.group = 2;
			}

			var lc = document.createElement('script');
			lc.type = 'text/javascript';
			lc.async = true;
			lc.src =
				('https:' == document.location.protocol
					? 'https://'
					: 'http://') + 'cdn.livechatinc.com/tracking.js';
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(lc, s);

			setChatLoaded(true);
		}
	};

	const handleLocationChange = async () => {
		const pageLocation = location.pathname;

		if (
			pageLocation.indexOf('/login') === -1 &&
			pageLocation.indexOf('/logout') === -1 &&
			pageLocation.indexOf('/forgot-password') === -1 &&
			pageLocation.indexOf('/invoice/') === -1 &&
			pageLocation.indexOf('/invoice-code') === -1 &&
			pageLocation.indexOf('/custom-setup') === -1 &&
			pageLocation.indexOf('/otut') === -1
		) {
			// console.log('session info:', user, brand);
			if (!user && brand) {
				const tempUser = await GetUserDetails();

				// console.log(tempUser);

				if (!tempUser.IsSuccessful) {
					// user not logged in, check if brand is loaded
					// and redirect to login if it is, otherwise let
					// the App handler handle it

					if (brand) {
						setSessionLoaded(true);
						history.push('/login');
					}
				} else {
					// console.log('user logged in', JSON.parse(tempUser.Data));
					dispatch({
						type : actionTypes.SET_USER,
						user : JSON.parse(tempUser.Data)
					});

					if (localStorage.getItem('rwau')) {
						let adminUser = JSON.parse(
							localStorage.getItem('rwau')
						);

						dispatch({
							type      : actionTypes.SET_ADMIN_USER,
							adminUser : adminUser
						});
					}

					setSessionLoaded(true);
				}
			} else {
				if (brand) {
					if (user.BrandId !== brand.Id) {
						setSessionLoaded(true);
						history.push('/login');
					}
				}
			}
		} else {
			setSessionLoaded(true);
		}

		if (user && user.Status !== 'Active') {
			window.trackEvent('PageView', document.title);
		}
		window.gtag('event', 'page_view', {
			page_title : document.title,
			page_path  : pageLocation
		});
	};

	return <Fragment />;
};

export default SessionLoader;
